import request from '@/utils/request'

// 查询产品二级分类列表
export function listCategory(query) {
  return request({
    url: '/sub/category/list',
    method: 'get',
    params: query
  })
}
// 查询产品二级分类列表-不分页
export function allListCategory(query) {
  return request({
    url: '/sub/category/allList',
    method: 'get',
    params: query
  })
}

// 查询产品二级分类详细
export function getCategory(subCategoryId) {
  return request({
    url: '/sub/category/' + subCategoryId,
    method: 'get'
  })
}

// 新增产品二级分类
export function addCategory(data) {
  return request({
    url: '/sub/category',
    method: 'post',
    data: data
  })
}

// 修改产品二级分类
export function updateCategory(data) {
  return request({
    url: '/sub/category',
    method: 'put',
    data: data
  })
}

// 删除产品二级分类
export function delCategory(subCategoryId) {
  return request({
    url: '/sub/category/' + subCategoryId,
    method: 'delete'
  })
}

// 导出产品二级分类
export function exportCategory(query) {
  return request({
    url: '/sub/category/export',
    method: 'get',
    params: query
  })
}


//批量禁用启用
export function updateStatus(query,status) {
  return request({
    url: '/sub/category/updateStatus/'+query+'/'+status,
    method: 'put',
  })
}
