
<template>
  <div class="houver-img">
    <span style="color: #1890ff" @click="showImage">{{ imageName || '--'}}</span>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="previewSrcList"
      :z-index="9999"
    ></el-image-viewer>
  </div>
</template>


<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ElImageViewer
  },
  props: {
    imgUrl: { default: "" },
    imgsIdArr: { type: Array },
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_API,
      showViewer: false,
    };
  },
  computed: {
    imageName() {
      let list = this.imgUrl.split(",");
      let imageName = "";
      this.imgsIdArr.map((el) => {
        if (el.id == list[0]) {
          imageName = el.filename
            .toString()
            .replace(".jpg", "")
            .replace(".png", "");
        }
      });
      return imageName;
    },
    previewSrcList() {
      let list = this.imgUrl.split(",");
      let srcList = [];
      this.imgsIdArr.forEach((el) => {
        if (list.includes(el.id.toString())) {
          srcList.push(
            el.filepath.indexOf("http") < 0
              ? this.base_url + el.filepath
              : el.filepath
          );
        }
      });
      return srcList;
    },
  },
  methods: {
    showImage() {
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
  },
};
</script>
<style  lang="less" scoped>
.houver-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
