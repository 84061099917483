<template>
  <div @click="clickRouter(item)" v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
          @click="$emit('menuClose')"
        >
          <item :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
      @click.native="menuClick"
    >
      <template slot="title">
        <item v-if="item.meta" :title="item.meta.title" />
      </template>
      <!-- 递归 -->
      <menu-item
        v-for="(child, index) in item.children"
        :key="child.path + index"
        :is-nest="true"
        :item="child"
        :actionIndex="actionIndex"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
        :class="{ 'nest-menu-children': child.children ? true : false }"
        @menuClose="$emit('menuClose')"
        @menuClick="$emit('menuClick')"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";

export default {
  name: "MenuItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
    actionIndex: {
      required: true,
    },
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    clickRouter(e, a) {},
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          //最有一个子项数据
          this.onlyOneChild = item;
          this.onlyOneChild.meta.tagsIndex = this.actionIndex;
          return true;
        }
      });
      // 当只有一个子路由器时，默认情况下会显示子路由器
      if (showingChildren.length === 1) {
        return true;
      }
      // 如果没有要显示的子路由器，则显示父路由器
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      //判断是否为外链
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    menuClick() {
      setTimeout(() => {
        this.$emit("menuClick");
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.nest-menu-children {
  .nest-menu {
    span {
      margin-left: 20px;
    }
  }
}
</style>
