import { traderDropdown } from '@/api/settlement/searchCommon'
import { beforeUncheckList } from '@/api/settlement/headquartersSupplier/supplierAccounts'

const state = {
  search: false,
  payDetailList: [], // 1.3总部-供应商付款:付款单明细列表
  meetFormData: {}, // 1.3总部-供应商付款:付款单FormData
  feeSettlementList: [], // 代发费结算单
  feePayList: [], // 7代发费结算管理:代发费付款单
  feePayFormData: {}, // 7代发费结算管理:代发费付款单FormData
  issueFeeList: [], // 3.3.2总部发行费支付:发行费支付-付款单
  issueSettleList: [], // 3.3.2总部发行费结算
  feeStatisDetail: {}, // 3.3.2总部发行费结算-发行费结算单详情接口数据
  billData: [],  // 5.4子分公司清账
  freeSettlement: [], // 3.1 总部免费数确认
  conversionFree: [], // 3.2 总部免费非免转换
  sendGoods: [], // 3.2 发货
  covertGoods: [], // 3.2 收货
  headerSupplierList: [],
  subCompanyList: [],
  schoolList: [],
  unsettleInfoList: [],
  isGetHeaderSupplierList: false,
  isGetSubCompanyList: false,
  isGetSchoolList: false,
  isGetUnsettleList: false
};
const mutations = {
  callSearch(state, value) {
    state.search = value;
  },
  setPayDetailList(state, value) {
    state.payDetailList = value;
  },
  setMeetFormData(state, value) {
    state.meetFormData = value;
  },
  setFeeSettlementList(state, value) {
    state.feeSettlementList = value;
  },
  setFeePayList(state, value) {
    state.feePayList = value;
  },
  setFeePayFormData(state, value) {
    state.feePayFormData = value;
  },
  setIssueFeeList(state, value) {
    state.issueFeeList = value;
  },
  setBillData(state, value) {
    state.billData = value;
  },
  setFreeSettlement(state, value) {
    state.freeSettlement = value;
  },
  setSendGoods(state, value) {
    state.sendGoods = value;
  },
  setCovertGoods(state, value) {
    state.covertGoods = value;
  },
  setConversionFree(state, value) {
    state.conversionFree = value;
  },
  setIssueSettleList(state, value) {
    state.issueSettleList = value;
  },
  setFeeStatisDetail(state, value) {
    state.feeStatisDetail = value;
  },
  setSupplierList(state, value) {
    state.headerSupplierList = value
    state.isGetHeaderSupplierList = true
  },
  setCompanyList(state, value) {
    state.subCompanyList = value
    state.isGetSubCompanyList = true
  },
  setSchoolList(state, value) {
    state.schoolList = value
    state.isGetSchoolList = true
  },
  setUnsettleInfoList (state, value) {
    state.unsettleInfoList = value
    state.isGetUnsettleList = true
  }
};

const actions = {
  async getTrandeList({commit}, traderType) {
    let { data } = await traderDropdown({traderType})
    if(traderType === 3) {
      commit("setSupplierList", data)
    }
    if(traderType === 2) {
      commit("setCompanyList", data)
    }
    if(traderType === 1) {
      commit("setSchoolList", data)
    }
  },
  async getUnsettleInfoList ({ commit }) {
    let query = {
      year: localStorage.getItem("year"),
      quarterId: localStorage.getItem("quarterId")
    }
      
    let { code, rows } = await beforeUncheckList(query)
    if (code == 200) {
      commit("setUnsettleInfoList", rows)
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
