import request from '@/utils/request';

// 查询附件
export async function listFile(bustype, busid) {
  return request({
    url: '/file/list/' + bustype + '/' + busid,
    method: 'get'
  });
}

// 查询附件
export async function listSysFile(query) {
  return request({
    url: '/file/sysList',
    method: 'get',
    params: query
  });
}

export async function delFileByFileID(fileid) {
  return request({
    url: '/file/delFileByFileID/' + fileid,
    method: 'delete'
  });
}

export async function delFileByFileIDs(fileIds) {
  return request({
    url: '/file/delFileByFileIDs/' + fileIds,
    method: 'delete'
  });
}

export async function fileInfo(data) {
  return request({
    url: '/common/fileInfo',
    method: 'POST',
    data
  });
}


export async function getFileInfo(fileId) {
  return request({
    url: '/file/'+fileId,
    method: 'get'

  });
}

export async function updateSysFile(data) {
  return request({
    url: '/file/updateInfo',
    method: 'post',
    data: data
  });
}

export async function sysResourceList(data) {
  return request({
    url: '/file/sysResourceList',
    method: 'get',
    params: data
  });
}

export async function sysResourceDetail(id) {
  return request({
    url: '/file/sysResource/'+id,
    method: 'get',
    params: {}
  });
}

export async function sysResourceAdd(data) {
  return request({
    url: '/file/sysResourceAdd',
    method: 'post',
    data: data
  });
}

export async function updateResource(data) {
  return request({
    url: '/file/updateResource',
    method: 'post',
    data: data
  });
}

export async function delResourceByIds(data) {
  return request({
    url: '/file/delResourceByIds',
    method: 'post',
    data: data
  });
}

export async function downResourceByIds(data) {
  return request({
    url: '/file/downResourceByIds',
    method: 'post',
    data: data
  });
}

export async function publishResourceByIds(data) {
  return request({
    url: '/file/publishResourceByIds',
    method: 'post',
    data: data
  });
}

export async function sysResourceExport(data) {
  return request({
    url: '/file/sysResource/export',
    method: 'get',
    params: data
  });
}
