<template>
  <div class="lw-table">
    <search-bar
      ref="lwSearchBar"
      :query="table.query"
      :search-data="table.searchData"
      :search-loading="table.searchLoading"
      :high-search-data="table.highSearchData"
      :no-reset="table.noReset || false"
      @on-search-change="searchChange"
      @on-search="handleQuery"
      @on-reset="onReset"
      @high-searcher-change="highSearcherChange"
    >
      <span
        v-for="(item, index) in searchSlotName"
        :key="index"
        :slot="`search${item.slotName}`"
      >
        <slot :name="item.slotName"></slot>
      </span>
      <span
        v-for="(item, index) in highSearchSlotName"
        :key="index"
        :slot="`highSearch${item.slotName}`"
      >
        <slot :name="item.slotName"></slot>
      </span>
      <span slot="header">
        <slot name="header"></slot>
      </span>
      <span slot="after">
        <slot name="ctrl-button"></slot>
      </span>
    </search-bar>
    <div class="set-columns">
      <el-popover
        v-if="table.setColumns"
        placement="bottom"
        trigger="click"
        @show="showCheckColumns"
        v-model="setColumnsVisible"
      >
        <div>
          <el-checkbox-group
            v-model="checkedColumnsList"
            class="checkbox-group"
            @change="showColumnsChange"
          >
            <el-checkbox
              v-for="item in checkColumnsList"
              :label="item.label"
              :key="item.key"
              v-show="!item.notSetColumns"
              class="checkbox"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
          <div class="checkbox-button">
            <el-button
              type="danger"
              @click="submitShowColumns"
              >保存
            </el-button>
            <el-button
              plain
              @click="setColumnsVisible = false"
              >取消
            </el-button>
          </div>
        </div>
        <i slot="reference" class="el-icon-setting setting"></i>
      </el-popover>
    </div>
    <div class="table-header">
      <slot name="header-tipInfo" />
    </div>
    <el-table
      v-loading="table.loading || false"
      element-loading-spinner="el-icon-loading"
      element-loading-text="数据加载中..."
      :ref="table.name || 'lwTable'"
      :key="table.key"
      stripe
      :border="
        table.border == null || table.border == undefined || table.border
      "
      size="small"
      :height="table.height || lwTableHeight"
      :max-height="table.maxHeight"
      :row-style="{ height: '32px' }"
      :header-cell-style="{height:'38px'}"
      :show-summary="table.showSummary"
      :summary-method="getSummary"
      :sum-text="table.sumText || '合计'"
      tooltip-effect="dark"
      :default-expand-all="table.defaultExpandAll || false"
      :data="table.data"
      :default-sort="table.sort"
      :empty-text="table.emptyText || '暂无数据'"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
      @select = "handleSelectRowChange"
      @select-all = "handleSelectAllChange"
      @row-click="handleRowClick"
      @row-dblclick="handleRowDbClick"
      :row-key="rowKey"
      :row-class-name="tableRowClassName"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :style="table.style"
    >
      <el-table-column
        v-if="table.columns && selectionColumn"
        :selectable="selectable"
        reserve-selection
        type="selection"
        align="center"
        width="50"
      />
      <el-table-column
        v-if="table.columns && indexColumn.length"
        :label="indexColumn[0].label || '序号'"
        type="index"
        align="center"
        :width="indexColumn[0].width || '50'"
      />
      <el-table-column
        v-for="(column, idx) in Columns"
        :key="idx"
        :prop="column.key"
        :sortable="column.sortable"
        :type="column.type"
        :label="column.label"
        :width="column.width"
        :min-width="flexColumnWidth(column)"
        :fixed="column.fixed"
        :align="getAlign(column)"
        :show-overflow-tooltip="!column.hideTooltip"
        :render-header="column.renderHeader"
        :sort-method="
          ['gradeId', 'gradeName'].includes(column.key)
            ? gradeSort
            : (a, b) => sortMethod(a, b, column)
        "
        v-show="!column.hidden"
      >
        <template slot="header" slot-scope="scope">
          <slot v-if="column.slotLableName" :name="column.slotLableName"></slot>
          <span v-else>{{ column.label }} </span>
        </template>

        <template v-if="!column.children" slot-scope="scope">
          <slot
            v-if="column.slotName"
            :name="column.slotName"
            :row="scope.row"
            :index="scope.$index"
          >
          </slot>
          <div v-else-if="column.type === 'image'" class="table-img" >
            <el-image
              v-if="scope.row[column.key]"
              :src="getFilepath(scope.row[column.key])"
              :preview-src-list="getFilepathList(scope.row[column.key])"
              class="img"
              fit="cover"
            ></el-image>
            <span v-else> -- </span>
          </div>
          <div v-else-if="column.type === 'imageId'" class="table-img">
            <houverImg
              v-if="scope.row[column.key]"
              :imgsIdArr="imgsIdArr"
              :imgUrl="scope.row[column.key]"
            ></houverImg>
            <span v-else> -- </span>
          </div>
          <span v-else-if="column.filter" :style="typeof scope.row[column.key] === 'number' && scope.row[column.key] < 0 ? 'color: #ff4949' : ''">
            {{ $filters[column.filter](scope.row[column.key]) }}
          </span>
          <cell
            v-else-if="column.render"
            :column="column"
            :row="scope.row"
            :index="scope.$index"
            :render="column.render"
          >
          </cell>
          <el-input
            v-else-if="column.type === 'input'"
            v-model="scope.row[column.key]"
            :disabled="column.disabled"
            :placeholder="column.placeholder"
            :type="column.inputType"
            :class="column.inputType === 'amount' ? 'amount-input' : ''"
            @blur="
              amountBlur(
                scope.row[column.key],
                column.inputType,
                scope.row,
                column.key
              )
            "
            @focus="
              amountFoucs(
                scope.row[column.key],
                column.inputType,
                scope.row,
                column.key
              )
            "
            :clearable="!column.clearableDisabled"
          />
          <el-select
            v-else-if="column.type === 'select'"
            v-model="scope.row[column.key]"
            :disabled="column.disabled"
            :placeholder="column.placeholder"
            clearable
          >
            <el-option
              v-for="i in column.dictsType
                ? getDictsOptions(column.dictsType)
                : column.option"
              :label="i.dictLabel"
              :value="i.dictValue"
              :key="i.dictLabel + i.dictValue"
            />
          </el-select>
          <el-date-picker
            v-else-if="$filters.isDatePicker(column.type)"
            v-model="scope.row[column.key]"
            :type="column.type"
            :disabled="column.disabled"
            clearable
            size="small"
            :format="$filters.dateFormat(column.type)"
            :value-format="$filters.dateValueFormat(column.type)"
            :default-value="column.defaultValue"
            :placeholder="column.placeholder || '日期'"
            :start-placeholder="column.startPlaceholder || '开始日期'"
            :end-placeholder="column.endPlaceholder || '结束日期'"
            :picker-options="column.pickerOptions"
          >
          </el-date-picker>
          <span v-else-if="column.dictsType">
            {{ getDictsName(column.dictsType, scope.row[column.key]) || "--" }}
          </span>
          <span v-else-if="column.enumType">
            {{
              (scope.row[column.key] || scope.row[column.key] === 0) &&
              scope.row[column.key] != "null"
                ? $enums[column.enumType].getName(scope.row[column.key] + "")
                : "--"
            }}
          </span>
          <span v-else-if="column.option">
            {{ getOptionName(scope.row[column.key], column.option) || "--" }}
          </span>
          <span v-else :style="typeof scope.row[column.key] === 'number' && scope.row[column.key] < 0 ? 'color: #ff4949' : ''">
            {{
              (scope.row[column.key] || scope.row[column.key] === 0) &&
              scope.row[column.key] != "null"
                ? scope.row[column.key]
                : "--"
            }}
          </span>
        </template>
        <el-table-column
          v-for="(columnChild, idxChild) in column.children || []"
          :key="idxChild"
          :prop="columnChild.key"
          :sortable="columnChild.sortable"
          :type="columnChild.type"
          :label="columnChild.label"
          :width="columnChild.width"
          :fixed="columnChild.fixed"
          :align="getAlign(columnChild)"
          :show-overflow-tooltip="true"
          v-show="!columnChild.hidden"
        >
          <template v-if="!columnChild.children" slot-scope="scope">
            <slot
              v-if="columnChild.slotName"
              :name="columnChild.slotName"
              :row="scope.row"
            >
            </slot>
            <div v-else-if="columnChild.type === 'image'" class="table-img">
              <el-image
                :src="getFilepath(scope.row[columnChild.key])"
                :preview-src-list="getFilepathList(scope.row[columnChild.key])"
                class="img"
                fit="cover"
              ></el-image>
            </div>
            <div v-else-if="columnChild.type === 'imageId'" class="table-img">
              <houverImg
                :imgsIdArr="imgsIdArr"
                :imgUrl="scope.row[columnChild.key]"
              ></houverImg>
            </div>
            <span v-else-if="columnChild.filter" :style="typeof scope.row[columnChild.key] === 'number' && scope.row[columnChild.key] < 0 ? 'color: #ff4949' : ''">
              {{ $filters[columnChild.filter](scope.row[columnChild.key]) }}
            </span>
            <cell
              v-else-if="columnChild.render"
              :column="columnChild"
              :row="scope.row"
              :index="scope.$index"
              :render="columnChild.render"
            >
            </cell>
            <el-input
              v-else-if="columnChild.type === 'input'"
              v-model="scope.row[columnChild.key]"
              :disabled="columnChild.disabled"
              :placeholder="columnChild.placeholder"
              :type="columnChild.inputType"
              :class="columnChild.inputType === 'amount' ? 'amount-input' : ''"
              @blur="
                amountBlur(
                  scope.row[columnChild.key],
                  columnChild.inputType,
                  scope.row,
                  columnChild.key
                )
              "
              @focus="
                amountFoucs(
                  scope.row[columnChild.key],
                  columnChild.inputType,
                  scope.row,
                  columnChild.key
                )
              "
              :clearable="!columnChild.clearableDisabled"
            />
            <el-select
              v-else-if="columnChild.type === 'select'"
              v-model="scope.row[columnChild.key]"
              :disabled="columnChild.disabled"
              :placeholder="columnChild.placeholder"
              clearable
            >
              <el-option
                v-for="i in columnChild.dictsType
                  ? getDictsOptions(columnChild.dictsType)
                  : columnChild.option"
                :label="i.dictLabel"
                :value="i.dictValue"
                :key="i.dictLabel + i.dictValue"
              />
            </el-select>
            <el-date-picker
              v-else-if="$filters.isDatePicker(columnChild.type)"
              v-model="scope.row[columnChild.key]"
              :type="item.type"
              :disabled="columnChild.disabled"
              clearable
              size="small"
              :format="$filters.dateFormat(columnChild.type)"
              :value-format="$filters.dateValueFormat(columnChild.type)"
              :default-value="columnChild.defaultValue"
              :placeholder="columnChild.placeholder || '日期'"
              :start-placeholder="columnChild.startPlaceholder || '开始日期'"
              :end-placeholder="columnChild.endPlaceholder || '结束日期'"
              :picker-options="columnChild.pickerOptions"
            >
            </el-date-picker>
            <span v-else-if="columnChild.dictsType">
              {{
                getDictsName(
                  columnChild.dictsType,
                  scope.row[columnChild.key]
                ) || "--"
              }}
            </span>
            <span v-else-if="columnChild.enumType">
              {{
              (scope.row[columnChild.key] || scope.row[columnChild.key] === 0) &&
              scope.row[columnChild.key] != "null"
                ? $enums[columnChild.enumType].getName(scope.row[columnChild.key] + "")
                : "--"
            }}
            </span>
            <span v-else-if="columnChild.option">
              {{
                getOptionName(scope.row[columnChild.key], columnChild.option) ||
                "--"
              }}
            </span>
            <span v-else :style="typeof scope.row[columnChild.key] === 'number' && scope.row[columnChild.key] < 0 ? 'color: #ff4949' : ''">
              {{
                scope.row[columnChild.key] || scope.row[columnChild.key] === 0
                  ? scope.row[columnChild.key]
                  : "--"
              }}
            </span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="footer" ref="lwTableFooter">
      <div>
        <slot name="footerData" />
      </div>
      <el-pagination
        class="pagination"
        v-if="!table.hidePagination"
        @size-change="handleSizeChange"
        :current-page.sync="table.pagination.pageNum"
        @current-change="handleCurrentChange"
        :page-sizes="[20, 50, 100, 200, 500, 1000]"
        :hide-on-single-page="table.pagination.noPageSizes"
        :page-size="table.pagination.pageSize"
        :layout="
          table.pagination.noJumper
            ? 'total, sizes, prev, pager, next'
            : 'total, sizes, prev, pager, next, jumper'
        "
        :total="table.pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import cell from "./cell";
import searchBar from "./SearchBar.vue";
import houverImg from "./houverImg.vue";
import { isLeft, isRight } from "@/utils/align";
import { insertSysTable, updateSysTable, getSysTable } from "@/api/index";
export default {
  components: {
    cell,
    searchBar,
    houverImg
  },
  //继承table数据、表格合计值数据、行keyName数据（树形结构需要使用）
  props: {
    table: {
      type: Object,
      required: true
    },
    summaryMethod: {
      type: Function,
      default: function() {}
    },
    lwSelectable: {
      type: Function,
      default: function(row) {
        return true;
      }
    },
    lwTableRowClassName: {
      type: Function,
      default: function(row, rowIndex) {
        return "";
      }
    },
    rowKey: {
      type: String,
      default: "id"
    },
    // search完不执行handleSelectionChange
    searchNoCleanSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lwTableHeight: "auto",
      gradeIdOptions: [], //年级数组
      imgsIdArr: [],
      setColumnsVisible: false,
      checkColumnsList: [],
      checkedColumnsList: [],
      setTableId: '',
    };
  },

  computed: {
    tagsView: {
      get() {
        return this.$store.state.settings.tagsView
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        })
      }
    },
    //过滤搜索项插槽
    searchSlotName() {
      return this.table.searchData
        ? this.table.searchData.filter(t => t.slotName && !t.hide)
        : [];
    },
    //过高级搜索项插槽
    highSearchSlotName() {
      return this.table.highSearchData
        ? this.table.highSearchData.filter(t => t.slotName)
        : [];
    },
    //过滤显示列
    Columns() {
      return this.table.columns.filter(
        t => t.type !== "selection" && t.type !== "index" && !t.hidden
      );
    },
    //是否显示序号
    indexColumn() {
      return this.table.columns.filter(t => t.type === "index" && !t.hidden);
    },
    //是否显示多选框
    selectionColumn() {
      return this.table.columns.some(t => t.type === "selection" && !t.hidden);
    }
  },
  watch: {
    table: {
      handler: function(val) {
        if (this.table.data && this.table.data.length > 0) {
          //列为图片id的key数组
          let urlList = this.table.columns.filter(item => {
            if (item.type === "imageId") {
              return item.key;
            }
          });
          urlList = urlList.map(item => {
            return item.key;
          });
          let fileIds = []; //需要查询的图片id数组
          this.table.data.forEach(column => {
            urlList.forEach(key => {
              if (column[key] != null) {
                let list = column[key].split(",");
                fileIds.push(...list);
              }
            });
          });
          let arr = fileIds.filter(item => {
            item = parseInt(item);
            return !isNaN(item);
          });
          if (arr.length) {
            this.$fileInfo(arr).then(res => {
              if (res.data) {
                this.imgsIdArr = Object.values(res.data);
              }
            });
          }
        } else {
          this.imgsIdArr = [];
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    //初始化表格加载状态与表格key
    this.$set(this.table, "searchLoading", false);
    this.$set(this.table, "key", 0);
    //获取表格列数据
    this.table.columns = this.table.columns.filter(t => t);
    //初始化分页数据
    if (!this.table.hidePagination && !this.table.pagination) {
      this.$set(this.table, "pagination", {
        pageNum: 1,
        pageSize: 200,
        total: 0
      });
    }
    this.getDicts("sys_grade").then(response => {
      this.gradeIdOptions = response.data;
    });
  },
  mounted() {
    let vm = this;
    vm.$nextTick(async() => {
      this.getLwTableHeight();
      if(this.table.setColumns){
        let list = await getSysTable({tableUrl: window.location.pathname + this.table.name});
        if(list.data){
          this.$set(this.table, "showColumnsList", JSON.parse(list.data.configuration));
          this.setTableId = list.data.id;
        }
        this.checkColumnsList = this.table.columns.filter(
          t => t.type !== "selection" && t.type !== "index" && !t.hidden
        );
        this.checkedColumnsList = this.table.showColumnsList ? this.table.showColumnsList : this.checkColumnsList.map((el) => {
          return el.label
        });
        let initColumns = this.table.columns.filter(
          t => t.type == "selection" || t.type == "index" || t.hidden
        );
        let showColumns = this.checkColumnsList.filter(
          t => this.checkedColumnsList.includes(t.label)
        );
        this.table.columns = initColumns.concat(showColumns);
        this.table.key ++;
      }
      if (this.table.loadData) {
        this.handleQuery();
      }
    });
  },
  methods: {
    //计算表格高度
    getLwTableHeight() {
      let vm = this;
      let sysHeight = '94px';
      if(this.tagsView){
        sysHeight = '118px';
      }
      if (vm.table.noHeight) {
        return "0px";
      } else if (vm.table.redundantHeight) {
        let height = vm.table.redundantHeight;
        if (height.indexOf("px") < 0) {
          height += Number(height) + "px";
        }
        vm.lwTableHeight = `calc(100vh - ${
          vm.$refs.lwSearchBar.$el.offsetHeight
        }px - ${vm.$refs.lwTableFooter.offsetHeight}px - ${height} - ${sysHeight})`;
      } else {
        vm.lwTableHeight = `calc(100vh - ${
          vm.$refs.lwSearchBar.$el.offsetHeight
        }px - ${vm.$refs.lwTableFooter.offsetHeight}px - ${sysHeight})`;
      }
    },
    //计算列到宽度。
    flexColumnWidth(column) {
      if (!column.key) {
        return column.width;
      }
      if (column.width) {
        //设置了宽度 直接返回
        return column.width;
      }
      //获取列的数据最大长度
      let $columns = [];
      for (let idx in this.table.data) {
        //拿到一行数据
        let item = this.table.data[idx];
        if (item[column.key]) {
          $columns.push(item[column.key]);
        }
      }
      //获取当前列中最大内容
      let columnContent = this.getContentMaxLength($columns) + "";
      let flexWidth = 0;
      for (const char of columnContent) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 12;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配20个单位宽度
          flexWidth += 20;
        } else {
          // 其他种类字符，为字符分配5个单位宽度
          flexWidth += 10;
        }
      }
      if (flexWidth < 100) {
        // 设置最小宽度
        flexWidth = 100;
      }
      if (flexWidth > 250) {
        // 设置最大宽度
        flexWidth = 300;
      }
      return flexWidth + "px";
    },
    //获取数组中内容最大长度到数据
    getContentMaxLength($columns) {
      let $index = 0;
      for (let idx in $columns) {
        if ($columns[idx] == null || $columns[idx] == undefined) {
          return;
        }
        let $nowTemp = $columns[idx];
        let $maxTemp = $columns[$index];
        if ($nowTemp.length > $maxTemp.length) {
          $index = idx;
        }
      }
      return $columns[$index];
    },
    //判断对齐方式
    getAlign(column) {
      // noUseDeafaultKeyAlign 不使用公共的匹配对齐方式 默认值为空(使用) 使用场景: 列表表头为操作,默认为左对齐,此时需要采用自定义对齐方式需传入该字段
      if (column.label && !column.noUseDeafaultKeyAlign) {
        if (isLeft(column.label)) {
          return "left";
        } else if (isRight(column.label)) {
          return "right";
        } else {
          return column.align || "center";
        }
      } else {
        return column.align || "center";
      }
    },
    highSearcherChange() {
      this.$nextTick(() => {
        this.getLwTableHeight();
      });
    },
    selectable(row) {
      if(row.unSelect){
        return false;
      }else{
        return this.lwSelectable(row);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      return this.lwTableRowClassName(row, rowIndex);
    },
    getSummary(param) {
      return this.summaryMethod(param);
    },
    //获取数据字典值名称
    getDictsName(type, key) {
      let dict = this.$allDict().filter(item => {
        return item.dictType == type && item.dictValue == key;
      });
      return dict.length ? dict[0].dictLabel : null;
    },
    //获取枚举名称
    getOptionName(value, option) {
      let dict = option.filter(item => {
        return (item.dictValue = value);
      });
      return dict.length ? dict[0].dictLabel : null;
    },
    //获取下拉控件数据字典数据
    getDictsOptions(type) {
      let list = this.$allDict().filter(item => {
        return item.dictType === type;
      });
      return list;
    },
    //获取图片地址(全路径类型)
    getFilepath(filepathList) {
      if (typeof filepathList === "string") {
        let list = filepathList.split(",");
        return list[0];
      } else {
        return "";
      }
    },
    //获取数组图片地址(全路径类型)
    getFilepathList(filepathList) {
      if (typeof filepathList === "string") {
        let list = filepathList.split(",");
        return list;
      } else {
        return [];
      }
    },
    //搜索事件
    onSearch() {
      return new Promise((resolve, reject) => {
        //搜索按钮防抖
        if (!this.table.searchLoading) {
          if (this.table.noSearch) {
            //抛出不能搜索时的搜索按钮点击事件
            this.$emit("no-search-click", this.table.query);
          } else {
            // 接口调用成功返回
            this.$search(this.table).then(() => {
              resolve(true)
              this.$emit("search-click", this.table.query);
            });
            if (!this.searchNoCleanSelect){
              this.handleSelectionChange([]);
            }
          }
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      if(this.table.pagination) this.table.pagination.pageNum = 1;
      this.onSearch();
    },
    //重置事件
    onReset(query) {
      this.table.query = query;
      this.$emit("on-reset", query);
    },
    //抛出搜索条件-下拉控件切换事件
    searchChange(modelName) {
      this.$emit("search-change", modelName, this.table.query);
    },
    //抛出表格行选择事件
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    handleSelectRowChange(selection, row){
      this.$emit("select", selection,row);
    },
    //当用户手动勾选全选 Checkbox 时触发的事件
    handleSelectAllChange(selection){
      this.$emit("select-all", selection);
    },
    //抛出表格行点击事件
    handleRowClick(row, column, event) {
      this.$emit("row-click", {
        row,
        column,
        event
      });
    },
    //抛出表格行双击事件
    handleRowDbClick(row, column, event) {
      this.$emit("row-dbclick", {
        row,
        column,
        event
      });
    },
    //抛出排序改变事件
    handleSortChange({ column, prop, order }) {
      this.$emit("sort-change", {
        column,
        prop,
        order
      });
    },
    // 年级使用统一排序
    gradeSort(a, b) {
      a = a.gradeId;
      b = b.gradeId;
      if (parseInt(a) > parseInt(b)) {
        return 1;
      } else {
        return -1;
      }
    },
    sortMethod(a, b, column) {
      if (column.sortMethod) {
        return column.sortMethod(a, b);
      } else if(column.sortable){
        if (typeof a[column.key]=='string') {
            if (a[column.key]>b[column.key]) {
              return 1;
            } else if (a[column.key]<b[column.key]){
              return -1;
            } else {
              return 0;
            }
        } else {
          return a[column.key] - b[column.key];
        }
      }
    },
    //页面条数改变事件
    handleSizeChange(val) {
      if(this.table.pagination) this.table.pagination.pageNum = 1;
      if(this.table.pagination) this.table.pagination.pageSize = val;
      if(!this.table.searchNoAuto)this.onSearch();
      this.$emit("size-change", val, this.table.query);
    },
    //抛出页数改变事件
    handleCurrentChange(val) {
     if(!this.table.searchNoAuto)
     this.onSearch().then((res) => {
      this.$emit("current-change", val, this.table.query);
     });
    },
    //表格金额输入框聚焦移除事件
    amountBlur(value, inputType, row, key) {
      if (inputType === "amount") {
        row[key] = this.$filters.amountFormat(value);
      }
    },
    //表格金额输入框聚焦事件
    amountFoucs(value, inputType, row, key) {
      if (inputType === "amount") {
        row[key] = this.$filters.amountNumber(value);
      }
    },
    showColumnsChange(list) {
      this.checkedColumnsList = list;
    },
    async submitShowColumns(){
      if(this.setTableId){
        await updateSysTable({
          id: this.setTableId,
          tableUrl: window.location.pathname + this.table.name,
          configuration: JSON.stringify(this.checkedColumnsList),
        });
      }else{
        await insertSysTable({
          tableUrl: window.location.pathname + this.table.name,
          configuration: JSON.stringify(this.checkedColumnsList),
        });
      }
      this.msgSuccess('保存成功');
      this.$set(this.table, "showColumnsList", JSON.parse(JSON.stringify(this.checkedColumnsList)));
      let initColumns = this.table.columns.filter(
        t => t.type == "selection" || t.type == "index" || t.hidden
      );
      let showColumns = this.checkColumnsList.filter(
        t => this.checkedColumnsList.includes(t.label)
      );
      this.table.columns = initColumns.concat(showColumns);
      this.setColumnsVisible = false;
      this.table.key ++;
    },
    showCheckColumns(){
      this.checkedColumnsList = this.table.showColumnsList ? this.table.showColumnsList : this.checkColumnsList.map((el) => {
        return el.label;
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";

.lw-table {
  background: #fff;
  .table-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    .img {
      width: 48px;
      height: 32px;
      cursor: zoom-in;
    }
    img {
      height: 32px;
    }
  }

  .footer {
    @include hor-between-start;
    padding-top: 16px;

    .pagination {
      text-align: right;
      background: #fff;
    }
  }

  .amount-input {
    input {
      text-align: right;
    }
  }
}

.el-button {
  .el-loading-spinner {
    top: 85% !important;
  }

  .el-loading-spinner .circular {
    height: 20px !important;
    width: 20px !important;
  }
}
.el-table .warning-row {
  background: #fef0f0;
}
.set-columns{
  z-index: 1000;
  position: relative;
  height: 38px;
  line-height: 38px;
  float: right;
  margin-bottom: -38px;
  margin-right: 10px;
  .setting{
    padding: 4px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.16);
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
}
.checkbox-group {
  width: 340px;
  padding-bottom: 14px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
  .checkbox {
    width: 140px;
  }
}
.checkbox-button{
  display: flex;
  justify-content: end;
}

</style>
