export default {
  dateFormat(row, column, cellValue, index) {
    const daterc = cellValue
    const paddNum = function (num) {
      num += ''
      return num.replace(/^(\d)$/, '0$1')
    }
    if (daterc != null) {
      const dateMat = new Date(daterc);
      const year = dateMat.getFullYear();
      const month = paddNum(dateMat.getMonth() + 1);
      const day = paddNum(dateMat.getDate());
      const timeFormat = year + "-" + month + "-" + day;
      return timeFormat;
    }
  },

  moneyFormat(row,column,str,index){
    if(str && parseFloat(str)!==0){
      var money = parseFloat(str)
      money = money.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      return money
    }
    return "-"
  },

  formatterMoney(str){
    if(str){
      var money = parseFloat(str)
      money = money.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      return money
    }
    return '-'
  },

  formatDate(date, format) {
    const paddNum = function (num) {
      num += '';
      return num.replace(/^(\d)$/, '0$1');
    };

    if (date.length && date.length == 10) {
      return date;
    }

    date = new Date(date);

    // 指定格式字符
    const cfg = {
      yyyy: date.getFullYear(),
      yy: date.getFullYear().toString().substring(2),
      M: date.getMonth() + 1,
      MM: paddNum(date.getMonth() + 1),
      d: date.getDate(),
      dd: paddNum(date.getDate()),
      hh: paddNum(date.getHours()),
      mm: paddNum(date.getMinutes()),
      ss: paddNum(date.getSeconds())
    };
    format || (format = 'yyyy-MM-dd hh:mm:ss');
    return format.replace(/([a-z])(\1)*/ig, function (m) {
      return cfg[m];
    });
  },
}
