
const tips = {
    renderHeaderFn(h, { column }) {
        return (
            <span>
                <span>{column.label}</span>
                <el-tooltip placement="right" effect="light" content="红色：图9">
                    <i class="el-icon-info" style="margin-left: 5px"></i>
                </el-tooltip>
            </span>
        );
    },
}
export default tips;