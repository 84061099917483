import request from '@/utils/request'

// 查询省产品目录加工列表
export function listProcess(query) {
  return request({
    url: '/company/process/list',
    method: 'get',
    params: query
  })
}

// 查询省产品目录加工详细
export function getProcess(processId) {
  return request({
    url: '/company/process/' + processId,
    method: 'get'
  })
}

// 新增省产品目录加工
export function addProcess(data) {
  return request({
    url: '/company/process',
    method: 'post',
    data: data
  })
}

// 修改省产品目录加工
export function updateProcess(data) {
  return request({
    url: '/company/process',
    method: 'put',
    data: data
  })
}

// 删除省产品目录加工
export function delProcess(processId) {
  return request({
    url: '/company/process/' + processId,
    method: 'delete'
  })
}

// 导出省产品目录加工
export function exportProcess(query) {
  return request({
    url: '/company/process/export',
    method: 'get',
    params: query
  })
}

// 查询出版社信息列表
export function listpress(query) {
  return request({
    url: '/system/press/list',
    method: 'get',
    params: query
  })
}

// 查询产品分类管理列表
export function listCategory(query) {
  return request({
    url: '/system/category/list',
    method: 'get',
    params: query
  })
}

