import Cookies from 'js-cookie'

const state = {
  customerSendList: new Array(), //客户发货
  provinceHarvestList: new Array(), //总部收货管理-收货单管理
  linePrintList: new Array, //总部发货管理-品种发货-打印
  refundPrintList: new Array(), //总部退款-向供应商退款-打印
  refundHarvestList: new Array(), //仓储管理/总部收货管理/收子分公司退货
  parcelWorkList: new Array(), //总部收货-交包
}

const mutations = {
  SET_CUSTOMER_SEND(state, customerSendList) {
    state.customerSendList = customerSendList;
  },
  SET_PROVINCE_HARVEST(state, provinceHarvestList) {
    state.provinceHarvestList = provinceHarvestList;
  },
  SET_REFUND_HARVEST_LIST(state, refundHarvestList) {
    state.refundHarvestList = refundHarvestList;
  },
  SET_LINE_PRINT(state, linePrintList) {
    state.linePrintList = linePrintList;
  },
  SET_REFUND_PRINT(state, refundPrintList) {
    state.refundPrintList = refundPrintList;
  },
   SET_PARCEL_WORK_LIST(state, parcelWorkList) {
    state.parcelWorkList = parcelWorkList;
  },

}

const actions = {
  setParcelWorkList({
    commit,
    state
  }, parcelWorkList) {
    commit('SET_PARCEL_WORK_LIST', parcelWorkList);
  },
  setCustomerSendList({
    commit,
    state
  }, customerSendList) {
    commit('SET_CUSTOMER_SEND', customerSendList);
  },
  setProvinceHarvestList({
    commit,
    state
  }, provinceHarvestList) {
    commit('SET_PROVINCE_HARVEST', provinceHarvestList);
  },
  setRefundHarvestList({
    commit,
    state
  }, refundHarvestList) {
    commit('SET_REFUND_HARVEST_LIST', refundHarvestList);
  },
  setLinePrintList({
    commit,
    state
  }, linePrintList) {
    commit('SET_LINE_PRINT', linePrintList);
  },
  setRefundPrintList({
    commit,
    state
  }, refundPrintList) {
    commit('SET_REFUND_PRINT', refundPrintList);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}