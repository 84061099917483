import JSEncrypt from 'jsencrypt/bin/jsencrypt'

const RSAEncrypt = {
    // 加密
    encrypt: function(txt, pubKey) {
        const encryptor = new JSEncrypt()
        encryptor.setPublicKey(pubKey) // 设置公钥
        return encryptor.encrypt(txt) // 对需要加密的数据进行加密
    },
    // 解密
    decrypt: function(txt, privKey) {
        const encryptor = new JSEncrypt()
        encryptor.setPrivateKey(privKey)
        return encryptor.decrypt(txt)
    }
}

export default RSAEncrypt