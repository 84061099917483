<template>
  <div>
    <el-input
      v-model="realValue"
      class="amount-input"
      :style="{ width: width }"
      :disabled="disabled"
      :placeholder="placeholder"
      @blur="amountBlur()"
      @focus="amountFocus()"
      @change="amountChange()"
      clearable
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请输入金额"
    },
    width: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      realValue: ""
    };
  },
  watch: {
    value: {
      handler(val) {
        this.realValue = this.$filters.amountFormat(val);
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.realValue = this.$filters.amountFormat(this.value);
  },
  methods: {
    amountBlur() {
      this.realValue = this.$filters.amountFormat(this.realValue);
      this.$emit(
        "update:value",
        this.$filters.amountNumber(this.realValue).toString()
      );
    },
    amountFocus() {
      this.realValue = this.$filters.amountNumber(this.realValue);
      this.$emit(
        "focus",
        this.$filters.amountNumber(this.realValue).toString()
      );
    },
    amountChange() {
      this.$emit(
        "change",
        this.$filters.amountNumber(this.realValue).toString()
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.amount-input {
  input {
    text-align: right;
  }
}
</style>
