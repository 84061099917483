<template>
  <div @click="clickRouter(item)" v-if="!item.hidden" class="card">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <div class="second-menu" @click="$emit('menuClose')">
          {{ onlyOneChild.meta.title }}
        </div>
      </app-link>
    </template>

    <div v-else class="card-info">
      <div class="second-menu">
        {{ item.meta.title }}
      </div>
      <menu-item
        v-for="(child, index) in item.children"
        :key="child.path + index"
        :is-nest="true"
        :item="child"
        :actionIndex="actionIndex"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
        :class="{ 'nest-menu-children': child.children ? true : false }"
        @menuClose="$emit('menuClose')"
        @menuClick="$emit('menuClick')"
      />
    </div>
  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import AppLink from "./Link";
import menuItem from "./menuItem.vue";

export default {
  name: "MenuCard",
  components: { AppLink, menuItem },
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
    actionIndex: {
      required: true,
    },
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    clickRouter(e, a) {},
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          //使用最后一个子项数据
          this.onlyOneChild = item;
          this.onlyOneChild.meta.tagsIndex = this.actionIndex;
          return true;
        }
      });
      // 当只有一个子路由器时，默认情况下会显示子路由器
      if (showingChildren.length === 1) {
        return true;
      }
      // 如果没有要显示的子路由器，则显示父路由器
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      //判断是否为外链
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  border-bottom: 1px solid #eff0f1;
  padding-bottom: 8px;
  width: 164px;
  margin: 0 8px 0 4px;
  .second-menu {
    color: #979899;
    font-size: 12px;
    font-weight: 400;
    font-family: PingFang SC, PingFang SC;
    line-height: 32px;
    margin-top: 8px;
    padding-left: 4px;
  }
  ::v-deep .el-menu-item {
    height: 44px !important;
    line-height: 40px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: 150px !important;
    span{
      padding-left: 4px;
    }
  }
  ::v-deep .el-submenu__title {
    height: 44px !important;
    line-height: 40px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    span{
      padding-left: 4px;
    }
  }
}
</style>
