<template>
  <div>
    <svg-icon icon-class="question" @click="goto"/>
  </div>
</template>

<script>
export default {
  name: 'LinkDoc',
  data() {
    return {
      url: 'https://www.feinote.com/link-vue-doc'
    }
  },
  methods: {
    goto() {
      window.open(this.url)
    }
  }
}
</script>