import {
  fileInfo
} from '@/api/upload/uploadTool.js'
const state = {
  imgsIdArr: []
}

const mutations = {
  IMGID_ARR(state, idArr) {
    state.imgsIdArr = idArr
  }
}

const actions = {
  getImg_id({
    commit,
    state
  }, arr) {
    arr = arr.filter(item => {
      item = parseInt(item)
      return !isNaN(item)
    })

    fileInfo(arr).then(res => {
      // console.log('----',res)
      if (res.data) {

        let newArr = state.imgsIdArr ? [...state.imgsIdArr] : []
        let arrToAdd = Object.values(res.data)
        arrToAdd.forEach(toAdd => {
          if (newArr.findIndex(old => old.id == toAdd.id) < 0) {
            newArr.push(toAdd)
          }
        })

        if (newArr && newArr.length > 0) {
          newArr = newArr.filter((item, index) => {
            return index <= 300
          })
        }
        commit('IMGID_ARR', newArr)

      }
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
