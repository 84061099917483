import Vue from "vue";
import filters from "@/utils/filters";
import enums from "@/utils/enums";
import store from '@/store'
import {listData} from "@/api/system/dict/data";

//筛选函数，以后新加过滤函数都可加在里面
Vue.prototype.$filters = filters;
Vue.prototype.$enums = enums;

/**
 * 表格查询事件
 * @table 需要查询的表格数据
 * **/
Vue.prototype.$search = async (table) => {
  if (table && table.api) {
    //是否获取全局年度与学季
    if (table.globalCriteria) {
      table.query.year = localStorage.getItem("year");
      table.query.quarterId = localStorage.getItem("quarterId");
      table.query.quarterName = localStorage.getItem("quarterName");
    }
    let query = JSON.parse(JSON.stringify(table.query)) || {};
    //查询条件值为数组需要改为拼接字符串(适用于多选下拉框)，strKeyList需要拼接的字段名数组集合，splitList拼接的字符集合数组。
    if (table.strKeyList && table.splitList) {
      for (let key in query) {
        if (table.strKeyList.includes(key)) {
          let index = table.strKeyList.indexOf(key);
          query[key] = query[key].join(table.splitList[index]);
        }
      }
    }
    //分页并且没有指定分页数据的时候默认分页数据
    if (!table.hidePagination) {
      query.pageNum = table.pagination ? table.pagination.pageNum : 1;
      query.pageSize = table.pagination ? table.pagination.pageSize : 200;
    }
    try {
      table.loading = true;
      table.searchLoading = true;
      let res = await table.api(query);
      let resData=undefined;
      if (res.rows instanceof Array) {
        resData = res.rows;
      } else {
        resData = res.data;
      }
      //对接口返回的表格数据进行处理(有需要的情况下，传handleDataFuction方法在table对象)
      if(resData && table.handleDataFuction){
        table.handleDataFuction(resData);
      }
      //对接口返回的表格数据进行处理(有需要的情况下，传handleAndReturnDataFuction方法在table对象)
      if(resData && table.handleAndReturnDataFuction){
        resData = table.handleAndReturnDataFuction(resData);
      }
      table.data = resData;
      if (!table.hidePagination) {
        table.pagination.total = res.total || res.total >= 0 ? res.total : res.data.total;
      }
      //判断表格操作列显示隐藏(有需要的情况下，传showAction方法在table对象)
      if (table.showAction) {
        let showList = table.data.filter((el) => {
          return table.showAction(el);
        });
        table.columns.forEach(el => {
          if(el.label == "操作"){
            if(showList.length === 0){
              el.hidden = true;
            }else{
              el.hidden = false;
            }
          }
        });
      }

      table.loading = false;
      table.searchLoading = false;
      table.key++;
      return new Promise(resolve => {resolve(res)})
    } catch (e) {
      table.loading = false;
      table.searchLoading = false;
    }
  }
};
/**
 * 表格查询返回结果数组
 * @table 需要查询的表格数据
 * **/
 Vue.prototype.$search2 = async (table, dom) => {
  if (table && table.api) {
    //是否获取全局年度与学季
    if (table.globalCriteria) {
      table.query.year = localStorage.getItem("year");
      table.query.quarterId = localStorage.getItem("quarterId");
      table.query.quarterName = localStorage.getItem("quarterName");
    }
    let query = JSON.parse(JSON.stringify(table.query)) || {};
    //查询条件值为数组需要改为拼接字符串(适用于多选下拉框)，strKeyList需要拼接的字段名数组集合，splitList拼接的字符集合数组。
    if (table.strKeyList && table.splitList) {
      for (let key in query) {
        if (table.strKeyList.includes(key)) {
          let index = table.strKeyList.indexOf(key);
          query[key] = query[key].join(table.splitList[index]);
        }
      }
    }
    //分页并且没有指定分页数据的时候默认分页数据
    if (!table.hidePagination) {
      query.pageNum = table.pagination ? table.pagination.pageNum : 1;
      query.pageSize = table.pagination ? table.pagination.pageSize : 200;
    }
    try {
      table.loading = true;
      table.searchLoading = true;
      let res = await table.api(query);
      let resData=undefined;
      if (res.rows instanceof Array) {
        resData = res.rows;
      } else {
        resData = res.data;
      }
      //对接口返回的表格数据进行处理(有需要的情况下，传handleDataFuction方法在table对象)
      if(resData && table.handleDataFuction){
        table.handleDataFuction(resData);
      }
      //对接口返回的表格数据进行处理(有需要的情况下，传handleAndReturnDataFuction方法在table对象)
      if(resData && table.handleAndReturnDataFuction){
        resData = table.handleAndReturnDataFuction(resData);
      }
      table.data = resData;
      if (!table.hidePagination) {
        table.pagination.total = res.total || res.total >= 0 ? res.total : res.data.total;
      }
      //判断表格操作列显示隐藏(有需要的情况下，传showAction方法在table对象)
      if (table.showAction) {
        let showList = resData.filter((el) => {
          return table.showAction(el);
        });
        table.columns.forEach(el => {
          if(el.label == "操作"){
            if(showList.length === 0){
              el.hidden = true;
            }else{
              el.hidden = false;
            }
          }
        });
      }
      table.loading = false;
      table.searchLoading = false;
      dom.reloadData(resData);
    } catch (e) {
      table.loading = false;
      table.searchLoading = false;
    }
  }
};

//获取用户信息
Vue.prototype.$userInfo = () => {
  let u = JSON.parse(localStorage.getItem("userInfo"));
  if (!u) {
    store.dispatch('setDictData', []);
    localStorage.clear();
    store.dispatch('LogOut').then(() => {
      location.href = '/index';
    })
  }
  return u;
};

//查询数据字典全部列表信息（若无则从接口获取）
Vue.prototype.$allDict = () => {
  if (Object.is(store.getters.allDictData, null)
  || Object.is(store.getters.allDictData, undefined)
  || Object.keys(store.getters.allDictData).length == 0) {
    let allDict = localStorage.getItem("allDict");
    if(allDict){
      store.dispatch('setDictData', JSON.parse(allDict));
      allDict = '';
    }else{
      listData().then((response) => {
        store.dispatch('setDictData', response.rows);
        localStorage.setItem("allDict", JSON.stringify(response.rows));
      });
    }
  };
  return store.getters.allDictData;
};

//下载（打开新页的下载方式）
Vue.prototype.$downloadFile = url => {
  if (url) {
    window.open(url);
    // window.location.href = url
  }
};
