import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/link";

// 查询用户列表
export function listUser(query) {
    return request({
        url: '/system/user/list',
        method: 'get',
        params: query
    })
}


// 查询用户列表
export function queryProvinceUserList(query) {
    return request({
        url: '/system/user/queryProvinceUserList',
        method: 'get',
        params: query
    })
}

// 查询学校管理员用户列表
export function schoolManageList(query) {
    return request({
        url: '/system/user/schoolManageList',
        method: 'get',
        params: query
    })
}
// 查询子公司管理员用户列表
export function companyManageList(query) {
    return request({
        url: '/system/user/companyManageList',
        method: 'get',
        params: query
    })
}

// 查询用户详细
export function getUser(userId) {
    return request({
        url: '/system/user/' + praseStrEmpty(userId),
        method: 'get'
    })
}

// 新增用户
export function addUser(data) {
    return request({
        url: '/system/user',
        method: 'post',
        data: data
    })
}

// 修改用户
export function updateUser(data) {
    return request({
        url: '/system/user',
        method: 'put',
        data: data
    })
}

// 删除用户
export function delUser(userId) {
    return request({
        url: '/system/user/' + userId,
        method: 'delete'
    })
}

// 导出用户
export function exportUser(query) {
    return request({
        url: '/system/user/export',
        method: 'get',
        params: query
    })
}
//导出用户管理员
export function companyExportUser(query) {
  return request({
    url: '/system/user/companyExport',
    method: 'get',
    params: query
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
    const data = {
        userId,
        password
    }
    return request({
        url: '/system/user/resetPwd',
        method: 'put',
        data: data
    })
}

// 登录解锁
export function userUnlock(userName) {
    const data = {
        userName
    }
    return request({
        url: '/system/user/unlock',
        method: 'put',
        data: data
    })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
    const data = {
        userId,
        status
    }
    return request({
        url: '/system/user/changeStatus',
        method: 'put',
        data: data
    })
}

// 查询用户个人信息
export function getUserProfile() {
    return request({
        url: '/system/user/profile',
        method: 'get'
    })
}

// 修改用户个人信息
export function updateUserProfile(data) {
    return request({
        url: '/system/user/profile',
        method: 'put',
        data: data
    })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
    const data = {
        oldPassword,
        newPassword
    }
    return request({
        url: '/system/user/profile/updatePwd',
        method: 'put',
        params: data
    })
}

// 用户头像上传
export function uploadAvatar(data) {
    return request({
        url: '/system/user/profile/avatar',
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

// 下载用户导入模板
export function importTemplate() {
    return request({
        url: '/system/user/importTemplate',
        method: 'get'
    })
}

// 下载用户导入模板
export function importTemplate2() {
  return request({
    url: '/system/user/importTemplate2',
    method: 'get'
  })
}

// 查询用户列表
export function listUser2(query) {
    return request({
        url: '/system/user/list2',
        method: 'get',
        params: query
    })
}

// 导出用户
export function exportUser2(query) {
  return request({
    url: '/system/user/export2',
    method: 'get',
    params: query
  })
}

//批量禁用启用
export function updateStatus(query,status) {
  return request({
    url: '/system/user/updateStatus/'+query+'/'+status,
    method: 'put',
  })
}

export function getUserSetting() {
  return request({
    url: '/system/user/getUserSetting',
    method: 'get',
  })
}

export function setUserSetting(data) {
  return request({
    url: '/system/user/setUserSetting',
    method: 'post',
    data: data
  })
}
export function queryUserList() {
  return request({
    url: '/system/user/queryUserList',
    method: 'get',
  })
}



// 查询用户列表
export function queryCompanyUserInfoList(query) {
    return request({
        url: '/system/user/queryCompanyUserInfoList',
        method: 'get',
    })
}

export function queryProvinceUserListUseronTop(query) {
  return request({
    url: '/system/user/queryProvinceUserList/CurrentUserOnTop',
    method: 'get',
    params: query
  })
}
