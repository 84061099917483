<template>
  <div class="search">
    <div class="search-bar" >
      <div class="search-box" v-if="searchDataIsVisible.length">
        <!-- 系统子分公司禁用状态则进行隐藏处理（刘总提出） -->
        <div
          class="form"
          v-for="(item, index) in searchDataIsVisible"
          :key="index"
          v-show="!item.hide && (!item.disabled || item.model != 'companyId')"
        >
          <slot v-if="item.slotName" :name="`search${item.slotName}`"> </slot>
          <div v-if="item.type === 'input'" class="input">
            <el-input
              v-model="query[item.model]"
              :style="{ width: item.width }"
              :disabled="item.disabled"
              :placeholder="item.placeholder"
              :maxlength="50"
              :clearable="!item.clearDisabled"
              @keyup.enter.native="doSearch()"
            ></el-input>
          </div>
          <div v-if="item.type === 'select'" class="select">
            <el-select
              v-model="query[item.model]"
              :style="{ width: item.width }"
              :disabled="item.disabled"
              :clearable="!item.clearDisabled"
              filterable
              default-first-option
              :placeholder="item.placeholder"
              :multiple="item.multiple"
              :collapse-tags="item.collapseTags"
              :no-data-text="item.noDataText"
              @change="emitSearchChange(item.model)"
            >
              <el-option
                v-for="(i, index) in item.dictsType
                  ? getDictsOptions(item.dictsType,item.disabledDictsValues)
                  : item.option"
                :label="item.labelKey?i[item.labelKey]:i.dictLabel"
                :value="item.valueKey?i[item.valueKey]:i.dictValue"
                :key="item.valueKey?i[item.valueKey]:i.dictLabel+index"
              />
            </el-select>
          </div>
          <div v-if="$filters.isDatePicker(item.type)" class="date">
            <el-date-picker
              v-if="item.type === 'daterange' || item.type === 'datetimerange'||item.type==='monthrange'"
              v-model="dateValue"
              :style="{ width: item.width }"
              :disabled="item.disabled"
              :type="item.type"
              align="center"
              unlink-panels
              :clearable="!item.clearDisabled"
              size="small"
              :format="$filters.dateFormat(item.type)"
              :value-format="$filters.dateValueFormat(item.type)"
              :start-placeholder="item.startPlaceholder || '开始日期'"
              :end-placeholder="item.endPlaceholder || '结束日期'"
              :picker-options="item.pickerOptions"
              @change="daterangeDateChange($event, item)"
            >
            </el-date-picker>
            <el-date-picker
              v-else
              v-model="query[item.model]"
              :style="{ width: item.width }"
              :disabled="item.disabled"
              :type="item.type"
              align="center"
              unlink-panels
              :clearable="!item.clearDisabled"
              size="small"
              :format="$filters.dateFormat(item.type)"
              :value-format="$filters.dateValueFormat(item.type)"
              :default-value="item.defaultValue"
              :placeholder="item.placeholder || '日期'"
              :picker-options="item.pickerOptions"
              @change="dateChange($event, item)"
            >
            </el-date-picker>
          </div>
          <div v-if="item.type === 'cascader'" class="cascader">
            <el-cascader
              :style="{ width: item.width }"
              v-model="query[item.model]"
              :options="item.option"
              :props="item.props"
              :disabled="item.disabled"
              :clearable="!item.clearDisabled"
              @change="cascaderChange($event, item)"
            >
            </el-cascader>
          </div>
          <div v-if="item.type === 'selectMore'" class="select">
            <SelectMore
              v-model="query[item.model]"
              :styleObj="{ width: item.width }"
              :disabled="item.disabled"
              :clearable="!item.clearDisabled"
              :placeholder="item.placeholder"
              :multiple="item.multiple"
              :labelKey="item.labelKey"
              :valueKey="item.valueKey"
              :collapseTags="item.collapseTags"
              :no-data-text="item.noDataText"
              :option="
                item.dictsType ? getDictsOptions(item.dictsType,item.disabledDictsValues) : item.option
              "
            />
          </div>
        </div>
        <div class="high-search-button">
          <el-button type="danger" :disabled="searchLoading" @click="doSearch()">
            <i class="el-icon-search"></i>
            搜索
          </el-button>
          <el-button v-if="!noReset" @click="doReset()" type="danger" plain>
            <i class="el-icon-refresh"></i>
            重置
          </el-button>
        </div>
        <div
          v-if="highSearchDataIsVisible.length"
          class="high-search"
          @click="highSearcherClick"
        >
          <img
            v-if="highSearcherShow"
            src="@/assets/image/high_searching.png"
          />
          <img v-else src="@/assets/image/high_search.png" />
          <span :style="{ color: highSearcherShow ? '#ff4949' : '#a6abb5', 'font-size': '12px' }"
            >高级搜索</span
          >
          <i
            v-if="highSearcherShow"
            class="el-select__caret el-input__icon el-icon-arrow-down"
            style="color: #ff4949;height: 32px; line-height: 32px;"
          ></i>
          <i
            v-else
            class="el-select__caret el-input__icon el-icon-arrow-up"
            style="color: #a6abb5;height: 32px; line-height: 32px;"
          ></i>
        </div>
      </div>
     <div class="header-info">
       <span slot="header">
        <slot name="header"></slot>
      </span>
      </div>
      <div class="ctrl-btn">
        <slot name="after" />
      </div>
    </div>
    <div class="search-box" v-show="highSearcherShow">
      <div
        class="form"
        v-for="(item, idx) in highSearchDataIsVisible"
        :key="idx"
        v-show="!item.hide"
      >
        <slot v-if="item.slotName" :name="`highSearch${item.slotName}`"> </slot>
        <div v-if="item.type === 'input'" class="input">
          <el-input
            v-model="query[item.model]"
            :style="{ width: item.width }"
            :disabled="item.disabled"
            :placeholder="item.placeholder"
            :maxlength="50"
            :clearable="!item.clearDisabled"
            @keyup.enter.native="doSearch()"
          ></el-input>
        </div>
        <div v-if="item.type === 'select'" class="select">
          <el-select
            v-model="query[item.model]"
            :style="{ width: item.width }"
            :disabled="item.disabled"
            :clearable="!item.clearDisabled"
            filterable
            default-first-option
            :placeholder="item.placeholder"
            :multiple="item.multiple"
            :collapse-tags="item.collapseTags"
            :no-data-text="item.noDataText"
            @change="emitSearchChange(item.model)"
          >
            <el-option
              v-for="(i, index) in item.dictsType
                ? getDictsOptions(item.dictsType,item.disabledDictsValues)
                : item.option"
              :label="item.labelKey?i[item.labelKey]:i.dictLabel"
              :value="item.valueKey?i[item.valueKey]:i.dictValue"
              :key="item.valueKey?i[item.valueKey]:i.dictLabel+index"
            />
          </el-select>
        </div>
        <div v-if="$filters.isDatePicker(item.type)" class="date">
          <el-date-picker
            v-if="item.type === 'daterange' || item.type === 'datetimerange'||item.type==='monthrange'"
            v-model="highDateValue"
            :style="{ width: item.width }"
            :disabled="item.disabled"
            :type="item.type"
            align="center"
            unlink-panels
            :clearable="!item.clearDisabled"
            size="small"
            :format="$filters.dateFormat(item.type)"
            :value-format="$filters.dateValueFormat(item.type)"
            :start-placeholder="item.startPlaceholder || '开始日期'"
            :end-placeholder="item.endPlaceholder || '结束日期'"
            :picker-options="item.pickerOptions"
            @change="daterangeDateChange($event, item)"
          >
          </el-date-picker>
          <el-date-picker
            v-else
            v-model="query[item.model]"
            :style="{ width: item.width }"
            :disabled="item.disabled"
            :type="item.type"
            align="center"
            unlink-panels
            :clearable="!item.clearDisabled"
            size="small"
            :format="$filters.dateFormat(item.type)"
            :value-format="$filters.dateValueFormat(item.type)"
            :default-value="item.defaultValue"
            :placeholder="item.placeholder || '日期'"
            :picker-options="item.pickerOptions"
            @change="dateChange($event, item)"
          >
          </el-date-picker>
        </div>
        <div v-if="item.type === 'cascader'" class="cascader">
          <el-cascader
            :style="{ width: item.width }"
            v-model="query[item.model]"
            :options="item.option"
            :props="item.props"
            :disabled="item.disabled"
            :clearable="!item.clearDisabled"
            @change="cascaderChange($event, item)"
          >
          </el-cascader>
        </div>
        <div v-if="item.type === 'selectMore'" class="select">
          <SelectMore
            v-model="query[item.model]"
            :styleObj="{ width: item.width }"
            :disabled="item.disabled"
            :clearable="!item.clearDisabled"
            :placeholder="item.placeholder"
            :multiple="item.multiple"
            :labelKey="item.labelKey"
            :valueKey="item.valueKey"
            :collapseTags="item.collapseTags"
            :no-data-text="item.noDataText"
            :option="
              item.dictsType ? getDictsOptions(item.dictsType,item.disabledDictsValues) : item.option
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import SelectMore from "../SelectMore/index.vue";
export default {
  components: {
    SelectMore,
  },
  //继承查询条件与搜索数据
  props: {
    query: {
      default() {
        return {};
      }
    },
    searchData: {
      type: Array,
      default() {
        return [];
      }
    },
    highSearchData: {
      type: Array,
      default() {
        return [];
      }
    },
    searchLoading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    noReset: {
      type: Boolean,
    },
  },
  data() {
    return {
      dateValue: null, //日期控件值
      highDateValue: null, //高级搜索日期控件值
      initQuery: null,
      highSearcherShow: false
    };
  },
  mounted() {
    //若日期控件有默认值则进行赋值
    this.initData();
    //保存初始查询值
    this.initQuery = JSON.parse(JSON.stringify(this.query));
  },
  computed: {
    //过滤显示搜索项
    searchDataIsVisible() {
      return this.searchData.filter(t => !t.hide);
    },
    //过滤显示高级搜索项
    highSearchDataIsVisible() {
      return this.highSearchData.filter(t => !t.hide);
    }
  },
  methods: {
    initData(){
      for (let i = 0; i < this.searchData.length; i++) {
        let date = this.searchData[i];
        if (this.$filters.isDatePicker(date.type) && date.defaultValue) {
          if (date.type === "daterange") {
            this.query[date.modelName[0]] = dayjs(date.defaultValue[0]).format('YYYY-MM-DD');
            this.query[date.modelName[1]] = dayjs(date.defaultValue[1]).format('YYYY-MM-DD');
            this.dateValue = date.defaultValue
          } else if (date.type === "datetimerange") {
            this.query[date.modelName[0]] = dayjs(date.defaultValue[0]).format('YYYY-MM-DD HH:mm:ss');
            this.query[date.modelName[1]] = dayjs(date.defaultValue[1]).format('YYYY-MM-DD HH:mm:ss');
            this.dateValue = date.defaultValue
          } else if (date.type === "monthrange") {
              this.query[date.modelName[0]] = dayjs(date.defaultValue[0]).format('YYYYMM');
              this.query[date.modelName[1]] = dayjs(date.defaultValue[1]).format('YYYYMM');
              this.dateValue = date.defaultValue
            }  else{
            this.$set(this.query, date.model, date.defaultValue);
          }
        }
      }
      for (let i = 0; i < this.highSearchData.length; i++) {
        let date = this.highSearchData[i];
        if (this.$filters.isDatePicker(date.type) && date.defaultValue) {
          if (date.type === "daterange") {
            this.query[date.modelName[0]] = dayjs(date.defaultValue[0]).format('YYYY-MM-DD');
            this.query[date.modelName[1]] = dayjs(date.defaultValue[1]).format('YYYY-MM-DD');
            this.highDateValue = date.defaultValue
          } else if (date.type === "datetimerange") {
            this.query[date.modelName[0]] = dayjs(date.defaultValue[0]).format('YYYY-MM-DD HH:mm:ss');
            this.query[date.modelName[1]] = dayjs(date.defaultValue[1]).format('YYYY-MM-DD HH:mm:ss');
            this.highDateValue = date.defaultValue
          } else if (date.type === "monthrange") {
            this.query[date.modelName[0]] = dayjs(date.defaultValue[0]).format('YYYYMM');
            this.query[date.modelName[1]] = dayjs(date.defaultValue[1]).format('YYYYMM');
            this.highDateValue = date.defaultValue
          }else {
            this.$set(this.query, date.model, date.defaultValue);
          }
        }
      }
    },
    //查询事件
    doSearch() {
      if (this.doCheckSearch(this.searchData)) {
        return;
      }
      if (this.doCheckSearch(this.highSearchData)) {
        return;
      }
      this.$emit("on-search");
    },
    doCheckSearch(search) {
      if (search) {
        return search.some(item => {
          let $required = item.required;
          if ($required) {
            let modelValue = this.query[item.model];
            let placeholder = item.placeholder;
            let type = item.type;
            if (modelValue == undefined) {
              let prefix = type == "input" ? "请输入" : "请选择";
              this.$message.error(prefix + "" + placeholder);
              return true;
            }
          }
          return false;
        });
      }
      return false;
    },
    highSearcherClick(){
      this.highSearcherShow = !this.highSearcherShow;
      this.$emit('high-searcher-change');
    },
    //重置事件
    doReset() {
      this.$emit("on-reset", this.initQuery);
      this.dateValue = null;
      this.highDateValue = null;
      this.initQuery = JSON.parse(JSON.stringify(this.initQuery));
      this.$nextTick(() => {
        this.initData();
        this.doSearch();
      })
    },
    //获取下拉控件数据字典数据
    getDictsOptions(type,disabledDictsValues) {
      let list = this.$allDict().filter(item => {
        if(disabledDictsValues&&disabledDictsValues.length>0){
          let has=false;
          for(let i in disabledDictsValues){
            let disabledValue=disabledDictsValues[i];
            if(item.dictValue===disabledValue){
              has=true;
              break;
            }
          }
          return item.dictType === type && item.status == 0&&!has;
        }else{
          return item.dictType === type && item.status == 0;
        }

      });
      return list;
    },
    // 日期选择控件切换的回调
    dateChange(event, item) {
      let modelName = "";
      if (event) {
        this.query[item.model] = event;
      } else {
        this.query[item.model] = null;
      }
      modelName = item.model;
      this.emitSearchChange(modelName);
    },
    // 日期范围选择控件切换的回调
    daterangeDateChange(event, item) {
      let modelName = "";
      if (event) {
        this.query[item.modelName[0]] = event[0];
        this.query[item.modelName[1]] = event[1];
      } else {
        this.query[item.modelName[0]] = this.query[item.modelName[1]] = null;
      }
      modelName = item.modelName[0];
      this.emitSearchChange(modelName);
    },
    //级联选择控件切换的回调
    cascaderChange(event, item) {
      this.query[item.model] = event[event.length - 1];
      this.emitSearchChange(item.model);
    },
    //抛出选择控件值切换事件
    emitSearchChange(modelName) {
      this.$forceUpdate();
      this.$emit("on-search-change", modelName);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";

.search {
  .search-bar {
    @include hor-between-end;
    padding-top: 14px;
  }

  .ctrl-btn {
    padding-bottom: 4px;
  }

  .search-box {
    @include hor-start-start;
    flex-flow: row;
    flex-wrap: wrap;
    padding-bottom: 4px;

    .form {
      display: flex;
      margin-right: 12px;
      vertical-align: top;
      height: 32px;
      margin-bottom: 6px;
      align-items: center;
    }

    .button {
      width: 70px;
    }

    .high-search-button{
      display: flex;
      align-items: center;
    }

    .high-search {
      @include hor-start-center;
      margin-left: 16px;
      cursor: pointer;
      img {
        @include rect(14px);
        height: 14px;
        margin-right: 2px;
      }

      span {
        @include font-normal;
        color: #a6abb5;
        padding: 0 4px;
      }
    }
  }
  .header-info{
    font-size: 12px;
    color:#949da5;
    margin-bottom: 10px;
  }
}
</style>
