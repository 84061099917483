<template>
    <div class="small-routine" @mouseenter="showCode = true" @mouseleave="showCode = false">
        <img :src="iconCode" class="code-img" alt="">
        <div class="code-box" v-show="showCode">
            <div class="left">
                <div class="img-box">
                    <img :src="student" alt="">
                </div>
                <span>扫码进入</span>
                <span>学生端小程序</span>
            </div>
            <el-divider direction="vertical" class="divider-code"></el-divider>
            <div class="right">
                <div class="img-box">
                    <img :src="teacher" alt="">
                </div>
                <span>扫码进入</span>
                <span>教师端小程序</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            iconCode: require("../../assets/icons/homeImg/icon-code.png"),
            teacher: require("../../assets/icons/homeImg/teacher.png"),
            student: require("../../assets/icons/homeImg/student.png"),
            showCode: false
        };
    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
.small-routine {
    position: relative;

    .code-img {
        width: 18px;
        height: 18px;
        position: relative;
        top: 2px;
    }

    .code-box {
        width: 289px;
        height: 202px;
        border-radius: 6px;
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
        position: absolute;
        left: -130px;
        bottom: -210px;
        z-index: 1000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
            width: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .right {
            width: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .img-box {
            width: 120px;
            height: 120px;
            margin-bottom: 10px;

            img {
                width: 120px;
                height: 120px;
            }
        }

        span {
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
            color: #606266;
        }
    }

    .code-box::after {
        content: " ";
        width: 0px;
        height: 0px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #ffffff;
        position: absolute;
        top: -16px;
        left: 138px;
    }
}

.divider-code {
  margin-top: 10px;
    width: 1px;
    height: 120px;
    color: #333333 !important;
}
</style>
