import request from '@/utils/request';

// 供应商结算统计列表
export function getSupplierSettleStatisList(query) {
  return request({
    url: "/provincesupplier/settle/list",
    method: "get",
    params: query
  });
}

// 获取供应商结算统计列表码洋实洋
export function getSupplierSettleListPrice(query) {
  return request({
    url: "/provincesupplier/settle/list/statis",
    method: "get",
    params: query
  });
}

// 供应商结算统计发票编辑
export function invoiceEdit(data) {
  return request({
    url: "/settle/supplierSettleStatis/invoiceEdit",
    method: "post",
    data: data
  });
}

// 新增结算-选择结算对象列表
export function getSettleObjectList(query) {
  return request({
    url: "/settle/supplierSettleStatis/settleObjectList",
    method: "get",
    params: query
  });
}

// 新增结算-选择结算对象列表码洋实洋
export function getSettleObjectListPrice(query) {
  return request({
    url: "/settle/supplierSettleStatis/settleObjectPrice",
    method: "get",
    params: query
  });
}

// 新增结算-选择收退单列表
export function getReceiptOrReturnList(data) {
  return request({
    url: "/settle/supplierSettleStatis/receiptOrReturnList",
    method: "post",
    data
  });
}

// 新增结算-获取选择收退单列表码洋实洋
export function getReceiptOrReturnPrice(data) {
  return request({
    url: "/settle/supplierSettleStatis/receiptOrReturnPrice",
    method: "post",
    data
  });
}

// 总部-供应商结算-发票批量上传
export function invoiceUpLoad(data) {
  return request({
    url: "/settle/supplierSettleStatis/invoiceUpLoad",
    method: "post",
    data: data
  });
}

// 供应商结算明细列表
export function getSettleDetailList(query) {
  return request({
    url: "/provincesupplier/settle/" + query.settleCode,
    method: "get"
  });
}

// 获取供应商结算明细列表码洋实洋
export function getSettleDetailListPrice(query) {
  return request({
    url: "/provincesupplier/settle/" + query.settleCode + '/statis',
    method: "get"
  });
}

// 供应商结算明细列表导出
export function exportSettleDetail(query) {
  return request({
    url: "/settle/settleDetail/export",
    method: "get",
    params: query
  });
}

// 未结算清单统计列表查询
export function getUnSettleStatisList(query) {
  return request({
    url: "/settle/supplierCheckDetail/unSettleStatisList",
    method: "get",
    params: query
  });
}

// 未结算清单明细列表查询
export function getUnSettleDetailList (query) {
  return request({
    url: "/settle/supplierCheckDetail/unSettleDetailList",
    method: "get",
    params: query
  });
}

// 根据收退单号查询结算明细列表
export function getSettleReceiptListBySettleCode(query) {
  return request({
    url: "/provincesupplier/settle/" + query.settleCode,
    method: "get"
  });
}
 
// 获取结算单列表
export function getSubmitSettleReceiptList(query) {
  return request({
    url: "/settle/supplierSettleStatis/submitSettleReceiptList",
    method: "get",
    params: query
  })
}

// 提交结算单
export function submitSettleReceipt(data) {
  return request({
    url: "/settle/supplierSettleStatis/submitSettleReceipt",
    method: "post",
    data: data
  })
}

// 删除结算单
export function removeSupplierSettleStatis(data) {
  return request({
    url: "/settle/supplierSettleStatis/remove",
    method: "delete",
    data: data
  })
}

// 保存结算单
export function saveSettleReceipt(data) {
  return request({
    url: "/settle/supplierSettleStatis/saveSettleReceipt",
    method: "post",
    data: data
  })
}

// 编辑结算单
export function editSettleReceipt(data) {
  return request({
    url: "/settle/supplierSettleStatis/editSettleReceipt",
    method: "post",
    data: data
  })
}

// 未结算清单统计列表导出
export function exportUnSettleStatisList(query) {
  return request({
    url: "/settle/supplierCheckDetail/unSettleStatisListExport",
    method: "get",
    params: query
  })
}

// 未结算清单明细列表导出
export function exportUnSettleDetailList(query) {
  return request({
    url: "/settle/supplierCheckDetail/unSettleDetailListExport",
    method: "get",
    params: query
  })
}

// 获取未结算清单统计列表码洋实洋
export function getUnSettleStatisListPrice(query) {
  return request({
    url: "/settle/supplierCheckDetail/unSettleStatisListPrice",
    method: "get",
    params: query
  })
}

// 获取未结算清单明细列表码洋实洋
export function getUnSettleDetailListPrice(query) {
  return request({
    url: "/settle/supplierCheckDetail/unSettleDetailListPrice",
    method: "get",
    params: query
  })
}

// 根据结算单号查询所有供应商ID
export function getSupplierIdsBySettleCode(query) {
  return request({
    url: "/settle/settleDetail/getSupplierIdsBySettleCode",
    method: "get",
    params: query
  })
}


// 供应商结算统计列表导出
export function exportSupplierSettleStatis(query) {
  return request({
    url: "/settle/supplierSettleStatis/export",
    method: "get",
    params: query
  })
}


// 发票模板导出
export function exportInvoiceTempDown(query) {
  return request({
    url: "/settle/supplierSettleStatis/exportInvoiceTemplate",
    method: "get",
    params: query
  })
}

// 结算总表导出
export function exportSettleSummary(query) {
  return request({
    url: "settle/supplierSettleStatis/settleSummaryExport",
    method: "get",
    params: query
  })
}

// 结算单导出
export function exportSettleInfo(query) {
  return request({
    url: "settle/supplierSettleStatis/settleInfoExport",
    method: "get",
    params: query
  })
}

// 数据导出
export function settleDetailPayExport (query) {
  return request({
    url: '/settle/settleDetail/payExport',
    method: 'get',
    params: query
  })
}

// 总部供应商未结算-生成结算批次列表
export function getSupplierSettleBatchObjectList (query) {
  return request({
    url: "/settle/supplierSettleBatch/settleObjectList",
    method: "get",
    params: query
  });
}

// 总部供应商未结算-生成结算批次统计
export function getSupplierSettleBatchObjectPrice (query) {
  return request({
    url: "/settle/supplierSettleBatch/settleObjectPrice",
    method: "get",
    params: query
  });
}

// 总部供应商未结算-生成结算批次统计
export function addOrEditSettleBatch (data) {
  return request({
    url: "/settle/supplierSettleBatch/addOrEdit",
    method: "post",
    data
  });
}

// 总部结算批次列表
export function getSupplierSettleBatchList (query) {
  return request({
    url: "/settle/supplierSettleBatch/list",
    method: "get",
    params: query
  });
}

// 总部结算批次统计
export function getSupplierSettleBatchListPrice (query) {
  return request({
    url: "/settle/supplierSettleBatch/listPrice",
    method: "get",
    params: query
  });
}

// 总部结算批次发票编辑
export function settleBatchInvoiceEdit (data) {
  return request({
    url: "/settle/supplierSettleBatch/invoiceEdit",
    method: "post",
    data: data
  });
}

// 总部结算批次发票模板导出
export function settleBatchExportInvoiceTemplate (query) {
  return request({
    url: "/settle/supplierSettleBatch/exportInvoiceTemplate",
    method: "get",
    params: query
  });
}

// 总部结算批次导出
export function settleBatchExport (query) {
  return request({
    url: "/settle/supplierSettleBatch/export",
    method: "get",
    params: query
  });
}

// 总部结算批次提交
export function submitSettleBatch (data) {
  return request({
    url: "/settle/supplierSettleBatch/submitSettleBatch",
    method: "post",
    data
  });
}

// 总部结算批次提交
export function removeSettleBatch (data) {
  return request({
    url: "/settle/supplierSettleBatch/remove",
    method: "delete",
    data
  });
}

// 总部供应商未结算-按供应商统计-列表
export function getUnSettleSupplierList(data) {
  return request({
    url: "/provincesupplier/unsettle/supplier",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-按供应商统计-统计数据
export function getUnSettleSupplierStatis (data) {
  return request({
    url: "/provincesupplier/unsettle/supplier/statis",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-按供应商统计-导出
export function getUnSettleSupplierExport (data) {
  return request({
    url: "/provincesupplier/unsettle/supplier/export",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-按供应商统计-列表
export function getUnSettleStorehousecodeList (data) {
  return request({
    url: "/provincesupplier/unsettle/storehousecode",
    method: "post",
    data
  });
}

// 总部供应商未结算-按供应商统计-统计数据
export function getUnSettleStorehousecodeStatis (data) {
  return request({
    url: "/provincesupplier/unsettle/storehousecode/statis",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-按供应商统计-导出
export function getUnSettleStorehousecodeExport (data) {
  return request({
    url: "/provincesupplier/unsettle/storehousecode/export",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-明细-列表
export function getUnSettleDetail (data) {
  return request({
    url: "/provincesupplier/unsettle/detail",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-明细-统计
export function getUnSettleDetailStatis (data) {
  return request({
    url: "/provincesupplier/unsettle/detail/statis",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 总部供应商未结算-明细-导出
export function getUnSettleDetailExport (data) {
  return request({
    url: "/provincesupplier/unsettle/detail/export",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第一个步骤（按供应商统计）- 列表
export function getProvincesupplierSettlePreList (data) {
  return request({
    url: "/provincesupplier/settle/pre/supplier",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第一个步骤（按供应商统计）- 统计
export function getProvincesupplierSettlePreStatis (data) {
  return request({
    url: "/provincesupplier/settle/pre/supplier/statis",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第二个步骤（按供应商、产品统计）- 列表
export function getProvincesupplierProductPreList (data) {
  return request({
    url: "/provincesupplier/settle/pre/product",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第二个步骤（按供应商、产品统计）- 统计
export function getProvincesupplierProductPreStatis (data) {
  return request({
    url: "/provincesupplier/settle/pre/product/statis",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第三个步骤（详细列表）- 列表
export function getProvincesupplierUnsettleDetail (data) {
  return request({
    url: "/provincesupplier/unsettle/detail",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第三个步骤（详细列表）- 统计
export function getProvincesupplierUnsettleDetailStatis (data) {
  return request({
    url: "/provincesupplier/unsettle/detail/statis",
    method: "post",
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    },
    data
  });
}

// 第四个步骤 - 保存/编辑
export function addSettleOrder (data) {
  return request({
    url: "/provincesupplier/settle/add",
    method: "post",
    data
  });
}

// 获取是否存在存在未处理的付退单
export function beforeUncheckList (query) {
  return request({
    url: "/provincesupplier/unsettle/before",
    method: "get",
    params: query
  });
} 

// 删除结算单
export function deleteSettle  (query) {
  return request({
    url: "/provincesupplier/settle/" + query,
    method: "delete"
  });
}