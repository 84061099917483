import request from '@/utils/request'

export function queryProvinceAmountList(params) {
  return request({
    url: '/priceStatement/stics/queryProvinceAmountList',
    method: 'get',
    params
  })
}
export function queryProvinceAmountSum(params) {
  return request({
    url: '/priceStatement/stics/queryProvinceAmountSum',
    method: 'get',
    params
  })
}



//  [子分公司PC非免费] 码洋统计表
export function selectProvinceAmountList(params) {
  return request({
    url: '/priceStatement/stics/selectProvinceAmountList',
    method: 'get',
    params
  })
}
export function selectProvinceAmountSum(params) {
    return request({
      url: '/priceStatement/stics/selectProvinceAmountSum',
      method: 'get',
      params
    })
  }
  export function provincePcAppNotFreeExport(params) {
    return request({
      url: '/priceStatement/stics/provincePcAppNotFreeExport',
      method: 'get',
      params
    })
  }
  //省 [子分公司免费] 码洋统计表
  export function provinceCompanyFreeList(params) {
    return request({
      url: '/priceStatement/stics/provinceCompanyFreeList',
      method: 'get',
      params
    })
  }
  export function provinceCompanyFreeSum(params) {
    return request({
      url: '/priceStatement/stics/provinceCompanyFreeSum',
      method: 'get',
      params
    })
  }
  export function provinceCompanyFreeExport(params) {
    return request({
      url: '/priceStatement/stics/provinceCompanyFreeExport',
      method: 'get',
      params
    })
  }
  //省 [子分公司免费-非免费] 码洋统计表      //省 [子分公司PC] 码洋统计表
  export function provinceCompanyList(params) {
    return request({
      url: '/priceStatement/stics/provinceCompanyList',
      method: 'get',
      params
    })
  }

  export function provinceFreeNotFreeExport(params) {
    return request({
      url: '/priceStatement/stics/provinceFreeNotFreeExport',
      method: 'get',
      params
    })
  }
export function provinceFreeNotFreeNotExport(params) {
  return request({
    url: '/priceStatement/stics/provinceFreeNotFreeNotExport',
    method: 'get',
    params
  })
}
  export function provincePcAppExport(params) {
    return request({
      url: '/priceStatement/stics/provincePcAppExport',
      method: 'get',
      params
    })
  }

  export function provinceCompanyListSum(params) {
    return request({
      url: '/priceStatement/stics/provinceCompanyListSum',
      method: 'get',
      params
    })
  }
//.省 [子分公司分类] 码洋统计表
export function provinceCompanyCatgoryExport(params) {
    return request({
      url: '/priceStatement/amount/provinceCompanyCatgoryExport',
      method: 'get',
      params
    })
  }
  export function provinceCompanyCatgorySum(params) {
    return request({
      url: '/priceStatement/amount/provinceCompanyCatgorySum',
      method: 'get',
      params
    })
  }

  export function provinceCompanyCatgoryList(params) {
    return request({
      url: '/priceStatement/amount/provinceCompanyCatgoryList',
      method: 'get',
      params
    })
  }

//   8.省 [子分公司版别] 码洋统计表

export function selectProvincePressAmountList(params) {
  return request({
    url: '/priceStatement/press/selectProvincePressAmountList',
    method: 'get',
    params
  })
}

export function selectProvincePressAmountSum(params) {
  return request({
    url: '/priceStatement/press/selectProvincePressAmountSum',
    method: 'get',
    params
  })
}

export function provincePressExport(params) {
  return request({
    url: '/priceStatement/press/provincePressExport',
    method: 'get',
    params
  })
}

export function provinceCompanyCatgorySums(params) {
  return request({
    url: '/priceStatement/amount/provinceCompanyCatgorySum',
    method: 'get',
    params
  })
}
//省 [地区PC非免费] 码洋统计表
export function selectProvinceAreaNotFreeList(params) {
  return request({
    url: '/priceStatement/stics/selectProvinceAreaNotFreeList',
    method: 'get',
    params
  })
}
export function selectProvinceAreaNotFreeListSum(params) {
  return request({
    url: '/priceStatement/stics/selectProvinceAreaNotFreeListSum',
    method: 'get',
    params
  })
}

export function provinceAreaNotFreeListExport(params) {
  return request({
    url: '/priceStatement/stics/provinceAreaNotFreeListExport',
    method: 'get',
    params
  })
}
export function selectAreaName(params) {
  return request({
    url: '/company/company/selectAreaName ',
    method: 'get',
    params
  })
}
//省 [地区免费] 码洋统计表
export function selectProvinceAreaFreeList(params) {
  return request({
    url: '/priceStatement/stics/selectProvinceAreaFreeList',
    method: 'get',
    params
  })
}
export function provinceAreaFreeExport(params) {
  return request({
    url: '/priceStatement/stics/provinceAreaFreeExport',
    method: 'get',
    params
  })
}

export function selectProvinceAreaFreeListSum(params) {
  return request({
    url: '/priceStatement/stics/selectProvinceAreaFreeListSum',
    method: 'get',
    params
  })
}

// 12.省 [地区免费-非免费] 码洋统计表  //省 [地区PC] 码洋统计表
export function provinceAreaList(params) {
  return request({
    url: '/priceStatement/stics/provinceAreaList',
    method: 'get',
    params
  })
}
export function provinceAreaFreeNotFreeExport(params) {
  return request({
    url: '/priceStatement/stics/provinceAreaFreeNotFreeExport',
    method: 'get',
    params
  })
}

export function provinceAreaListSum(params) {
  return request({
    url: '/priceStatement/stics/provinceAreaListSum',
    method: 'get',
    params
  })
}
//省 [地区PC] 码洋统计表
export function provinceAreaPcAppExport(params) {
  return request({
    url: '/priceStatement/stics/provinceAreaPcAppExport',
    method: 'get',
    params
  })
}




// 省 [地区分类] 码洋统计表
export function provinceAreaCatgoryList(params) {
  return request({
    url: '/priceStatement/amount/provinceAreaCatgoryList',
    method: 'get',
    params
  })
}
export function provinceAreaCatgoryExport(params) {
  return request({
    url: '/priceStatement/amount/provinceAreaCatgoryExport',
    method: 'get',
    params
  })
}

export function provinceAreaCatgoryListSum(params) {
  return request({
    url: '/priceStatement/amount/provinceAreaCatgoryListSum',
    method: 'get',
    params
  })
}

// 省 [地区版别] 码洋统计表
export function selectProvinceAreaPressList(params) {
  return request({
    url: '/priceStatement/press/selectProvinceAreaPressList',
    method: 'get',
    params
  })
}
export function provinceAreaPressExport(params) {
  return request({
    url: '/priceStatement/press/provinceAreaPressExport',
    method: 'get',
    params
  })
}

export function selectProvinceAreaPressSum(params) {
  return request({
    url: '/priceStatement/press/selectProvinceAreaPressSum',
    method: 'get',
    params
  })
}

// 省 [板块业务] 码洋统计表
export function selectProvincePlateList(params) {
  return request({
    url: '/priceStatement/amount/selectProvincePlateList',
    method: 'get',
    params
  })
}
export function provincePlateExport(params) {
  return request({
    url: '/priceStatement/amount/provincePlateExport',
    method: 'get',
    params
  })
}

export function selectProvincePlateSum(params) {
  return request({
    url: '/priceStatement/amount/selectProvincePlateSum',
    method: 'get',
    params
  })
}
// 省 [年级] 码洋统计表
export function selectProvinceGradeAmountList(params) {
  return request({
    url: '/priceStatement/grade/selectProvinceGradeAmountList',
    method: 'get',
    params
  })
}
export function provinceGradeExport(params) {
  return request({
    url: '/priceStatement/grade/provinceGradeExport',
    method: 'get',
    params
  })
}

export function selectProvinceGradeAmountSum(params) {
  return request({
    url: '/priceStatement/grade/selectProvinceGradeAmountSum',
    method: 'get',
    params
  })
}





