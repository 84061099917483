import request from '@/utils/request'

export function queryValidCompany(query) {
  return request({
    url: '/SD/staff/controlCompany/queryValidCompany',
    method: 'get',
    params: query
  })
}

export function queryValidValidCompany(query) {
  return request({
    url: '/SD/staff/controlCompany/queryValidValidCompany',
    method: 'get',
    params: query
  })
}

export function queryValidValidCompanyByUser() {
  return request({
    url: '/SD/staff/controlCompany/queryValidValidCompanyByUser',
    method: 'get',
  })
}

export function addStaffControlCompany(data) {
  return request({
    url: '/SD/staff/controlCompany/addStaffControlCompany',
    method: 'post',
    data: data
  })
}

export function delStaffControlCompany(data) {
  return request({
    url: '/SD/staff/controlCompany/delStaffControlCompany',
    method: 'post',
    data: data
  })
}

export function updateUserCompany(query) {
  return request({
    url: '/SD/staff/controlCompany/updateUserCompany',
    method: 'get',
    params: query
  })
}

export function getUserCompanyCount() {
  return request({
    url: '/SD/staff/controlCompany/getUserCompanyCount',
    method: 'get',
  })
}
