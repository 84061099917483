import {
  constantRoutes
} from '@/router';
import {
  getRouters
} from '@/api/menu';
import Layout from '@/layout/index';
import Router from '@/layout/components/router'
import { getUserSetting } from "@/api/system/user";
import cache from "@/utils/cache";

const permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({
      commit
    }) {
      return new Promise(resolve => {
        getUserSetting().then(res => {
          localStorage.setItem("year", res.data.year);
          localStorage.setItem("quarterId", res.data.quarterId);
          let quarterName = res.data.quarterId == "0" ? "春季" : "秋季";
          localStorage.setItem("quarterName", quarterName);
          //设置全局业务数据
          let businessInfo = {
            year: res.data.year,
            quarterId: res.data.quarterId,
            quarterName: quarterName,
            isProvince: cache.local.get("isProvince")
          };
          cache.local.setJSON("businessInfo", businessInfo);
        }).finally(()=>{
          // 向后端请求路由数据
          getRouters().then(res => {

            const accessedRoutes = filterAsyncRouter(res.data)
            accessedRoutes.push({
              path: '*',
              redirect: '/404',
              hidden: true
            })
            commit('SET_ROUTES', accessedRoutes)
            resolve(accessedRoutes)
          })
        });
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, routerView) {
  return asyncRouterMap.filter(route => {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        if(routerView){
          route.component = Router;
        } else{
          route.component = Layout;
        }
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, true)
    }
    return true
  })
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
