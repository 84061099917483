const allDictData = {
  state: {
    dictData: []
  },
  mutations: {
    SET_DICT_DATA: (state, dictData) => {
      state.dictData = dictData
    }
  },
  actions: {
    setDictData({ commit }, dictData) {
      commit('SET_DICT_DATA', dictData)
    }
  }
}

export default allDictData

