
const left = [
  '操作', '年级', '产品名称', '版别', '图书类型', '分类', '二级分类', '分类名称', '展示名称', '供应商',
  '供应商简称', '供应商全称', '地址', '发布标题', '公司简称', '学校简称', '学校全称', '活动名称', '公司全称',
  '活动编号', '活动产品池编号', '活动产品池标题', '活动备注', '备注', '一级客户', '一级客户名称', '仓库', '客户',
  '角色名称', '用户所在单位全称', '用户所在部门', '服务商(子分公司)', '出版社全称', '课程标题', '课程名称', '评语', 
  '任务名称', '任务描述', '活动标题', '关联收费方案', '课程标题', '专题名称', '专题封面', '类型', '对象名称', '产品发布标题', 
];

const right = ['估价', '进货折扣', '通发折扣', '折扣', '税率', '订数', '退款', '数量', '退款金额', '退款描述', '码洋', '实洋',
  '征订码洋', '品种数', '报订数', '其中备货数', '提印数', '本季收订数', '上年收订数', '与上年差', '本季收订数', '上季收订数', '比例',
  '与上季差', '完成次数', '报名人数', '建档立卡折扣', '建档立卡售价', '旧存', '品种', '结算码洋', '结算实洋', '实发实洋', '未税成本', '应收余额', '处理金额', '处理后余额',
  '实收实洋', '收货实洋', '退货实洋', '实收码洋', '应付余额', '收货码洋', '退货码洋', '退货码洋', '退货实洋', '借方金额', '贷方金额', '结算折扣(%)', '税率(%)', 
  '结算单价', '定价', '退货数', '进货折扣(%)', '免费收订数', '免费码洋', '非免收订数', '非免码洋', '总收订数', '免费发货数', '免费发货码洋', '非免发货数',
  '非免发货码洋', '总发货数', '总发货码洋', '总收订码洋', '总收订实洋', '发货数', '发货码洋', '折扣(%)', '本次回数', '实发码洋', '发货实洋', '客户退货码洋', '客户退货实洋',
  '付款金额', '结算额', '已付金额', '付款金额', '免费代码发货数', '非免代码发货数', '免费确认数', '应转非免数', '未结算实洋', '未结算码洋'
];

export function isLeft(name) {
  return left.includes(name);
}

export function isRight(name) {
  return right.includes(name);
}

