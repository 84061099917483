import request from '@/utils/request';

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  };
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
}

// 获取用户详细信息
export async function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  });
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  });
}

// 小程序h5页面，需求【ZD20240416-2】拓展活动优化3：学生端证书查询与证书扫码校验前端页面
export function getActivityEnrollCert(url) {
  return request({
    url: url,
    method: 'get'
  });
}

export function enrollCertCheck(query) {
  return request({
    url: '/miniapp/activity/enrollCert/check',
    method: 'get',
    params: query
  })
}
