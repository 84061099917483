<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar wrap-class="scrollbar-wrapper" slot="reference" class="scrollbar-menu">
      <el-menu
        :default-active="activeMenu"
        :background="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
      >
        <sidebar-item
          v-for="(route, index) in permission_routes"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
          :class="tagsIndex === index ? 'action-sidebar-item' : ''"
          :actionIndex="index"
          :isCollapse="isCollapse"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem.vue";
import variables from "@/assets/styles/variables.scss";

export default {
  components: { Logo, SidebarItem },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["permission_routes", "sidebar"]),
    tagsIndex() {
      const route = this.$route;
      const { meta } = route;
      if (meta.tagsIndex) {
        return meta.tagsIndex;
      }
      return null;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  methods: {},
};
</script>

<style lang="less">
.scrollbar-menu{
  margin-top: 50px;
}
.action-sidebar-item{
  .el-menu-item {
    color: #EC5151 !important;
  }
}
</style>


