import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import '@/assets/styles/theme/index.css'
import Element from 'element-ui'
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/link.scss' // link css
import '@/assets/styles/font.css' // link css
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
import day from "dayjs";
import utils from "@/utils/utils"
import './assets/icons' // icon
import './permission' // permission control
import './global'

import {
  getDicts
} from "@/api/system/dict/data";
import {
  getConfigKey
} from "@/api/system/config";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  download,
  downloadErrorExcel,
  templateDownload,
  handleTree,
  downloadhistory,
  selectDictLabelObject,
  downloadByATag
} from "@/utils/link";
import LwTable from "@/components/LwTable/index.vue";
import LwUmyTable from "@/components/LwUmyTable/index.vue";
import LwAmount from "@/components/LwAmount/index.vue";
import LwNumber from "@/components/LwNumber/index.vue";
import LwHeaderCard from "@/components/LwHeaderCard/index.vue";
import Pagination from "@/components/Pagination";
import ComponentTable from "@/components/Table/table.vue";
import dictComboBox from "@/components/dict/dictComboBox";
import subCategoryComboBox from "@/components/product/category/subCategoryComboBox";
import Search from "@/components/Search/search.vue";

import {
  checkPer,
  CenterVerify
} from "@/utils/jurisdiction"

import './views/flow/common/directives'
import {
  fileInfo
} from '@/api/upload/uploadTool.js'
import vueSeamlessScroll from 'vue-seamless-scroll' //数据轮播
import api from './router/api';
import './assets/icons/flow/iconfont.css'
import {
  convertDicData,
  bookTypeFilterByUser,
  bookTypeFormatter,
  categoryFilterByUser,
  customerFilterByUser,
  rolesCheck
} from "@/utils/index"
import { settleMath } from '@/utils/settleMath.js'
import tips from '@/utils/tips.js'
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabelObject = selectDictLabelObject
Vue.prototype.download = download
Vue.prototype.$downloadByATag = downloadByATag
Vue.prototype.templateDownload = templateDownload
Vue.prototype.downloadErrorExcel = downloadErrorExcel
Vue.prototype.handleTree = handleTree
Vue.prototype.downloadhistory = downloadhistory
Vue.prototype.$fileInfo = fileInfo
Vue.prototype.$bus = new Vue()
Vue.prototype.CenterVerify = CenterVerify
Vue.prototype.vueSeamlessScroll = vueSeamlessScroll
Vue.prototype.checkPer = checkPer
Vue.prototype.bookTypeFormatter = bookTypeFormatter
Vue.prototype.bookTypeFilterByUser = bookTypeFilterByUser
Vue.prototype.convertDicData = convertDicData
Vue.prototype.$categoryFilterByUser = categoryFilterByUser
Vue.prototype.$customerFilterByUser = customerFilterByUser
Vue.prototype.$api = api
Vue.prototype.$dayjs = day;
Vue.prototype.$rolesCheck = rolesCheck;
Vue.prototype.$utils = utils;
Vue.prototype.$settleMath = settleMath;
Vue.prototype.$tips = tips;
Vue.prototype.msgSuccess = function(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
}

Vue.prototype.msgError = function(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
}

Vue.prototype.msgInfo = function(msg) {
  this.$message.info(msg);
}

Vue.prototype.msgInfoNotClose = function(msg) {
  this.$message({
    showClose: true,
    message: msg,
    duration: 5000
  });
}

//判断当前用户是否只有一个代发点角色
Vue.prototype.$onlyCompanyProxy = function() {
   if(store.getters && store.getters.onlyCompanyProxy){
     return true;
   }else{
     return false;
   }
}

//过滤代发人员的一级客户列表
Vue.prototype.$filterCompanyProxyOneCustomerList = function(customerList) {
  let oneCustomerList=[];
  let oneCustomerIdsStr=store.getters&&store.getters.userInfo&&store.getters.userInfo.oneCustomerIds;
  if(oneCustomerIdsStr){
    let oneCustomerIds=oneCustomerIdsStr.split(',');
    for(let i in oneCustomerIds){
      for(let j in customerList){
        if(oneCustomerIds[i]==customerList[j].id+''){
          oneCustomerList.push(customerList[j]);
        }
      }
    }
  }
  return oneCustomerList;
}

Vue.mixin({
  computed: {
    userInfoMixin() {
      return JSON.parse(localStorage.getItem("userInfo"));
    }
  }
})

// 全局组件挂载
Vue.use(UmyUi);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
});
Vue.component('LwTable', LwTable)
Vue.component('LwUmyTable', LwUmyTable)
Vue.component('LwAmount', LwAmount)
Vue.component('LwNumber', LwNumber)
Vue.component('LwHeaderCard', LwHeaderCard)
Vue.component('Pagination', Pagination)
Vue.component('Table', ComponentTable)
Vue.component('Search', Search)
Vue.component('dictComboBox', dictComboBox)
Vue.component('subCategoryComboBox', subCategoryComboBox)
Vue.use(vueSeamlessScroll)
Vue.use(permission)

//directive：注册一个全局的指令 v-preventReClick
const preventReClick = Vue.directive('preventReClick', {
  //inserted：当被绑定的元素插入进DOM时发生的调用
  inserted: function(el, binding) {
    //el:指令绑定的元素。用来操作DOM  binding：一个对象
    el.addEventListener('click', () => {
      //addEventListener() 方法用于向指定元素添加事件
      if (!el.disabled) {
        //判断当前元素如果不是禁用的状态
        el.disabled = true //开启按钮禁用
        setTimeout(() => { //加定时器 3秒后把定时器关闭
          el.disabled = false
        }, binding.value || 3000)
        //binding.value可以自行设置。如果设置了则跟着设置的时间走
        //例：v-preventReClick='2000'
      }
    })
  }
});

//系统错误捕获
const errorHandler = (error, vm) => {
  console.error('抛出全局异常');
  console.error(error);

}

Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error) => errorHandler(error, this);
Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
