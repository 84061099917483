import store from '@/store'
export function checkPer(data) {
    const permissions = store.getters && store.getters.permissions


    if (typeof data === 'object') {
        var count = 0;
        data.push('*:*:*')
        data.forEach(element => {

            count += permissions.includes(element) ? 1 : 0

        });
        return count
    }
    //
    return permissions.includes(data)
}
const formCenter = function (center, status) {
    let arr = [9, 10, 12, 13]
    let type;

        if (center) {
            type = arr.includes(center.length)
        } else {
            type = false
    }

    return type
}

export const CenterVerify = (rule, value, callback) => {
    if (formCenter(value)) {
        callback();
    } else {
        callback(new Error("长度为9、10、12、13位数字"));
    }
};
