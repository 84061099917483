<template>
  <div @click="clickRouter(item)" v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="isCollapse ? 'submenu-title-noDropdown2' : 'submenu-title-noDropdown'"
        >
          <item
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="isCollapse ? '' : onlyOneChild.meta.title"
          />
        </el-menu-item>
      </app-link>
    </template>
    <el-popover
      v-else
      popper-class="menu-popover"
      placement="right"
      :visible-arrow="false"
      trigger="click"
      v-model="popMenuVisible"
      :width="popWidth"
      @after-enter="show"
      @hide="hide"
    >
      <div ref="popoverMenu" class="menu-popover-content">
        <menu-card
          v-for="(child, index) in item.children"
          :key="child.path + index"
          :item="child"
          :base-path="resolvePath(child.path)"
          @menuClose="menuClose"
          @menuClick="show"
          :actionIndex="actionIndex"
        />
      </div>
      <el-menu-item
        slot="reference"
        ref="subMenu"
        :index="resolvePath(item.path)"
        :class="isCollapse ? 'submenu-title-noDropdown2' : 'submenu-title-noDropdown'"
      >
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="isCollapse ? '' : item.meta.title"
        />
      </el-menu-item>
    </el-popover>
  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
import menuCard from "./menuCard.vue";

export default {
  name: "SidebarItem",
  components: { Item, AppLink, menuCard },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: "",
    },
    actionIndex: {
      required: true,
    },
    isCollapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    this.onlyOneChild = null;
    return {
      popMenuVisible: false,
      rowNum: 1,
    };
  },
  computed: {
    popWidth() {
      return 180 * this.rowNum + 16;
    },
  },
  methods: {
    clickRouter(e, a) {},
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          //如果只有一个显示的子项，将使用
          this.onlyOneChild = item;
          this.onlyOneChild.meta.tagsIndex = this.actionIndex;
          return true;
        }
      });
      // 当只有一个子路由器时，默认情况下会显示子路由器
      if (showingChildren.length === 1) {
        return true;
      }
      // 如果没有要显示的子路由器，则显示父路由器
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      //判断是否为外链
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    menuClose() {
      this.popMenuVisible = false;
    },
    show() {
      let excessiveHeight = 0;//每列冗余高度
      let columnHeight = this.$refs.popoverMenu.offsetHeight;//列高度
      let menuHeight = 0;//子菜单高度
      let countHeight = 0;//用于计算每列冗余高度
      this.$refs.popoverMenu.childNodes.forEach((element) => {
        menuHeight += element.offsetHeight;
        countHeight += element.offsetHeight;
        if(countHeight > columnHeight){
          excessiveHeight += columnHeight - (countHeight -  element.offsetHeight);
          countHeight = element.offsetHeight;
        }
      });
      this.rowNum = Math.ceil((menuHeight + excessiveHeight) / columnHeight);
      // let columnWidth = 0;
      // let list = JSON.stringify(element.innerText).split('n');
      //   list.forEach((el,index) => {
      //     if(index == 0){
      //       if(el.length - 2 > columnWidth){
      //         columnWidth = el.length - 2;
      //       }
      //     }else{
      //       if(el.length > columnWidth){
      //         columnWidth = el.length;
      //       }
      //     }
      //   });
      // console.log('menuMaxLength', columnWidth - 1);
    },
    hide(){
      this.$refs.subMenu.$el.classList.remove('is-active');
      this.$refs.subMenu.$el.style.color = '#303133';
    }
  },
};
</script>

<style lang="less">
.nest-menu-children {
  .nest-menu {
    span {
      margin-left: 20px;
    }
  }
}
.el-menu {
  border-right: 0 !important;
}
.menu-popover {
  top: 50px !important;
  margin-left: 0px !important;
  &-content {
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.el-menu-item:hover {
  color: #ec5151 !important;
  background-color: #feefef;
}
.el-menu-item:focus {
  background-color: #ffffff;
}
.el-submenu__title:hover {
  color: #ec5151 !important;
  background-color: #ffffff;
}
.el-submenu__title:focus {
  background-color: #ffffff;
}
</style>
