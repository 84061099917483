import request from '@/utils/request'

// 查询公司下拉框选择
export function getCompanysByLevel(isControl) {
  return request({
    url: '/company/company/getCompanysByLevel/'+isControl,
    method: 'get'
  })
}
// 查询公司信息列表
export function listCompany(query) {
  return request({
    url: '/company/company/list',
    method: 'get',
    params: query
  })
}
// 查询公司信息列表2
export function queryCompanyList(query) {
  return request({
    url: '/company/company/queryList',
    method: 'get',
    params: query
  })
}


// 查询公司信息详细
export function getCompany(companyId) {
  return request({
    url: '/company/company/' + companyId,
    method: 'get'
  })
}

// 新增公司信息
export function addCompany(data) {
  return request({
    url: '/company/company',
    method: 'post',
    data: data
  })
}

// 修改公司信息
export function updateCompany(data) {
  return request({
    url: '/company/company',
    method: 'put',
    data: data
  })
}

// 删除公司信息
export function delCompany(companyId) {
  return request({
    url: '/company/company/' + companyId,
    method: 'delete'
  })
}

// 导出公司信息
export function exportCompany(query) {
  return request({
    url: '/company/company/export',
    method: 'get',
    params: query
  })
}

//批量禁用
export function batchStopCompanyStatus(companyId) {
  return request({
    url: '/company/company/stopStatus/' + companyId,
    method: 'delete'
  })
}

//批量启用
export function batchStartCompanyStatus(companyId) {
  return request({
    url: '/company/company/startStatus/' + companyId,
    method: 'delete'
  })
}
//编辑是否厂发
export function isPlantShipments(companyId,factorySend) {
  return request({
    url: '/company/company/isPlantShipments/'+companyId +'/'+ factorySend,
    method: 'put',

  })
}

//根据地区id查询公司列表
export function queryCompanyListByAreaId(query) {
  return request({
    url: '/company/company/attribution',
    method: 'get',
    params:query,
  })
}


//    <!--查询地区名，去重地区名查询  用在省地区征订报表的地区下拉框-->
export function selectAreaName(query) {
  return request({
    url: '/company/company/selectAreaName',
    method: 'get',
    params:query,
  })
}

// 修改公司信息
export function updateCompanyConfig(data) {
  return request({
    url: '/company/company/config',
    method: 'put',
    data: data
  })
}

// 新增公司其他客商信息
export function addCompanyMerchant(data) {
  return request({
    url: '/company/company/otherMerchant/add',
    method: 'post',
    data: data
  })
}
// 删除公司其他客商信息
export function delCompanyMerchant(data) {
  return request({
    url: '/company/company/otherMerchant/del/'+data,
    method: 'delete',
  })
}
// 删除公司其他客商信息
export function delCompanyMerchantByIds(data) {
  return request({
    url: '/company/company/otherMerchant/list/del/'+data,
    method: 'delete',
  })
}
// 修改公司其他客商信息
export function updateCompanyMerchant(data) {
  return request({
    url: '/company/company/otherMerchant/update',
    method: 'post',
    data: data
  })
}
// 查询公司其他客商信息列表
export function getCompanyMerchantList(query) {
  return request({
    url: '/company/company/otherMerchant/list',
    method: 'get',
    params: query
  })
}

//批量修改子公司区域标签
export function updateAreaTagsByIds(data) {
  return request({
    url: '/company/company/areaTags',
    method: 'post',
    data: data
  })
}

//批量新增关闭的公司
export function companyCloseListAdd(data) {
  return request({
    url: '/company/company/close/listAdd',
    method: 'post',
    data: data
  })
}

//批量修改删除关闭的公司
export function companyCloseDelChange(data) {
  return request({
    url: '/company/company/close/delChange',
    method: 'post',
    data: data
  })
}


//关闭的公司列表
export function companyCloseList(query) {
  return request({
    url: '/company/company/close/list',
    method: 'get',
    params: query
  })
}

export function getAreaCompanyList(query) {
  return request({
    url: '/company/company/areaCompany/list',
    method: 'get',
    params: query
  })
}
