import Cookies from 'js-cookie'

const state = {
  inventoryManageList: new Array(), //盘存管理
  seasonList: new Array(), //转季管理
  transferList: new Array(), //移库管理
  scrapInfoList: new Array(), //报损异管理
  retailInfoList: new Array(), //零售管理
  giftsInfoList: new Array(), //领用赠送管理
  ticketInfoList: new Array(), //下票单管理

}

const mutations = {
  SET_INVENTORY_MANAGE_LIST(state, inventoryManageList) {
    state.inventoryManageList = inventoryManageList;
  },
  SET_TICKET_INFO_LIST(state, ticketInfoList) {
    state.ticketInfoList = ticketInfoList;
  },
  SET_SEASON_LIST(state, seasonList) {
    state.seasonList = seasonList;
  },
  SET_GIFTS_LIST(state, giftsInfoList) {
    state.giftsInfoList = giftsInfoList;
  },
  SET_TRANSFER_LIST(state, transferList) {
    state.transferList = transferList;
  },
  SET_SCRAP_LIST(state, scrapInfoList) {
    state.scrapInfoList = scrapInfoList;
  },
  SET_RETAIL_INFO_LIST(state, retailInfoList) {
    state.retailInfoList = retailInfoList;
  },
  SET_UN_RECEPT_LIST(state, unReceptList) {
    state.unReceptList = unReceptList;
  },
}

const actions = {
  setInventoryList({
    commit,
    state
  }, inventoryManageList) {
    commit('SET_INVENTORY_MANAGE_LIST', inventoryManageList);
  },
  setTicketInfoList({
    commit,
    state
  }, ticketInfoList) {
    commit('SET_TICKET_INFO_LIST', ticketInfoList);
  },
  setRetailInfoList({
    commit,
    state
  }, retailInfoList) {
    commit('SET_RETAIL_INFO_LIST', retailInfoList);
  },
  setSeasonList({
    commit,
    state
  }, seasonList) {
    commit('SET_SEASON_LIST', seasonList);
  },
  setTransferList({
    commit,
    state
  }, transferList) {
    commit('SET_TRANSFER_LIST', transferList);
  },
  setScrapInfoList({
    commit,
    state
  }, scrapInfoList) {
    commit('SET_SCRAP_LIST', scrapInfoList);
  },
  setGiftsInfoList({
    commit,
    state
  }, giftsInfoList) {
    commit('SET_GIFTS_LIST', giftsInfoList);
  },
  setUnReceptList({
    commit,
    state
  }, unReceptList) {
    commit('SET_UN_RECEPT_LIST', unReceptList);
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}