import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */
// asyncRoutes
// 公共路由
export const constantRoutes = [{
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: (resolve) => require(['@/views/redirect'], resolve)
    }]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/logisticsTrajectoryView',
    component: (resolve) => require(['@/views/logisticsTrajectoryView'], resolve),
    hidden: true
  },
  {
    path: '/certPage',
    component: (resolve) => require(['@/views/certPage'], resolve),
    hidden: true
  },
  {
    path: '/template',
    component: (resolve) => require(['@/views/template'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: Layout,
    redirect: 'index',
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [{
      path: 'index',
      component: (resolve) => require(['@/views/index'], resolve),
      name: '首页',
      meta: {
        title: '首页',
        icon: 'home',
        noCache: true,
        affix: true,
        tagsIndex: 0
      }
    }]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
      path: 'profile',
      component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
      name: 'Profile',
      meta: {
        title: '个人中心',
        icon: 'user'
      }
    }]
  },
  {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [{
      path: 'type/data/:dictId(\\d+)',
      component: (resolve) => require(['@/views/system/dict/data'], resolve),
      name: 'Data',
      meta: {
        title: '字典数据',
        icon: ''
      }
    }]
  },
  {
    path: '/gen',
    component: Layout,
    hidden: true,
    children: [{
      path: 'edit/:tableId(\\d+)',
      component: (resolve) => require(['@/views/tool/gen/editTable'], resolve),
      name: 'GenEdit',
      meta: {
        title: '修改生成配置'
      }
    }]
  }, {
    path: '/packet',
    component: Layout,
    hidden: true,
    children: [{
      path: 'show/:poolId(\\d+)',
      component: (resolve) => require(['@/views/product/activity/packet-list'], resolve),
      name: 'packetList',
      meta: {
        title: '活动套餐管理'
      }
    }]
  }, {
    path: '/template',
    name: 'template',
    component: (resolve) => require(['@/views/performance/gridAssess/templatess/index'], resolve),
  }, {
    path: '/performance',
    component: Layout,
    hidden: true,
    children: [{
      path: '/performance/setSale',
      component: (resolve) => require(['@/views/performance/parentAssess/setSale'], resolve),
      name: 'SetSale',
      meta: {
        title: '单品销售任务设置',
      }
    }, {
      path: '/performance/meshAssess/taskAdd',
      component: (resolve) => require(['@/views/performance/meshAssess/taskManagement/taskAdd'], resolve),
      name: 'TaskAdd',
      meta: {
        title: '网格员任务新增',
      }
    }, {
      path: '/performance/meshAssess/assessSet',
      component: (resolve) => require(['@/views/performance/meshAssess/taskManagement/assessSet'], resolve),
      name: 'AssessSet',
      meta: {
        title: '网格员任务设置',
      }
    },
    ]
  },
  // {
  //   path: '/pool/act/add',
  //   component: (resolve) => require(['@/views/product/index/addActProductPool'], resolve),
  //   name: 'AddPool'
  // },
  {
    path: '/pool',
    component: Layout,
    hidden: true,
    children: [{
        path: 'act/add',
        component: (resolve) => require(['@/views/product/index/addActProductPool'], resolve),
        name: 'AddPool',
        meta: {
          title: '新增活动产品池',
        }
      },
      {
        path: 'act/edit/:poolId',
        component: (resolve) => require(['@/views/product/index/addActProductPool'], resolve),
        name: 'EditPool',
        meta: {
          title: '编辑活动产品池',
        }
      },
      {
        path: 'act/packet/add/:poolId',
        component: (resolve) => require(['@/views/product/index/packet/add'], resolve),
        name: 'AddPacket',
        meta: {
          title: '新增套餐',
        }
      },
      {
        path: 'act/packet/edit/:poolId',
        component: (resolve) => require(['@/views/product/index/packet/add'], resolve),
        name: 'EditPacket',
        meta: {
          title: '编辑套餐',
        }
      },
      {
        path: 'com/add',
        component: (resolve) => require(['@/views/subcompany/pool/add'], resolve),
        name: 'AddComPool',
        meta: {
          title: '新增子分公司产品目录',
        }
      },
    ]
  },
  {
    path: '/print',
    component: (resolve) => require(['@/views/print/index'], resolve),
    hidden: true
  },
]
export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
