<!-- 公用年度学季组件 -->
<template>
  <span :class="mode ? 'modeinline' : ''">
    <el-form-item label="" prop="year">
      <el-date-picker :style="{width: yearWidth || '100px'}" v-model="myYear" type="year" placeholder="年度"
        value-format="yyyy" @change="datechange" :clearable="clearable" :disabled="disabled"
        :editable="false"></el-date-picker>
    </el-form-item>

    <el-form-item label="" prop="quarterId">
      <el-select :disabled="disabled" @change="quarterchange" @clear="clearchange" filterable
        :style="{width: quarterWidth || '100px'}" v-model="quater" placeholder="学季" :clearable="clearable">
        <el-option v-for="dict in quarterOptions" :key="dict.dictValue" :label="dict.dictLabel"
          v-model="quarterKey === 'quarterName' ? dict.dictLabel : dict.dictValue" />
      </el-select>
    </el-form-item>
  </span>
</template>
<script>
  import {
    getYQ,
    getQuarterOpt
  } from '@/components/yearAndQuarter/common.js';

  export default {
    name: 'yearAndQuarter',

    props: {
      isSync: {
        type: Boolean,
        default: false,
      },
      // 是否显示清除按钮
      clearable: {
        type: Boolean,
        default: true
      },
      // 学季返回值，是id还是name
      quarterKey: {
        type: String,
        default: undefined
      },
      // 学季控件的宽度
      quarterWidth: {
        type: String,
        default: undefined
      },
      // 年度控件的宽度
      yearWidth: {
        type: String,
        default: undefined
      },
      // 显示在一行内，默认不需要加，特殊情况传 inline
      mode: {
        type: String,
        default: undefined
      },
      // 类型，传update代表修改页进来的，默认读取传来的数据，不读缓存
      type: {
        type: String,
        default: undefined
      },
      // 默认年份（新增修改页用到）
      defaultYear: {
        type: String | Number,
        default: undefined
      },
      // 默认年度（新增修改页用到）
      defaultQuarter: {
        type: String | Number,
        default: undefined
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
    },
    emits: ['update: year', 'update: quarter'],
    data() {
      return {
        getYQ,
        getQuarterOpt,
        quarterOptions: [],
        myYear: undefined,
        myQuarterId: undefined,
        myQuarterName: undefined,
        quater: undefined,
      }
    },
    watch: {
      defaultYear: {
        handler(val) {
          if (val) {
            this.myYear = val.toString();
          }
        },
      },
      defaultQuarter: {
        handler(val) {
          if (val) {
            this.quater = val.toString();
          }
        },
      },
    },
    activated() {
      if (this.type === 'update') {
        if (this.defaultYear && this.defaultQuarter) {
          this.myYear = this.defaultYear.toString();
          this.quater = this.defaultQuarter.toString();
          const result = this.getQuarter(this.quater);
          this.myQuarterId = result.id;
          this.myQuarterName = result.name;
          this.$emit('update:year', this.myYear);
          this.$emit('update:quarter', this.quater);
        } else {
          this.init();
        }
      } else {
        this.init();
      }
      console.log("组建重新唤起", this.quater, this.myYear, this.myQuarterId);
    },
    created() {
      // 获得学季下拉列表框数据
      this.getQuarterOpt().then(res => {
        this.quarterOptions = res
      });
      // 传update代表修改页进来的，默认读取传来的数据，不读缓存
      if (this.type === 'update') {
        if (this.defaultYear && this.defaultQuarter) {
          this.myYear = this.defaultYear.toString();
          this.quater = this.defaultQuarter.toString();
          const result = this.getQuarter(this.quater);
          this.myQuarterId = result.id;
          this.myQuarterName = result.name;
          this.$emit('update:year', this.myYear);
          this.$emit('update:quarter', this.quater);
        } else {
          this.init();
        }

      } else {
        this.init();
      }
    },
    methods: {
      // 初始化数据
      init() {

        console.log("调用初始化方法", this.isSync, this.myYear, this.quater);
        const result = this.getYQ();
        this.myYear = result.year;
        this.myQuarterId = result.quarterId;
        this.myQuarterName = result.quarterName;
        this.quater = this.quarterKey === 'quarterName' ? this.myQuarterName : this.myQuarterId;

        //重新设置学季 年度
        this.myYear = this.myYear !== null ? this.myYear : undefined;
        this.myQuarterId = result.quarterId;
        this.myQuarterName = result.quarterName;
        if (this.isSync) {
          //如果要同步
          if (this.myYear === undefined) {
            localStorage.removeItem('year')
          } else {
            localStorage.setItem('year', this.myYear)
          }
          localStorage.setItem('quarterId', result.quarterId);
          localStorage.setItem('quarterName', result.quarterName);
          console.log("设置数据完成",
            localStorage.getItem("year"),
            localStorage.getItem("quarterId"),
            localStorage.getItem("quarterName"));
        }
        this.$emit('update:year', this.myYear);
        this.$emit('update:quarter', this.quater);
        this.$store.dispatch('settings/setYearAndQuarter', {
          year: this.myYear,
          quarterId: this.myQuarterId
        });
      },
      initQuarter() {

      },
      datechange() {
        this.myYear = this.myYear !== null ? this.myYear : undefined;
        this.$emit('update:year', this.myYear);
        this.$emit('change', {
          year: this.myYear,
          quater: this.quater,
          quarterId: this.myQuarterId,
          quarterName: this.myQuarterName
        });
        this.$store.dispatch('settings/setYearAndQuarter', {
          year: this.myYear,
          quarterId: this.myQuarterId
        });
        if (this.isSync) {
          if (this.myYear === undefined) {
            localStorage.removeItem('year')
          } else {
            localStorage.setItem('year', this.myYear)
          }

        }

      },
      getQuarter(key) {
        switch (key) {
          case '0':
          case '春季':
            return {
              id: '0',
                name: '春季'
            };
          case '1':
          case '秋季':
            return {
              id: '1',
                name: '秋季'
            };

          default:
            return '';
        }
      },
      quarterchange() {
        this.$emit('update:quarter', this.quater);
        const result = this.getQuarter(this.quater);
        if (this.isSync) {
          localStorage.setItem('quarterId', result.id);
          localStorage.setItem('quarterName', result.name)
        }
        this.$store.dispatch('settings/setYearAndQuarter', {
          year: this.myYear,
          quarterId: this.myQuarterId
        });
        this.$emit('change', {
          year: this.myYear,
          quater: this.quater,
          quarterId: result.id,
          quarterName: result.name
        });
        this.myQuarterId = result.id;
        this.myQuarterName = result.name;

      },
      clearchange() {
        this.$emit('update:quarter', undefined);
        if (this.isSync) {
          localStorage.removeItem('quarterId');
          localStorage.removeItem('quarterName')
        }
        this.$emit('change', {
          year: this.myYear,
          quater: this.quater,
          quarterId: this.myQuarterId,
          quarterName: this.myQuarterName
        });
      }
    }
  }
</script>
<style scoped>
  .modeinline {
    display: flex;
  }

  .modeinline>div {
    flex: 1;
  }

  .modeinline>div:first-child {
    margin-right: 10px;
  }
</style>