import Cookies from 'js-cookie'

const state = {
  refundInfoList: [], //新增OL退款 临时数据
}

const mutations = {


  COMMIT_REFUND_LIST(state, refundInfoList) {
    state.refundInfoList = refundInfoList;
  },

}

const actions = {
  setRefundInfoList({
    commit
  }, data) {
    commit('COMMIT_REFUND_LIST', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}