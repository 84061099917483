<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view v-if="noCachedView" :key="key" />
      <keep-alive v-else>
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import watermark from "watermark-dom";
import { noCachedViews } from './noCachedViews.js'

export default {
  name: "AppMain",
  computed: {
    noCachedView(){
      let noCache = false;
      noCachedViews.forEach((url) => {
        if(noCache == false && this.$route.path.indexOf(url) > -1){
          noCache = true;
        }
      });
      return noCache;
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  },
  mounted() {
    // 加载水印
    const username = this.$store.state.user.name;
    watermark.load({
      watermark_txt: username,
      watermark_rows: 10,
      watermark_cols: 10,
      watermark_width: 160,
      watermark_height: 100,
      watermark_alpha: 0.02
    });
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: auto;
  background-color: #F1F1F1 ;
  padding: 10px;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    height: calc(100vh - 84px);
    background-color: #f4f7fc;
    padding: 0 10px 10px 10px;
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
