import Vue from "vue";

/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
  if (cellValue == null || cellValue == "") {
    return "";
  }
  var date = new Date(cellValue);
  var year = date.getFullYear();
  var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      s++;
    } else if (code > 0x7ff && code <= 0xffff) {
      s += 2;
    }
    if (code >= 0xDC00 && code <= 0xDFFF) {
      i--;
    }
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) {
    return "";
  }
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) {
        return "";
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split("?")[1]).replace(/\+/g, " ");
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split("&");
  searchArr.forEach(v => {
    const index = v.indexOf("=");
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

export function getNowDate() {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = currentDate.getDate();
  return `${year}-${month}-${day}`;
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait = 500, immediate = false) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) {
          context = args = null;
        }
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) {
    ele.className += " " + cls;
  }
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

export function makeMap(str, expectsLowerCase) {
  const map = Object.create(null);
  const list = str.split(",");
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }
  return expectsLowerCase ?
    val => map[val.toLowerCase()] :
    val => map[val];
}

export const exportDefault = "export default ";

export const beautifierConf = {
  html: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "separate",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "normal",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
};

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, L => L.toUpperCase());
}

// 下划转驼峰
export function camelCase(str) {
  return str.replace(/-[a-z]/g, str1 => str1.substr(-1).toUpperCase());
}

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}

/**
 * 数字格式化
 * @param number 要格式化的数字
 * @param decimals 保留几位小数
 * @param dec_point 小数点符号
 * @param thousands_sep 千分位符号
 * @returns {string}
 */
export function numberFormat(number, decimals = 2, dec_point = ".", thousands_sep = ",") {
  number = (number + "").replace(/[^0-9+-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number,
    pre = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === "undefined") ? "," : thousands_sep,
    dec = (typeof dec_point === "undefined") ? "." : dec_point,
    s,
    toFixedFix = function (n, pre) {
      let k = Math.pow(10, pre);
      let arr = n.toString().split(".");
      let num = arr[0];
      let _decimal =
        arr.length > 1 && arr[1].length > pre
          ? arr[1].substring(0, pre)
          : arr[1];
      return num + (_decimal ? "." + _decimal : "");
    };
  // 为非有限数是小数位位为0
  s = (pre ? toFixedFix(n, pre) : "" + Math.floor(n)).split(".");
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }
  if ((s[1] || "").length < pre) {
    s[1] = s[1] || "";
    s[1] += new Array(pre - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

/**
 * 表格数字金额格式化
 * @param row
 * @param column
 * @param cellValue
 * @returns {string}
 */
export function tableNumberFormat(row, column, cellValue) {
  return numberFormat(cellValue);
}
/**
 * 李嘉
 * 显示提交状态
 * @param row
 * @param column
 * @param cellValue
 * @returns {string}
 */
export function tablestatusFormat(row, column, cellValue) {
  return cellValue === 1 ? "已提交" : "未提交";
}

/**
 * 表格数字百分比格式化
 * @param row
 * @param column
 * @param cellValue
 * @returns {string}
 */
export function tablePercentSignFormat(row, column, cellValue) {
  return numberPercentSign(cellValue);
}

/**
 * 百分化格式化
 * @param number 要格式化的数字
 * @param decimals 保留几位小数
 * @param isadd 是否要进位100
 * @returns {string}
 *
 * @change date:2023/3/28;提出人:杨世杰; 修改:黄智文;需求全系统折扣取消百分号,在列头加(%);  注释的代码为修改之前原始版本;
 */
export function numberPercentSign(number, decimals = 2, isadd = 1) {
  if (isadd == 1) {
    return number && (number * 100).toFixed(decimals);
    // return number && (number * 100).toFixed(decimals) + '%'
  } else {
    return number && (number).toFixed(decimals);
    // return number && (number).toFixed(decimals) + '%'
  }
}

/**
 * 数字格式化
 */
export function Formatnumber(value) {
  if (value == "-") {
    return value;
  } else if (value < 1000) {
    let vArr = value.toString().split(".");
    if (vArr[1]) {
      let Arr = vArr[1].toString();
      if (Arr.length == 3) {
        vArr[1] = Arr + "0";
      } else if (Arr.length == 2) {
        vArr[1] = Arr + "00";
      } else if (Arr.length == 1) {
        vArr[1] = Arr + "000";
      }
      return vArr[0] + "." + vArr[1];
    } else {
      return vArr[0] + "." + "0000";
    }
  } else if (1000 <= parseInt(value) && parseInt(value) < 1000000) {
    let vArr = value.toString().split(".");
    let firNum = vArr[0].substring(0, vArr[0].length - 3);
    let secNum = vArr[0].substring(vArr[0].length - 3, vArr[0].length);
    if (vArr[1]) {
      let Arr = vArr[1].toString();
      if (Arr.length == 3) {
        vArr[1] = Arr + "0";
      } else if (Arr.length == 2) {
        vArr[1] = Arr + "00";
      } else if (Arr.length == 1) {
        vArr[1] = Arr + "000";
      }
      return firNum + "," + secNum + "." + vArr[1];
    } else {
      return firNum + "," + secNum + "." + "0000";
    }
  } else if (1000000 <= parseInt(value) && parseInt(value) < 1000000000) {
    let vArr = value.toString().split(".");
    let firNum = vArr[0].substring(0, vArr[0].length - 6);
    let secNum = vArr[0].substring(vArr[0].length - 6, vArr[0].length - 3);
    let thiNum = vArr[0].substring(vArr[0].length - 3, vArr[0].length);
    if (vArr[1]) {
      let Arr = vArr[1].toString();
      if (Arr.length == 3) {
        vArr[1] = Arr + "0";
      } else if (Arr.length == 2) {
        vArr[1] = Arr + "00";
      } else if (Arr.length == 1) {
        vArr[1] = Arr + "000";
      }
      return firNum + "," + secNum + "." + vArr[1];
    } else {
      return firNum + "," + secNum + "," + thiNum + "." + "0000";
    }
  } else {
    return value;
  }
}

/**
 * @param {Object} time
 * 秒转时分秒
 */
export function getHMS(time) {
  const hour = parseInt(time / 3600) < 10 ? "0" + parseInt(time / 3600) : parseInt(time / 3600);
  const min = parseInt(time % 3600 / 60) < 10 ? "0" + parseInt(time % 3600 / 60) : parseInt(time % 3600 / 60);
  const sec = parseInt(time % 3600 % 60) < 10 ? "0" + parseInt(time % 3600 % 60) : parseInt(time % 3600 % 60);
  return hour + ":" + min + ":" + sec;
}

//下载文件并重命名
export function downloadFileRename(url, filename) {
  function getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else if (xhr.status === 404) {
          //throw new Error("404", "文件不存在");
          //Vue.prototype.msgError('文件不存在')
          Vue.prototype.$message({
            showClose: true,
            message: "文件不存在",
            type: "error"
          });
          return;
        }
      };
      xhr.send();
    });
  }

  function saveAs(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      const body = document.querySelector("body");

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
      return;
    }
  }

  getBlob(url).then((blob) => {
    saveAs(blob, filename);
  });

}

/**
 * 图书类型格式化
 * @param row
 * @param column
 * @param cellValue
 * @returns {string}
 */
export function bookTypeFormatter(row, column, cellValue, index) {
  //从浏览器获取图书类型缓存
  let dictList = Vue.prototype.$allDict().filter((item) => {
    item.dictType === 'book_type'
  });
  let bookTypeValue = row.bookType;
  let bookTypes = dictList.filter((item) => {
    item.dictValue === bookTypeValue
  });
  if (bookTypes.length == 0) {
    return "";
  }
  return bookTypes[0].dictLabel;
}

/**
 * 获取用户自身图书类型 如果有就返回图书类型组成的数组，没有就返回false
 */
export function getUserInfoBookType() {
  let userBookType = JSON.parse(localStorage.getItem('userInfo')).bookType
  return userBookType ? userBookType.split(',') : false
}


// 字典数据获取名称
export async function getDictName({ type, value }) {
  if (!value) return
  let result = Vue.prototype.$allDict().filter((item) => { item.dictType === type });
  result = result.filter((item) => { item.dictValue === value });
  return result[0].dictLabel || '';
}

/**
 * 是否是空对象
 * @param obj
 * @return {boolean}
 * */
export function isObjectEmpty(obj) {
  return Object.prototype.toString.call(obj) !== '[object Object]' || (Object.keys(obj).length === 0)
}

// 图书类型根据登录人过滤
export function bookTypeFilterByUser() {
  let bookTypeData = [];
  try {
    const { bookType } = JSON.parse(localStorage.getItem("userInfo"));
    bookTypeData = Vue.prototype.$allDict().filter((item) => { item.dictType === 'book_type' });
    if (!bookType) return bookTypeData
    return bookTypeData.filter(item => bookType.includes(item.dictValue));
  } catch (error) {
    return bookTypeData;
  }
}

// 请求数据转字典
export function convertDicData(data = [], key, value) {
  const list = []
  data.map(item => {
    const obj = {
      ...item
    }
    obj['dictLabel'] = item[key]
    obj['dictValue'] = item[value]
    list.push(obj)
  })
  return list
}

// 分类根据登录人过滤
export function categoryFilterByUser(data = []) {
  try {
    const { categoryIds } = JSON.parse(localStorage.getItem("userInfo"));
    if (!categoryIds) return data
    return data.filter(item => {
      const category = categoryIds.split(',')
      return category.includes(String(item.categoryId))
    });
  } catch (error) {
    return data;
  }
}

// 客商类型过滤


export function customerFilterByUser() {
  let list = [];
  try {
    const { level } = JSON.parse(localStorage.getItem("userInfo"));
    list = Vue.prototype.$allDict().filter((item) => { item.dictType === 'customer_type' });
    return list.filter(item => level == 1 ? item.dictValue !== '1' : item.dictValue !== '3')
  } catch (error) {
    return list;
  }
}

function checkRole(arr1, arr2) {
  var set1 = new Set(arr1);
  var set2 = new Set(arr2);
  for (var item of set1) {
    if (set2.has(item)) {
      return true;
    }
  }
  return false;
}

export function rolesCheck(val) {
  const { roles = [] } = JSON.parse(localStorage.getItem("userInfo"))
  const roleIds = roles.map(item => item.roleId)
  return checkRole(val, roleIds)
}

export function _debounce(fn, delay = 300) {
  var timer = null;
  return function () {
    var _this = this;
    var args = arguments;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(_this, args);
    }, delay);
  };
}
