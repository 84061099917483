<template>
  <div class="content">
    <el-select
      v-model="chooseValue"
      :clearable="clearable"
      filterable
      default-first-option
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :no-data-text="noDataText"
      :filter-method="filterMethod"
      v-loadmore="loadMore"
      :placeholder="placeholder"
      @visible-change="visibleChange"
      @change="changeSelect"
      :disabled="disabled"
      :style="styleObj"
    >
      <el-option
        v-for="(item, index) in options"
        :key="item[valueKey]+index"
        :label="item[labelKey]"
        :value="item[valueKey]"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { _debounce } from "@/utils/index.js";
// 带滚动加载的下拉选择组件,用于解决下拉数量过多页面卡顿问题
export default {
  data() {
    return {
      chooseValue: "",
      options: [],
      rangeNumber: 10,
    };
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "无数据",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "dictLabel",
    },
    valueKey: {
      type: String,
      default: "dictValue",
    },
    styleObj: {
      type: Object,
      default: () => {},
    },
    option: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler(val) {
        this.chooseValue = val;
      },
      immediate: true,
      deep: true,
    },
    option: {
      handler(newVal) {
        // console.log(newVal, "newVal");
        this.options = newVal.slice(0, this.rangeNumber);
      },
      deep: true,
    },
  },
  mounted() {
    // 设默认值
    this.options = this.option.slice(0, this.rangeNumber);
  },
  methods: {
    // 滚动加载
    loadMore() {
      this.rangeNumber += 5;
      this.options = this.option.slice(0, this.rangeNumber);
    },
    filterMethod: _debounce(function (filterVal) {
      if (filterVal) {
        let filterArr = this.option.filter((item) =>
          item[this.labelName].toLowerCase().includes(filterVal.toLowerCase())
        );
        this.options = filterArr.slice(0, this.rangeNumber);
      } else {
        this.options = this.option.slice(0, this.rangeNumber);
      }
    }, 500),
    visibleChange(flag) {
      if (flag) {
        this.filterMethod("");
      }
    },
    changeSelect(value) {
      this.$emit("input", value);
    },
  },
  // 滚动监听
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrapDom = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        if (selectWrapDom) {
          selectWrapDom.addEventListener("scroll", function () {
            const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
            if (condition) binding.value();
          });
        }
      },
    },
  },
};
</script>