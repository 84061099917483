<template>
  <div class="">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          :disabled="disabled"
          icon="el-icon-upload2"
          plain
          type="primary"
          @click="handleExport"
          :loading="fullscreenLoading"
        >数据导出
        </el-button
        >
      </el-col>
      <el-col :span="1.5">
        <!-- <el-button type="success" icon="el-icon-plus" plain>打印</el-button> -->
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-form
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <YearAndQuarter :quarter.sync="queryParams.quarterName" :year.sync="queryParams.year" quarterKey="quarterName"/>
      <!--      <el-form-item label="" prop="year">-->
      <!--        <el-date-picker-->
      <!--          v-model="queryParams.year"-->
      <!--          style="width: 100px"-->
      <!--          clearable-->
      <!--          type="year"-->
      <!--          value-format="yyyy"-->
      <!--          placeholder="年度"-->
      <!--        >-->
      <!--        </el-date-picker>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="" prop="quarterName">-->
      <!--        <el-select-->
      <!--          style="width: 100px"-->
      <!--          clearable-->
      <!--          v-model="queryParams.quarterName"-->
      <!--          placeholder="学季"-->
      <!--          filterable-->
      <!--          @change="changeQuarte"-->
      <!--        >-->
      <!--          <el-option-->
      <!--            v-for="dict in quarterNameOptions"-->
      <!--            :key="dict.dictValue"-->
      <!--            :label="dict.dictLabel"-->
      <!--            :value="dict.dictLabel"-->
      <!--          />-->
      <!--        </el-select>-->


      <!--      </el-form-item>-->
      <el-form-item v-if="areaId" label="" prop="quarterName">
        <el-select
          v-model="queryParams.areaId"
          clearable
          filterable
          placeholder="地区"
        >
          <el-option
            v-for="item in areaList"
            :key="item.areaId"
            :label="item.areaName"
            :value="item.areaId"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="pressId" label="" prop="quarterName">
        <el-select
          v-model="queryParams.pressId"
          clearable
          filterable
          placeholder="版别"
        >
          <el-option
            v-for="item in pressOptions"
            :key="item.pressId"
            :label="item.pressSimpleName"
            :value="item.pressId"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="categoryId" label="" prop="quarterName">
        <el-select
          v-model="queryParams.categoryId"
          clearable
          filterable
          placeholder="分类"
        >
          <el-option
            v-for="item in calssifyList"
            :key="item.categoryId"
            :label="item.categoryName"
            :value="item.categoryId"

          >
            <span style="float: left">{{ item.categoryName }}</span>
            <!--      <span style="float: right; color: #8492a6; font-size: 13px">{{queryParams.year+'-'+queryParams.quarterName }}</span>-->

          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="companyId" label="" prop="quarterName">
        <el-select
          v-model="queryParams.companyId"
          clearable
          filterable
          placeholder="子分公司"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.companyId"
            :label="item.companySimpleName"
            :value="item.companyId"
          />
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="handleQuery"
        >搜索
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {companyByAreaIdList} from "@/api/edu/school.js";
import {listCompany} from "@/api/company/company.js";
import {listCategory, listpress} from '@/api/productPool/process'
import {selectAreaName} from "@/api/province/province";
import YearAndQuarter from "@/components/yearAndQuarter";


export default {
  components: {
    YearAndQuarter
  },
  props: {
    companyId: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    },
    pressId: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: Boolean,
      default: false
    },
    exportName: {
      type: String,
      default: ""
    },
    areaId: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    fullscreenLoading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      calssifyList: [],
      pressOptions: [],
      areaList: [],
      queryParams: {
        quarterName: "",//学季
        companyId: "",//子分公司id
        categoryId: "",//分类
        pressId: '',
        areaId: ''

      },
      quarterNameOptions: [],
      companyOptions: [],
      dictValue: '',
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    dictValue(e) {
      localStorage.setItem('quarterId', e)
      if (this.categoryId == true) {
        this.classify()
      }
    }
  },
  //方法集合
  methods: {
    changeQuarte() {
      let index = this.quarterNameOptions.findIndex(item => item.dictLabel == this.queryParams.quarterName)
      this.dictValue = this.quarterNameOptions[index].dictValue
    },
    async handleExport() {
      await this.$emit('handleExport', this.exportName)
     /*  this.$confirm(`是否确认导出所有${this.exportName}统计数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('handleExport', this.exportName)
      }).catch(() => {

      }); */

    },
    handleQuery() {
      if (!this.queryParams.year) {
        return this.$message.info("请选择年度")
      }
      let params = {
        companyId: this.companyId,
        categoryId: this.categoryId,
        pressId: this.pressId,
        areaId: this.areaId
      }
      Object.keys(params).forEach((item) => {
        if (params[item] == false) {
          delete this.queryParams[item]
        }
      })
      this.$bus.$emit("handleQuery", this.queryParams);
    },
    installTIme() {
      const localYear = localStorage.getItem('year');
      const localQuarterName = localStorage.getItem('quarterName');
      if (localYear == null || localQuarterName == null) {
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth() + 1;
        this.queryParams.year = currentDate.getFullYear().toString();
        if (currentMonth >= 1 && currentMonth <= 4) {
          this.queryParams.quarterName = "春季";
        } else if (currentMonth >= 5 && currentMonth <= 10) {
          this.queryParams.quarterName = "秋季";
        } else if (currentMonth >= 11 && currentMonth <= 12) {
          this.queryParams.year = currentDate.setFullYear(
            currentDate.getFullYear() + 1
          );
          this.queryParams.quarterName = "春季";
        }
      } else {

        this.queryParams.year = localYear;
        this.queryParams.quarterName = localQuarterName
      }
      this.dictValue = this.queryParams.quarterName == "春季" ? 0 : 1


      this.handleQuery();
    },
    async classify() {

      let {rows} = await listCategory({categoryYear: this.queryParams.year, startQuarter: this.queryParams.quarterName})
      this.calssifyList = rows


    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

    this.getDicts("sys_quarter").then((response) => {
      this.quarterNameOptions = response.data;

    });
    if (this.pressId == true) {
      listpress({status: "0", delFlag: "0"}).then(response => {
        this.pressOptions = response.rows;
      });
    }

    if (this.status) {

      listCompany().then(
        (response) => {
          this.companyOptions = response.rows;
        }
      );
    } else {
      if (this.$store.state.user.userInfo.areaId) {
        companyByAreaIdList(this.$store.state.user.userInfo.areaId).then(
          (response) => {
            this.companyOptions = response.data;
          }
        );
        return
      }
      listCompany().then(
        (response) => {
          this.companyOptions = response.rows;
        }
      );

    }
    if (this.areaId) {
      selectAreaName().then(res => {
        this.areaList = res.rows
      })
    }


  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //  console.log(this.areaId)
    this.handleQuery()

  },
  beforeCreate() {

  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {

  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
</style>
