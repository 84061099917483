import request from '@/utils/request'

export function queryDeptList(companyId) {
  return request({
    url: '/SD/company/dept/queryDeptList/'+companyId,
    method: 'get',
  })
}

export function queryDeptListStatus(companyId) {
  return request({
    url: '/SD/company/dept/queryDeptListStatus/'+companyId,
    method: 'get',
  })
}

export function addDept(data) {
  return request({
    url: '/SD/company/dept/addDept',
    method: 'post',
    data: data
  })
}

export function updateDept(data) {
  return request({
    url: '/SD/company/dept/updateDept',
    method: 'post',
    data: data
  })
}

export function delDept(data) {
  return request({
    url: '/SD/company/dept/delDept',
    method: 'post',
    data: data
  })
}

export function updateStatus(status,data) {
  return request({
    url: '/SD/company/dept/updateStatus/'+status,
    method: 'post',
    data:data
  })
}

export function getUserDeptName(companyId) {
  return request({
    url: '/SD/staff/controlCompany/getUserDeptName/'+companyId,
    method: 'get',
  })
}

export function queryDeptListByCompanyId(query) {
  return request({
    url: '/SD/company/dept/queryDeptListByCompanyId',
    method: 'get',
    params: query
  })
}
