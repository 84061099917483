import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import user from "./modules/user";
import tagsView from "./modules/tagsView";
import permission from "./modules/permission";
import settings from "./modules/settings";
import uploadImg from "./modules/uploadImg";
import getters from "./getters";
import VueTaskNode from "vue-task-node";
import course from "./modules/course";
import stock from "./modules/stock";
import settlement from "./modules/settlement";
import refund from './modules/refund'
import subCompanyStock from './modules/subCompanyStock'
import daily from './modules/daily'
import allDictData from "./modules/allDictData";
Vue.use(Vuex);
Vue.use(VueTaskNode);

const store = new Vuex.Store({
  modules: {
    refund,
    course,
    stock,
    app,
    user,
    tagsView,
    permission,
    settings,
    uploadImg,
    VueTaskNode,
    settlement,
    subCompanyStock,
    daily,
    allDictData
  },
  getters
});

export default store;