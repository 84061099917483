<template>
  <div>
    <el-select v-model="value" filterable :placeholder="placeholder" :style="{'width':width}" clearable @change="change" :disabled="disabled"
      @clear="clear">
      <el-option v-for="(item, index) in categoryList" :key="index" :label="item.subCategoryName"
        :value="item.subCategoryId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {
    allListCategory,

  } from "@/api/product/category";
  export default {
    name: "subCategoryComboBox",
    components: {

    },
    props: {
      placeholder: {
        type: String,
        default: ''
      },
      value: {
        type: Number || String,
      },

      width: {
        type: String,
        default: "140px"
      },

      disabled: {
        type: Boolean,
        default: false
      },

    },
    data() {
      return {
        categoryList: []
      };
    },
    emits: ['update: value', 'update: name'],
    created() {
      this.queryList();
    },
    methods: {
      clear() {
        this.$emit('update:value', "");
        let selectCategory = this.categoryList.filter(info => {
          if (this.value == info.subCategoryId) {
            return info;
          }
        });
        let categoryName = selectCategory[0].subCategoryName;
        this.$emit('update:name', "");
      },
      change(val) {
        this.$emit('update:value', val);
        let selectCategory = this.categoryList.filter(info => {
          if (val == info.subCategoryId) {
            return info;
          }
        });
        let categoryName = selectCategory[0].subCategoryName;
        this.$emit('update:name', categoryName);
        console.log(categoryName);
      },
      queryList() {
        allListCategory({}).then((response) => {
          this.categoryList = response.rows;
        });
      },
    }
  };
</script>

<style lang="less" scoped>
  ::v-deep .el-dialog__body {
    margin-top: -40px;
  }

  .cacl-height {
    height: calc(100vh - 301px) !important;
  }

  ::v-deep .el-divider {
    display: none;
  }
</style>