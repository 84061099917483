<template>
  <div>
    <el-input
      v-model="realValue"
      class="amount-input"
      :style="{ width: width }"
      :disabled="disabled"
      :placeholder="placeholder"
      @blur="amountBlur()"
      @focus="amountFocus()"
      @change="amountChange()"
      :clearable="clearable"
      @input="handleInputChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    disabled: {
      default: false
    },
    clearable: {
      default: true
    },
    placeholder: {
      default: "请输入"
    },
    width: {
      default: ""
    },
    regExp: {
      //过滤字符的正则表达式
      default: ""
    }
  },
  data() {
    return {
      realValue: ""
    };
  },
  watch: {
    value: {
      handler(val) {
        this.realValue = this.$filters.numberFormat(val);
      },
      immediate: true,
      deep: true
    }
  },

  mounted() {
    this.realValue = this.$filters.numberFormat(this.value);
  },
  methods: {
    amountBlur() {
      this.realValue = this.$filters.numberFormat(this.$filters.amountNumber(this.realValue));
      this.$emit(
        "update:value",
        this.$filters.amountNumber(this.realValue).toString()
      );
      this.$emit("blur");
    },
    amountFocus() {
      this.realValue = this.$filters.amountNumber(this.realValue);
      this.$emit(
        "focus",
        this.$filters.amountNumber(this.realValue).toString()
      );
    },
    amountChange() {
      this.$emit(
        "change",
        this.$filters.amountNumber(this.realValue).toString()
      );
    },
    handleInputChange(value) {
      if (this.regExp !== "") {
        this.realValue = value.replace(new RegExp(this.regExp, "g"), "");
        console.log(
          value,
          this.regExp,
          new RegExp(this.regExp, "g"),
          this.realValue
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.amount-input {
  input {
    text-align: right;
  }
}
</style>
