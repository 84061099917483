<template>
  <div>
    <el-select v-model="dictValue" filterable
               :placeholder="placeholder"
               :style="{'width':width}"
               :disabled="disabled"
               clearable @change="change"
      @clear="clear">
      <el-option v-for="(item, index) in dictList" :key.sync="index" :label.sync="item.dictLabel"
        :value.sync="item.dictValue">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {
    listData,
    getDicts
  } from "@/api/system/dict/data";

  export default {
    name: "dictComboBox",
    components: {},
    props: {
      placeholder: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      dictType: {
        type: String,
        default: 'book_type'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: "140px"
      }

    },
    data() {
      return {
        dictValue: undefined,
        dictList: []
      };
    },
    emits: ['update: value'],
    watch: {
      value: {
        handler(newVal) {
          // newVal是新值，oldVal是旧值
          if (null == newVal || newVal == undefined || newVal == "null") {
            this.dictValue = undefined;
            this.$forceUpdate()
            return;
          }
          this.dictValue = newVal + "";
          this.$forceUpdate()
        },
        immediate: true,
      },

    },
    created() {
      this.$nextTick(() => {
        if (null == this.value || this.value == undefined || this.value == "null") {
          this.dictValue = undefined;
        } else {
          this.dictValue = this.value + "";
        }

        this.queryDictList();
      })
    },
    methods: {
      clear() {
        this.$emit('update:value', "");
      },
      change(val) {
        this.$emit('update:value', val);
      },
      queryDictList() {
        if (this.dictType === "book_type") {
          // 图书类型变动较大，需实时获取数据
          getDicts("book_type").then((resp) => {
            this.dictList = resp.data;
          });
          return;
        }
        this.dictList = this.$allDict().filter(item => {
          if (item.dictType == this.dictType) {
            return item;
          }
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  ::v-deep .el-dialog__body {
    margin-top: -40px;
  }

  .cacl-height {
    height: calc(100vh - 301px) !important;
  }

  ::v-deep .el-divider {
    display: none;
  }
</style>
