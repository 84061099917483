/*
  dictValue为String类型，不要用number类型。
*/
// 枚举合集
export default {
  yesOrNo: {
    list: [
      { dictLabel: '是', dictValue: '1' },
      { dictLabel: '否', dictValue: '0' },
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return '是'
        case '0':
          return '否'
        default:
          return val
      }
    }
  },
  //报订类型
  orderStatus: {
    list: [
      { dictLabel: '待总部确认', dictValue: '1' },
      { dictLabel: '总部已确认', dictValue: '2' },
      { dictLabel: '总部拒绝', dictValue: '3' }
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      switch (val) {
        case '1':
          return '待总部确认'
        case '2':
          return '总部已确认'
        case '3':
          return '总部拒绝'
        default:
          return val
      }
    }
  },
  upgradeOption: {
    // 枚举列表
    list: [{
      dictLabel: '未升级',
      dictValue: '-1'
    }, {
      dictLabel: '升级中',
      dictValue: '0'
    }, {
      dictLabel: '升级中',
      dictValue: '1'
    }, {
      dictLabel: '已升级',
      dictValue: '2'
    }],
    getName: function (val) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].dictValue == val) {
          return this.list[i].dictLabel
        }
      }
    }
  },
  //是否厂送
  factorySend: {
    getName: function (val) {
      switch (val) {
        case '1':
          return '是'
        default:
          return '否'
      }
    }
  },
  //学制
  schoolScope: {
    list: [
      { dictLabel: '学前', dictValue: '0' },
      { dictLabel: '小学', dictValue: '1,2,3,4,5,6' },
      { dictLabel: '初中', dictValue: '7,8,9' },
      { dictLabel: '高中', dictValue: '10,11,12' },
      { dictLabel: '九年一贯制', dictValue: '1,2,3,4,5,6,7,8,9' },
      { dictLabel: '初高中', dictValue: '7,8,9,10,11,12' },
      { dictLabel: '小初高', dictValue: '1,2,3,4,5,6,7,8,9,10,11,12' },
      { dictLabel: '大中专', dictValue: '13' }
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      switch (val) {
        case '0':
          return '学前'
        case '1,2,3,4,5,6':
          return '小学'
        case '7,8,9':
          return '初中'
        case '10,11,12':
          return '高中'
        case '1,2,3,4,5,6,7,8,9':
          return '九年一贯制'
        case '7,8,9,10,11,12':
          return '初高中'
        case '1,2,3,4,5,6,7,8,9,10,11,12':
          return '小初高'
        case '13':
          return '大中专'
        default:
          return val
      }
    }
  },
  //提交状态
  submitStatus: {
    list: [
      { dictLabel: '未提交', dictValue: '0' },
      { dictLabel: '已提交', dictValue: '1' },
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      val=val+'';
      switch (val) {
        case '0':
          return '未提交'
        case '1':
          return '已提交'
        default:
          return val
      }
    }
  },
  //发布状态
  publishStatus: {
    list: [
      { dictLabel: '已发布', dictValue: '1' },
      { dictLabel: '已撤销', dictValue: '2' },
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      switch (val) {
        case '1':
          return '已发布'
        case '2':
          return '已撤销'
        default:
          return val
      }
    }
  },
  //教育监管-分类
  categoryId: {
    list: [
      { dictLabel: '目录教辅', dictValue: '1' },
      { dictLabel: '国标教材', dictValue: '2' },
      { dictLabel: '地方教材', dictValue: '3' },
      { dictLabel: '专题', dictValue: '4' },
      { dictLabel: '学习材料类', dictValue: '5' },
      { dictLabel: '特殊教育(大学版)', dictValue: '6' },
      { dictLabel: '学校教育', dictValue: '7' },
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return '目录教辅'
        case '2':
          return '国标教材'
        case '3':
          return '地方教材'
        case '4':
          return '专题'
        case '5':
          return '学习材料类'
        case '6':
          return '特殊教育(大学版)'
        case '7':
          return '学校教育'
        default:
          return val
      }
    }
  },
  // 审核类型
  submitState: {
    list: [
      { dictLabel: "未提交", dictValue: "0" },
      { dictLabel: "已提交", dictValue: "1" }
    ],
    getName: function (val) {
      switch (val) {
        case "0":
          return "未提交";
        case "1":
          return "已提交";
        default:
          return "无";
      }
    }
  },
  // 是否免费
  isFreeFlag: {
    list: [
      { dictLabel: "非免费", dictValue: "0" },
      { dictLabel: "免费", dictValue: "1" }
    ],
    getName: function (val) {
      switch (val) {
        case "0":
          return "非免费";
        case "1":
          return "免费";
        default:
          return "无";
      }
    }
  },
  // 应付金额
  payableAmount: {
    list: [
      { dictLabel: "应付余额<>0", dictValue: "1" },
      { dictLabel: "应付余额=0", dictValue: "2" }
    ],
    getName: function (val) {
      switch (val) {
        case "1":
          return "应付余额<>0";
        case "2":
          return "应付余额=0";
        default:
          return "无";
      }
    }
  },
  // 应收金额
  amountReceivable: {
    list: [
      { dictLabel: "应收余额=结算实洋", dictValue: "0" },
      { dictLabel: "应收余额<>0", dictValue: "1" },
      { dictLabel: "应收余额=0", dictValue: "2" }
    ],
    getName: function (val) {
      switch (val) {
        case "0":
          return "应收余额=结算实洋";
        case "1":
          return "应付余额<>0";
        case "2":
          return "应付余额=0";
        default:
          return "无";
      }
    }
  },
  // 是否审核
  auditState: {
    list: [
      { dictLabel: "已审核", dictValue: "1" },
      { dictLabel: "未审核", dictValue: "2" }
    ],
    getName: function (val) {
      switch (val) {
        case "1":
          return "已审核";
        default:
          return "未审核";
      }
    }
  },
  // 默认状态
  defaultsState: {
    list: [
      { dictLabel: "无", dictValue: "0" },
      { dictLabel: "付款", dictValue: "1" },
      { dictLabel: "收款", dictValue: "2" }
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      switch (val) {
        case "1":
          return "付款";
        case "2":
          return "收款";
        default:
          return "无";
      }
    }
  },
  // 客商银行信息启用状态
  valid: {
    list: [
      { dictLabel: "禁用", dictValue: '0' },
      { dictLabel: "启用", dictValue: '1' }
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      switch (String(val)) {
        case '1':
          return "启用";
        case '0':
          return "禁用";
      }
    }
  },
  // 影像上传状态
  videoUploadState: {
    list: [
      { dictLabel: "已上传", dictValue: "1" },
      { dictLabel: "未上传", dictValue: "2" }
    ],
    getName: function (val) {
      switch (val) {
        case "1":
          return "已上传";
        case "2":
          return "未上传";
        default:
          return "无";
      }
    }
  },
  // 收退单类型
  indentType: {
    list: [
      { dictLabel: "收货单", dictValue: '1' },
      { dictLabel: "退货单", dictValue: '2' }
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return "收货单";
        case '2':
          return "退货单";
        default:
          return "无";
      }
    }
  },
  // 发退单类型
  sentReturnType: {
    list: [
      { dictLabel: "发货单", dictValue: '1' },
      { dictLabel: "退货单", dictValue: '2' }
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return "发货单";
        case '2':
          return "退货单";
        default:
          return "无";
      }
    }
  },
  // 流程状态【搜索条件】
  flowstatus: {
    list: [
      { dictLabel: "未审批", dictValue: '1' },
      { dictLabel: "审批中", dictValue: '2' },
      { dictLabel: "结束", dictValue: '3' }
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return "未审批";
        case '2':
          return "审批中";
        case '3':
          return "结束";
        default:
          return "无";
      }
    }
  },
  // 托收客户确认状态
  collectionState: {
    list: [
      { dictLabel: "未确认", dictValue: '0' },
      { dictLabel: "已确认", dictValue: '1' },
      { dictLabel: "无托收", dictValue: '2'}
    ],
    getName: function (val) {
      switch (val) {
        case '0':
          return "未确认";
        case '1':
          return "已确认";
        case '2':
          return "无托收";
        default:
          return "无";
      }
    }
  },
  // 子分公司-供应商 业务类型
  subIndentType: {
    list: [
      { dictLabel: "总部发货单", dictValue: '1' },
      { dictLabel: "子分公司退货单", dictValue: '2' }
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return "总部发货单";
        case '2':
          return "子分公司退货单";
        default:
          return "无";
      }
    }
  },
  // 总部-发货单据审核- 收货状态
  sendType: {
    list: [
      { dictLabel: "未收货", dictValue: '1' },
      { dictLabel: "部分收货", dictValue: '2' },
      { dictLabel: "已收货", dictValue: '3' }
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return "未收货";
        case '2':
          return "部分收货";
        case '3':
          return "已收货";
        default:
          return "无";
      }
    }
  },
  // 发行费提交状态
  subIssuanceFeeState: {
    list: [
      { dictLabel: "总部已提交", dictValue: 1 },
      { dictLabel: "子分公司已确认", dictValue: 2 }
    ],
    getName: function (val) {
      switch (val) {
        case '1':
          return "总部已提交";
        case '2':
          return "子分公司已确认";
        case '0':
          return "无";
        default:
          return "无";
      }
    }
  },
  //报订类型
  approveStatus: {
    list: [
      { dictLabel: '待审核', dictValue: '1' },
      { dictLabel: '已同意', dictValue: '2' },
      { dictLabel: '已拒绝', dictValue: '3' }
    ],
    // 根据枚举值获取名称
    getName: function (val) {
      switch (val) {
        case '1':
          return '待审核'
        case '2':
          return '已同意'
        case '3':
          return '已拒绝'
        default:
          return val
      }
    }
  },
  // 审核类型
  submitStateOfAgency: {
    list: [
      { dictLabel: "未提交", dictValue: 0 },
      { dictLabel: "提交待确认", dictValue: 1 },
      { dictLabel: "子分公司已确认", dictValue: 2 }
    ],
    getName: function (val) {
      switch (val) {
        case 0:
          return "未提交";
        case 1:
          return "提交待确认";
        case 2:
          return "子分公司已确认";
        default:
          return "无";
      }
    }
  },
  // 冲销状态
  reversalStatusType: {
    list: [
      { dictLabel: "未冲销", dictValue: 0 },
      { dictLabel: "已冲销", dictValue: 1 }
    ],
    getName: function (val) {
      switch (val) {
        case 0:
          return "未冲销";
        case 1:
          return "已冲销";
        default:
          return "无";
      }
    }
  },
  // 审核类型-带冲销
  submiReversalState: {
    list: [
      { dictLabel: "未提交", dictValue: "0" },
      { dictLabel: "已提交", dictValue: "1" },
      { dictLabel: "已冲销", dictValue: "2" }
    ],
    getName: function (val) {
      switch (val) {
        case "0":
          return "未提交";
        case "1":
          return "已提交";
        case "2":
          return "已冲销";
        default:
          return "无";
      }
    }
  },
};
