import request from '@/utils/request'

/**
 * 无参数---->统计用户数量
 * {'userType':'02'} --->统计本校录入教师教师数量
 * @param query
 * @returns {*}
 */
export function queryUserCount(query) {
  return request({
    url: '/edu/index/userCount',
    method: 'get',
    params: query
  })
}

//统计学生数量
export function studentCount() {
  return request({
    url: '/edu/index/studentCount'
  })
}

//统计学校数量
export function schoolCount() {
  return request({
    url: '/edu/index/schoolCount',
    method: 'get'
  })
}

//统计公司数量
export function companyCount() {
  return request({
    url: '/edu/index/companyCount',
    method: 'get'
  })
}

/**
 * 无参数--->      统计本期活动方案
 * {'complete':0} 统计本期活动未审批数
 * {'complete':1} 统计本期活动已审批数
 * @param query
 * @returns {*}
 */
export function countPlanCount(query) {
  return request({
    url: '/edu/index/countPlanCount',
    method: 'get',
    params: query
  })
}

//统计完善学生人数
export function perfectStudent() {
  return request({
    url: '/edu/index/perfectStudent',
    method: 'get'
  })
}

/**
 * 无参数--->           统计本期活动上架数量
 * {'publishStatus':1} 统计本期活动上架数
 * @param query
 * @returns {*}
 */
export function countReleaseCount(query) {
  return request({
    url: '/edu/index/countReleaseCount',
    method: 'get',
    params: query
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

// 查询当前用户的快速入口信息
export function fastEnterList() {
  return request({
    url: '/menu/fastenter/list',
    method: 'get'
  })
}

export function fastEnterList4Role(query) {
  return request({
    url: '/menu/fastenter/list4role',
    method: 'get',
    params: query
  })
}

// 查询所有快速入口
export function fastEnterAllList(query) {
  return request({
    url: '/menu/fastenter/allList',
    method: 'get',
    params: query
  })
}

// 编辑快速入口菜单
export function editFastEnter(data) {
  return request({
    url: '/menu/fastenter',
    method: 'put',
    data ,
  })
}

// 编辑快速入口菜单
export function editFastEnter4Role(data) {
  return request({
    url: '/menu/fastenter/role',
    method: 'put',
    data ,
  })
}

// 查询所有快速入口
export function fastEnterAllList4Role(query) {
  return request({
    url: '/menu/fastenter/allList4Role',
    method: 'get',
    params: query
  })
}

//首页子分公司报订数据
export function listToOrder(query) {
  return request({
    url: '/edu/subscribe/listToOrder',
    method: 'get',
    params: query
  })
}

// 首页子分公司收订数据
export function comprehensive(query) {
  return request({
    url: '/edu/pc/subscriptions/comprehensive/list',
    method: 'get',
    params: query
  })
}

// 首页按品种提印排序
export function listOrderPrintData(query) {
  return request({
    url: '/purchase/company/listOrderPrintData',
    method: 'get',
    params: query
  })
}

// 设置表格定制化显示列
export async function insertSysTable(data) {
  return request({
    url: '/system/config/insertSysTableConfiguration',
    method: 'post',
    data: data
  })
}
// 更新表格定制化显示列
export async function updateSysTable(data) {
  return request({
    url: '/system/config/updateSysTableConfiguration',
    method: 'post',
    data: data
  })
}
// 获取表格定制化显示列
export function getSysTable(query) {
  return request({
    url: '/system/config/getSysTableConfiguration',
    method: 'get',
    params: query
  })
}

//首页数据 year和quarterId 必传
export function indexData(query) {
  return request({
    url: '/edu/index/indexData',
    method: 'get',
    params: query
  })
}

//首页预览数据
export function companyStatist(query) {
  return request({
    url: '/companyStatist/statistics',
    method: 'get',
    params: query
  })
}


