<template>
  <div class="">
    <el-table
      class="cacl-height"
      border
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      :row-style="{ height: '38px' }"
      v-loading="loading"
      :header-cell-style="{ height: '40px' }"
      :data="tableData"
      :default-sort="{ prop: 'date', order: 'descending' }"
      style="width: 100%"
    >
      <template v-for="item in titleList">

        <el-table-column
          v-if="item.label=='总征订码洋'||item.label=='总征订实洋'||item.label=='征订码洋'||item.label=='征订实洋'||item.label=='其中—免费码洋'||item.label=='其中—付费码洋'||item.label=='OL征订码洋'"
          :sortable="item.slot == 'sort'"
          :prop="item.prop"
          align="right"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span v-if="item.label=='征订码洋'">
             <span v-if="scope.row.totalPrice ">{{scope.row.totalPrice}}</span>
              <span v-else-if="scope.row.payTotalPrice">{{scope.row.payTotalPrice}}</span>
              <span v-else-if="scope.row.freeTotalPrice">{{scope.row.freeTotalPrice}}</span>

            </span>
            <span v-else-if="item.label=='征订实洋'">
                 {{scope.row.actualTotalPrice}}
            </span>
            <span v-else-if="item.label=='其中—免费码洋'">
                 {{scope.row.freeTotalPrice}}
            </span>
            <span v-else-if="item.label=='其中—付费码洋'">
                 {{scope.row.payTotalPrice}}
            </span>
            <span v-else-if="item.label=='OL征订码洋'">
                <span v-if="scope.row.totalPrice ">{{scope.row.totalPrice}}</span>
                <span v-else-if="scope.row.actualTotalPrice">{{scope.row.actualTotalPrice}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column v-else
                         :prop="item.prop"
                         :label="item.label"
                         :sortable="item.slot == 'sort'"
                         :align="item.align"
                         :formatter="item.prop =='gradeId' ? gradeFormatter : null"
        >

        </el-table-column>
        <!--  -->
      </template>
    </el-table>
  </div>
</template>

<script>
import { numberFormat } from "@/utils";
export default {
  name: "Table",
  components: {},
  props: {
    loading: {type: Boolean, default: false},
    tableData: {type: Array, default: []},
    titleList: {type: Array, default: []},
  },

  data() {

    return { numberFormat};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    gradeFormatter(row, column) {
      return row.gradeName  ;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped>
.cacl-height {
  height: calc(100vh - 301px) !important;
}
</style>
