
let name = '云南新华阅读服务平台';
console.log('process.env.NODE_ENV', process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') {
  name = '云南新华阅读服务平台';
}
module.exports = {
  title: name,

  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示标签导航
   */
  tagsView: true,

  /**
   * 是否固定头部
   */
  fixedHeader: false,

  /**
   * 是否显示logo
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: "production"
};
