import Cookies from 'js-cookie'

const state = {
  courseAuthList: [],
}

const mutations = {


  setCourseAuthList(state, courseAuthList) {
    state.courseAuthList = courseAuthList;
  },

}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}