var utils = {
  formatDictList(list, label, value) {
    let result = [];
    list.forEach((item) => {
      let info = {
        dictLabel: item[label],
        dictValue: item[value],
      };
      result.push(info);
    });
    return result;
  },
  getUserInfo() {
    let userInfoJson = localStorage.getItem("userInfo");
    if (!userInfoJson) {
      return null;
    }
    return JSON.parse(userInfoJson);
  },
  getDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // 月
    let day = date.getDate(); // 日
    day = day <= 9 ? '0' + day : day;
    month = month <= 9 ? '0' + month : month;
    return year + "-" + month + "-" + day;
  },
  //审批意见数组转换为打印字符串
  flowOpinionChangeToString(arr) {
    let result = ''
    arr.forEach((activity) => {
      let flowNodeName =  activity.flowstep + '、' + activity.nodename + '\n'
      let flowName = activity.username ? activity.username  + '\n' : ''
      let flowContent = ''
      if (activity.audit) {
        flowContent = activity.audit==='1'?'同意\n':(activity.audit==='0'?'不同意\n':'')
      }
      // let flowPushtime = activity.pushtime!=null?activity.pushtime.substring(0,activity.pushtime.length-5) + '\n':''
      let flowPs = activity.ps
      let itemFlowContent = flowNodeName + flowName + flowContent + flowPs + '\n'
      result = result + itemFlowContent
    })
    return result
  },
  // 截取2位小数
  subPointDecimal(value) {
    if (!value) return 0
    value = value.toString()
    let pointIndex = value.indexOf('.')
    if (pointIndex >= 0) {
      let result = value.substring(0, pointIndex + 3)
      return Number(result)
    } else {
      return Number(value)
    }
  },
  // 判断批量提交数据是否有问题
  determineFlowSubmit(list) {
    if (!list.length) {
      return;
    }
    // 判断是否包含已完成的流程数据
    let haveComplete = list.filter((item) => {return item.complete == 1})
    if (haveComplete && haveComplete.length > 1) {
      return true
    }
    // 判断是否存在不同流程的数据
    let stateArr = list.map((item) => item.substate);
    const flag = Array.from(new Set(stateArr))
    if(flag && flag.length > 1) {
      return true
    } else {
      return false
    }
  },
  // 截取2位小数,不足2位小数补齐2位
  subPointDecimalAndCompletion(value) {
    if (!value) return '0.00'
    value = value.toString()
    let pointIndex = value.indexOf('.')
    if (pointIndex >= 0) {
      let result = value.substring(0, pointIndex + 3)
      return Number(result).toFixed(2)
    } else {
      return Number(value).toFixed(2)
    }
  },
  //获取OL订单状态
  getOrderStatusStr(str,unsubStatus){
    let result='';
    if(unsubStatus){
      unsubStatus=unsubStatus+"";
      //退订状态的优先级高
      if(unsubStatus.indexOf("0")!=-1&&unsubStatus.indexOf("1")!=-1&&unsubStatus.indexOf("2")!=-1){
        //0，1，2
        result='部分退订';
      }else if(unsubStatus.indexOf("0")==-1&&unsubStatus.indexOf("1")!=-1&&unsubStatus.indexOf("2")!=-1){
        //1，2
        result='部分退订';
      }else if(unsubStatus.indexOf("0")!=-1&&unsubStatus.indexOf("1")==-1&&unsubStatus.indexOf("2")!=-1){
        //0，2
        result='部分退订';
      }else if(unsubStatus.indexOf("0")!=-1&&unsubStatus.indexOf("1")!=-1&&unsubStatus.indexOf("2")==-1){
        //0,1
        result='部分申请退订';
      }else if(unsubStatus.indexOf("0")==-1&&unsubStatus.indexOf("1")==-1&&unsubStatus.indexOf("2")!=-1){
        //2
        result='退订完成';
      }else if(unsubStatus.indexOf("0")!=-1&&unsubStatus.indexOf("1")!=-1&&unsubStatus.indexOf("2")==-1){
        //1
        result='申请退订';
      }else if(unsubStatus.indexOf("0")!=-1&&unsubStatus.indexOf("1")==-1&&unsubStatus.indexOf("2")==-1){
        //0
        result='';
      }

      if(result){
        return result;
      }
    }
    if(!str){
      return '--';
    }else{
      let statusArr=str.split(',');
      for(let index in statusArr){
        let statusStr=statusArr[index];
        if(statusStr == '0'){
          result='未发起';
        }else if(statusStr == '1'){
          result='待支付';
        }else if(statusStr == '2'){
          result='已支付';
        }else if(statusStr == '3'){
          result='已退订';
        }else if(statusStr == '7'){
          result='已发货';
          break;
        }else if(statusStr == '9'){
          result='导入待支付';
        }else if(statusStr == '10'){
          result='已收货';
        }else if(statusStr == '11'){
          result='已完成';
        }else if(statusStr == '12'){
          result='已完结';
        }
      }
      return result;
    }
  },
  //获取OL订单发货状态
  getTransportStatus(statusStr){
    if(!statusStr){
      return '--';
    }else{
      let result='';
      if(statusStr == 0){
        result='--';
      }else if(statusStr == 1){
        result='--';
      }else if(statusStr == 2){
        result='未发货';
      }else if(statusStr == 3){
        result='--';
      }else if(statusStr == 7){
        result='已发货';
      }else if(statusStr == 9){
        result='--';
      }else if(statusStr == 10){
        result='已发货';
      }else if(statusStr == 11){
        result='已发货';
      }else if(statusStr == 12){
        result='已发货';
      }
      return result;
    }
  },
}
export default utils;
