<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
<style lang="scss">
.el-card {
  border-radius: 4px;
  border: 1px solid #e6ebf5 !important;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}

.statistics {
  .pagination-container .el-pagination {
    bottom: -15px;
    right: 0px;
    position: absolute;
  }
}

.subTitle div {
  margin-bottom: 4px;
  width: 4px;
  height: 14px;
  background: #3e7bfa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.el-dialog__body {
  .el-form-item {
    margin-bottom: 10px;

    .el-form-item__error {
      color: #ff4949;
      font-size: 12px;
      line-height: 1;
      padding-top: 2px;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}

.searchBt {
  width: 70px !important;
  padding: 5px 5px 5px 0px !important;
}

.app-main {
  .el-message-box {
    width: 250px !important;
  }
}

.statistics {
  //  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 14px;
}

.statistics > div:first-of-type {
  padding-top: 20px;
  //  color: #909399;
  font-size: 14px;
  flex: 3;
}

.statistics > div:last-of-type {
  //  color: #909399;
  font-size: 14px;
  flex: 2;
}

.el-table th > .cell {
  line-height: 34px !important;
}

.el-input__suffix {
  right: 12px;
}

.el-table__fixed-body-wrapper {
  height: calc(100% - 57px) !important;
  padding-bottom: 17px;
  box-sizing: content-box;
}

.el-table .cell {
  white-space: nowrap;
}

.el-input.is-disabled .el-input__inner {
  color: #000000 !important;
}

.el-textarea__inner {
  color: #000000 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex-bc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-x {
  display: flex;
  justify-content: center;
}

.border-none {
  input {
    border: none !important;
    outline: none !important;
  }

  input:focus-visible {
    border: none !important;
    outline: none !important;
  }
}

.common-border-div {
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #dcdfe6;
  min-height: 32px;
  min-width: 200px;
  .el-input__inner {
    width: 120px !important;
    overflow: hidden;
  }
}

.flex-1 {
  flex: 1;
}

.white-bg {
  background-color: white;
}

.full-router-div {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 2000;
  background-color: white;
  overflow-y: auto;
  border-radius: 4px;
}
.hasTagsView {
  .full-router-div {
    position: absolute;
    top: 0px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: 2000;
    background-color: white;
    overflow-y: auto;
    border-radius: 4px;
  }
}

.full-router-div2 {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2100;
  background-color: white;
  border-radius: 4px;
}

.pl-router {
  padding-left: 20px;
}

.pr-router {
  padding-right: 20px;
}

.hyperlink {
  color: #1890ff;
  cursor: pointer;
}

.redText{
  color: #ff4949;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-invoice {
  color: #636465;
  font-size: 13px;
  padding: 0 15px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  width: 100% !important;
  background-color: #f5f7fa;
}

.disable-input {
  color: #000;
  font-size: 13px;
  padding: 0 15px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  width: 200px !important;
  background-color: #f5f7fa;
}

.flex1{
  flex:1
}

</style>
