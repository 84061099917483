import request from '@/utils/request';

// 查询供应商信息列表
export function listsupplier(query) {
  return request({
    url: '/purchase/company/listSupplier',
    method: 'get',
    params: query
  })
}

// 凭证业务列表查询
export function certTypeList(query) {
  return request({
    url: "/settle/voucher/certTypeList",
    method: "get",
    params: query
  });
}

// 查询版别
export async function getPressName() {
  return request({
    url: '/edu/subscribe/getPressName',
    method: "get",
  });
}

//仓库名称
export function getStore(query) {
  return request({
    url: '/subcompany/payReturn/province/stock/options',
    method: 'get',
    params: query
  })
}

// 获取分类选项
export function getProductOptions(year, quarterId) {
  return request({
    url: "/edu/pcOrder/product/options",
    method: "get",
    params: {
      year, quarterId
    }
  });
}

// 凭证业务列表
export function getCertTypes(query) {
  return request({
    url: "/settle/certificate/certTypes",
    method: "get",
    params: query
  });
}

// 会计期间列表
export function getAccountPeroids(query) {
  return request({
    url: "/settle/certificate/accountPeroids",
    method: "get",
    params: query
  });
}
// LS凭证列表
export function getLsCerts(query) {
  return request({
    url: "/settle/certificate/lsCerts",
    method: "get",
    params: query
  });
}

// 财务凭证号列表
export function getSapCerts(query) {
  return request({
    url: "/settle/certificate/sapCerts",
    method: "get",
    params: query
  });
}

// 地区下拉列表
export function selectAreaName(query) {
  return request({
    url: '/company/company/selectAreaName',
    method: 'get',
    params: query,
  })
}

// 现金流量编码下拉列表查询
export async function cashFlowCodeSelectList(query) {
  return request({
    url: "/settle/cashFlowCode/selectList",
    method: "get",
    params: query
  });
}

// 银行下拉列表  （客户类型 3-供应商，2-子分公司，1-学校，4-其他）
export async function getTraderBankList(query) {
  return request({
    url: "/settle/traderBank/selectList",
    method: "get",
    params: query
  });
}

//收款单位下拉查询
export async function getCompanySelectList(query) {
  return request({
    url: "/settle/companySettleStatis/companySelectList",
    method: "get",
    params: query
  });
}

// 查询一级客户列表
export function listCustomer(query) {
  return request({
    url: '/customer/customer/list',
    method: 'get',
    params: query
  })
}

// 根据影像业务类型 获取附件文件名、下载路径地址
export function getFilePath({ busiType, busiCode }) {
  return request({
    url: `/settle/attachment/${busiType}/${busiCode}`,
    method: 'get'
  })
}

// 根据影像业务类型 获取附件文件名、下载路径地址---批量
export function getFilePathBatch(query) {
  return request({
    url: `/settle/attachment/batch`,
    method: 'get',
    params: query
  })
}

// 根据客商类型查询客商名称和财务编码
export function getTraderInfoByType(query) {
  return request({
    url: "/settle/traderBank/getTraderInfoByType",
    method: "get",
    params: query
  });
}

// 获取付款单位
export async function getSelectTraderInfos(query) {
  return request({
    url: "/settle/traderBank/selectTraderInfos",
    method: "get",
    params: query
  });
}

// 生成二维码
export function setQrCode(data) {
  return request({
    url: "/edu/qrCodePrintingInfo",
    method: "post",
    data
  });
}
// 查询二维码
export function queryQrCode(code) {
  return request({
    url: "/edu/qrCodePrintingInfo/" + code,
    method: "get",
  });
}

// 获取客商、财务编码下拉列表   参数traderType 1学校，2子分公司，3供应商
export function traderDropdown(query) {
  return request({
    url: "/settle/trader/traderDropdown",
    method: "get",
    params: query
  });
}

// 获取人员下拉列表
export function userDropdown(query) {
  return request({
    url: "/settle/trader/userDropdown",
    method: "get",
    params: query
  });
}

// 银行存款科目
export function subjectIdsAndNames (query) {
  return request({
    url: "/settle/accounting/title/subjectIdsAndNames",
    method: "get",
    params: query
  });
}

// 根据图书类型查询分类
export function categoryDropdown (query) {
  return request({
    url: "/settle/trader/categoryDropdown",
    method: "get",
    params: query
  });
}

// 银行存款科目-客户下拉
export function traderSubDropdown (query) {
  return request({
    url: "/settle/comSubject/traderSubDropdown",
    method: "get",
    params: query
  });
}

// 仓库下拉-根据图书类型过滤
export function selectStorehouseInfos (query) {
  return request({
    url: "/settle/traderBank/selectStorehouseInfos",
    method: "get",
    params: query
  });
}

// 成本中心
export function getCostCenterList (query) {
  return request({
    url: "/settle/costCenter/list",
    method: "get",
    params: query
  });
}

// 获取用户集团内外
export function getBaseCompany (query) {
  return request({
    url: "/settle/trader/getBaseCompany",
    method: "get",
    params: query
  });
}

// 获取图书类型分类及联
export function bookTypeDropdown (query) {
  return request({
    url: "/settle/trader/bookTypeDropdown",
    method: "get",
    params: query
  });
}