// 获得默认的年度学季
export function getYearAndQuarter() {
  // 当前系统时间
  const nowTime = new Date();
  // 当前年份
  var nowYear;
  // 当前月份
  const nowMonth = nowTime.getMonth() + 1;
  var quarter;
  if (nowMonth > 0 && nowMonth < 5) {
    quarter = "0";
    nowYear = nowTime.getFullYear();
  } else if (nowMonth < 11) {
    quarter = "1";
    nowYear = nowTime.getFullYear();
  } else {
    quarter = "0";
    nowYear = nowTime.getFullYear() + 1;
  }
  return { quarter, quarterName: quarter === '0' ? '春季' : '秋季', nowYear: nowYear.toString() }
}

export function getYQ() {
  const localYear = localStorage.getItem('year');
  const localQuarterName = localStorage.getItem('quarterName');

  const localQuarterId = localStorage.getItem('quarterId');
  const result = getYearAndQuarter();

  // 本地存储无数据时，则获取默认数据
  const year = localYear ? localYear : result.nowYear;
  const quarterId = localQuarterId ? localQuarterId : result.quarter;
  const quarterName = localQuarterName ? localQuarterName : result.quarterName;
  return { year, quarterId, quarterName }

}

export function getQuarterOpt() {
  // 本地学季下拉框有数据，则从本地取
  return new Promise((resolve) => {
    if (localStorage.getItem('quarterOptions') && localStorage.getItem('quarterOptions') !== '[]') {
      const result = JSON.parse(localStorage.getItem('quarterOptions'));
      resolve(result);
    } else {
      // 获得学季下拉列表框数据
      this.getDicts('sys_quarter').then((response) => {
        localStorage.setItem('quarterOptions', JSON.stringify(response.data));
        resolve(response.data)
      });
    }
  });
}
