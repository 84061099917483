<template>
  <div class="header">
    <div class="title">{{ title }}
    </div>
    <div class="btn">
      <el-button v-if="!hiddenCancel" plain @click="back()" :disabled="disabledCancel">{{cancelText ? cancelText : '返回'}}</el-button>
      <el-button v-if="!hidden" type="danger" @click="submit()" :disabled="disabled">{{confirmText ? confirmText : '保存'}}</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      },
      disabledCancel: {
        type: Boolean,
        default: false
      },
      hiddenCancel: {
        type: Boolean,
        default: false
      },
      hidden: {
        type: Boolean,
        default: false
      },
      cancelText: {
        type: String,
        default: ''
      },
      confirmText: {
        type: String,
        default: ''
      },
    },
    data() {
      return {};
    },
    methods: {
      back() {
        this.$emit("on-back");
      },
      submit() {
        this.$emit("on-submit");
      }
    }
  };
</script>

<style scoped lang="scss">
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 8px 20px;
    border-bottom: 1px solid #D8DBE4;
    .title {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #303133;
    }
    .el-button--default:hover, .el-button--default:focus{
      color: #ff4949 !important;
      background: #ffeded !important;
      border-color: #ffb6b6 !important;
    }
  }
</style>
