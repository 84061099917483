import { getInfo, login, logout } from '@/api/login';
import { getToken, removeToken, setToken } from '@/utils/auth';
import RSAEncrypt from '@/utils/rsaencrypt';
import request from '@/utils/request'
import store from "../index";

export const pubKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC8gjttRwecbaGadlqwDmHJPnAR' +
  'lvxiGkcejVSNE5m/5YMmx6DdcHlgDOz8OpZmbm5DBksFXGbr20jkhbzHoBEymuE1' +
  'VK1gYGUKM3QuSFpRwZeEj02SNWsVJ3478gWArw05BtaVYY9n2bh27EVBMZphrdLZ' +
  '15C+x2bH5gH1nQf+pQIDAQAB';
const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    orgName: '',
    xm: '',
    roles: [],
    permissions: [],
    companyId: '',
    // 公司全称
    companyName: '',
    userInfo: '',
    supplier: [], //供应商
    press: [], //出版社
    category: [], //分类,
    onlyCompanyProxy:'',//只是代发点人员

  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_ORGNAME: (state, orgName) => {
      state.orgName = orgName;
    },
    SET_XM: (state, xm) => {
      state.xm = xm;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_COMPANY_ID: (state, companyId) => {
      state.companyId = companyId;
    },
    SET_COMPANY_NAME: (state, companyName) => {
      state.companyName = companyName;
    },
    USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_ONLY_COMPANY_PROXY: (state, onlyCompanyProxy) => {
      state.onlyCompanyProxy = onlyCompanyProxy;
    },

  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = RSAEncrypt.encrypt(userInfo.password.trim(), pubKey);
      const code = userInfo.code;
      const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          setToken(res.token);
          commit('SET_TOKEN', res.token);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 获取用户信息
    GetInfo({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(res => {
          const user = res.user;
          if (user.userType === "01") {
            commit('SET_ORGNAME', user.companySimpleName);
            commit('SET_COMPANY_NAME', user.companyName);
          } else if (user.userType === "02") {
            commit('SET_ORGNAME', user.schoolSimpleName);
            commit('SET_COMPANY_NAME', user.companyName);
          } else if (user.userType === "03") {
            commit('SET_ORGNAME', user.supplierSimpleName);
          } else {
            commit('SET_ORGNAME', '');
          }
          const avatar = (user.avatar === "" || user.avatar == null || user.avatar == undefined) ? require("@/assets/image/profile.jpg") :
            // process.env.VUE_APP_BASE_API + user.avatar;
            user.avatar;
          commit('SET_ONLY_COMPANY_PROXY', '');
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles);
            commit('SET_PERMISSIONS', res.permissions);
            if(res.roles.length==1&&res.roles[0].indexOf('代发点')>=0){//改员工有且仅有一个代发点角色
              commit('SET_ONLY_COMPANY_PROXY', '1');
            }
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT']);
          }
          commit('SET_NAME', user.userName);
          commit('SET_AVATAR', avatar);
          commit('SET_COMPANY_ID', user.companyId);
          commit('USER_INFO', user);
          commit('SET_XM', user.nickName);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMISSIONS', []);
          commit('SET_ONLY_COMPANY_PROXY', false);
          removeToken();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
