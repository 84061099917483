<template>
  <div class="navbar" :class="{'navbar-collapse':sidebar.opened}">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <el-form
          style="padding-top: 10px;"
          class="right-menu-item"
          :model="userSetting"
          ref="queryForm"
          :inline="true"
        >
          <YearAndQuarter
            ref="yqRef"
            isSync
            :type="yearType"
            @change="updateYear"
            :defaultYear="userSetting.year"
            :clearable="false"
            :defaultQuarter="userSetting.quarterId"
            :year.sync="userSetting.year"
            :quarter.sync="userSetting.quarterId"
            quarterKey="quarterId"
            yearWidth="95px"
            quarterWidth="85px"
          />
        </el-form>
        <!-- v-if="showFileLinkFlag" -->
        <el-link
          class="el-dropdown-link"
          v-if="false"
          type="s"
          style="font-size:16px;margin-right: 10px; color: #5a5e66"
        >
          <router-link
            :to="'/system/sysfile'"
            class="link-type"
            style="color: #5a5e66"
          >
            <span>帮助文档</span>
          </router-link>
        </el-link>
        <small-routine class="right-menu-item hover-effect" />
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
        <search id="header-search" class="right-menu-item hover-effect" />
      </template>
      <el-form
        style="padding-top: 10px;"
        class="right-menu-item"
        :model="userSetting"
        ref="queryForm"
        :inline="true"
      >
        <el-form-item
          label
          prop="companySimpleName"
          v-if="distinctCompanyList.length > 0"
        >
          <el-select
            style="width:140px"
            filterable
            v-model="userSetting.companySimpleName"
            placeholder="公司简称"
            @change="companyChange"
            :disabled="distinctCompanyList.length < 2"
          >
            <el-option
              v-for="company in distinctCompanyList"
              :key="company.companyId"
              :label="company.companySimpleName"
              :value="company"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-dropdown class="avatar-container right-menu-item" style="padding: 0px;" trigger="click">
        <div class="avatar-wrapper">
          <span class="right-menu-item" style="font-size:14px; padding: 0px;">{{ xm }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";
import SmallRoutine from "@/components/smallRoutine";
import LinkGit from "@/components/Link/Git";
import LinkDoc from "@/components/Link/Doc";

import YearAndQuarter from "@/components/yearAndQuarter/index.vue";
import { getUserSetting, setUserSetting } from "@/api/system/user";
import { listData } from "@/api/system/dict/data";
import { getInfo } from "@/api/login";
import { checkPermi } from "@/utils/permission";
import {
  queryValidValidCompanyByUser,
  updateUserCompany
} from "@/api/secondaryDevelopment/staff/controlCompany";
import { getUserDeptName } from "@/api/secondaryDevelopment/company/dept"; // 权限判断函数
import cache from "@/utils/cache";
export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    SmallRoutine,
    LinkGit,
    LinkDoc,
    YearAndQuarter
  },
  computed: {
    ...mapGetters([
      "sidebar",
      "avatar",
      "orgName",
      "xm",
      "device",
      "companyId",
      "companySimpleName",
      "deptName"
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val
        });
      }
    }
  },
  data() {
    return {
      allDicts: [],
      //是否显示文件管理链接
      showFileLinkFlag: false,
      yearType: "update",
      userSettingQuarterId: undefined,
      userSettingYear: undefined,
      userSetting: {
        year: undefined,
        quarterId: undefined,
        companyId: undefined,
        companySimpleName: undefined,
        deptId: undefined,
        deptName: undefined
      },
      distinctCompanyList: [],
      companyList: [],
      deptList: [],
      // showInput:false,
      userCompany: {}
    };
  },
  created() {
    window.vue2 = this;
    let _this = this;
    setTimeout(function() {
      // _this.getUserCompanyCount();
      _this.queryValidValidCompanyByUser();
      _this.$forceUpdate();
      _this.userSetting.companyId = _this.companyId;
      _this.userSetting.companySimpleName = _this.companySimpleName;
      _this.userSetting.deptId = _this.deptId;
      _this.userSetting.deptName = _this.deptName;
      // _this.getUserDeptName(_this.companyId);
      _this.getSetting();
      //_this.$refs.yqRef.init();
      _this.showFileLink();
      _this.getUserInfo();
    }, 300);
  },
  methods: {
    async getUserDeptName(companyId) {
      await getUserDeptName(companyId).then(res => {
        this.userSetting.deptId = res.data.id;
        this.userSetting.deptName = res.data.deptName;
      });
    },

    getUserInfo() {
      //登录对象
      getInfo().then(response => {
        let userInfo = response.user;
        let urlMap = response.urlMap;
        localStorage.setItem("urlMap", JSON.stringify(urlMap));
        //是否是省公司
        let isProvince = 0;
        if (userInfo.level === 1 || userInfo.userType == "00") {
          //省公司
          isProvince = 1;
        }
        localStorage.setItem("isProvince", isProvince);
      });
    },

    async updateUserCompany() {
      await updateUserCompany(this.userSetting).then(res => {
        this.$router.go(0);
      });
    },

    deptChange(val) {
      this.$confirm("是否确定切换部门?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          this.$set(this.userSetting, "companyId", this.companyId);
          this.$set(
            this.userSetting,
            "companySimpleName",
            this.companySimpleName
          );
          this.$set(this.userSetting, "deptId", val);
          this.updateUserCompany();
          this.$forceUpdate();
        })
        .catch(() => {});
    },

    async companyChange(val) {
      //更新本地存储中的公司部门信息, 避免页面更新延迟导致的显示错误
      let userInfo= this.$userInfo();
      userInfo.areaId = val.areaId;
      userInfo.areaName = val.areaName;
      userInfo.companyId = val.companyId;
      userInfo.companySimpleName = val.companySimpleName;
      userInfo.deptId = val.deptId;
      userInfo.deptName = val.deptName;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.$set(this.userSetting, "areaId", userInfo.areaId);
      this.$set(this.userSetting, "areaName", userInfo.areaName);
      this.$set(this.userSetting, "companyId", val.companyId);
      this.$set(this.userSetting, "companySimpleName", val.companySimpleName);
      this.$set(this.userSetting, "deptId", val.deptId);
      this.$set(this.userSetting, "deptName", val.deptName);
      this.deptList = this.companyList.filter(
        m => m.companyId == val.companyId
      );
      await this.updateUserCompany();
      this.$forceUpdate();
    },

    async queryValidValidCompanyByUser() {
      await queryValidValidCompanyByUser().then(res => {
        if (res.data.distinctList) {
          this.distinctCompanyList = res.data.distinctList;
        }

        this.companyList = res.data.list;
        if (this.userSetting.companyId) {
          this.deptList = this.companyList.filter(
            m => m.companyId == this.userSetting.companyId
          );
        }
      });
    },
    showFileLink() {
      this.showFileLinkFlag = checkPermi(["system:file:auth", "*.*.*"]);
    },
    getSetting() {
      getUserSetting().then(res => {
        this.userSetting.quarterId = res.data.quarterId;
        this.userSetting.year = res.data.year;
        localStorage.setItem("year", res.data.year);
        localStorage.setItem("quarterId", res.data.quarterId);
        let quarterName = res.data.quarterId == "0" ? "春季" : "秋季";
        localStorage.setItem("quarterName", quarterName);
         //设置全局业务数据
        let businessInfo = {
          year: res.data.year,
          quarterId: res.data.quarterId,
          quarterName: quarterName,
          isProvince: cache.local.get("isProvince")
        };
        cache.local.setJSON("businessInfo", businessInfo);
      });
    },
    updateYear(o) {
      setUserSetting({
        year: o.year,
        quarterId: o.quarterId
      }).then(res => {
        let info = cache.local.getJSON("businessInfo");
        info.year = o.year;
        info.quarterId = o.quarterId;
        info.quarterName = o.quarterName;
        cache.local.setJSON("businessInfo", info);
        this.$emit('appMainUpload')
      });
    },
    openfile() {
      window.open(
        process.env.VUE_APP_BASE_API + "/profile/doc/系统操作手册v1.0_0812.pdf",
        "_blank"
      );
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm("确定退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch('setDictData', []);
        localStorage.clear();
        this.$store.dispatch("LogOut").then(() => {
          location.href = "/index";
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-collapse{
  margin-left: 80px;
}
.navbar {
  height: 50px;
  // overflow: hidden;
  position: relative;
  background: #fff;
  font-weight: bold;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 6px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      .avatar-wrapper {
        //margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          margin-bottom: 5px;
          border-radius: 50%;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 18px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
