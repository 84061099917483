import Cookies from 'js-cookie'

const state = {
  subCompanyHarvestList: new Array(), //子分公司收货管理-收获单管理
  subCompanySendList: new Array(), //
  refundPrintList: new Array(), //
  diffPrintList: new Array(), // 差异打印管理
}

const mutations = {

  SET_SUB_COMPANY_HARVEST(state, subCompanyHarvestList) {
    state.subCompanyHarvestList = subCompanyHarvestList;
  },
  SET_SUB_COMPANY_SEND(state, subCompanySendList) {
    state.subCompanySendList = subCompanySendList;
  },
  SET_REFUND_PRINT(state, refundPrintList) {
    state.refundPrintList = refundPrintList;
  },
  SET_DIFF_PRINT_LIST(state, diffPrintList) {
    state.diffPrintList = diffPrintList;
  },

}

const actions = {

  setSubCompanyHarvest({
    commit,
    state
  }, subCompanyHarvestList) {
    commit('SET_SUB_COMPANY_HARVEST', subCompanyHarvestList);
  },
  setSubCompanySendList({
    commit,
    state
  }, subCompanySendList) {
    commit('SET_SUB_COMPANY_SEND', subCompanySendList);
  },
  setRefundPrintList({
    commit,
    state
  }, refundPrintList) {
    commit('SET_REFUND_PRINT', refundPrintList);
  },
  setDiffPrintList({
    commit,
    state
  }, diffPrintList) {
    commit('SET_DIFF_PRINT_LIST', diffPrintList);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
