import * as math from 'mathjs';

export default {
  /**
   * 判断是否日期控件类型值（公共组件使用）
   * @param val 要格式化的值
   */
  isDatePicker(type) {
    let dateType = [
      "year",
      "month",
      "date",
      "datetime",
      "daterange",
      "datetimerange",
      "monthrange"
    ];
    return dateType.includes(type);
  },
  /**
   * 判断日期控件输入框格式（公共组件使用）
   * @param val 要格式化的值
   */
  dateFormat(type) {
    switch (type) {
      case "year":
        return "yyyy";
      case "month":
        return "yyyy-MM";
      case "date":
        return "yyyy-MM-dd";
      case "datetime":
        return "yyyy-MM-dd HH:mm:ss";
      case "daterange":
        return "yyyy-MM-dd";
      case "datetimerange":
        return "yyyy-MM-dd HH:mm:ss";
      case "monthrange":
        return "yyyyMM";
      default:
        return "yyyy-MM-dd";
    }
  },
  /**
   * 判断日期控件绑定值格式（公共组件使用）
   * @param val 要格式化的值
   */
  dateValueFormat(type) {
    switch (type) {
      case "year":
        return "yyyy";
      case "month":
        return "yyyy-MM";
      case "date":
        return "yyyy-MM-dd";
      case "datetime":
        return "yyyy-MM-dd HH:mm:ss";
      case "daterange":
        return "yyyy-MM-dd";
      case "datetimerange":
        return "yyyy-MM-dd HH:mm:ss";
      case "monthrange":
        return "yyyyMM";
      default:
        return "yyyy-MM-dd";
    }
  },
  /**
   * 年月日时分秒数据 获取时间格式
   * @param val 要格式化的值
   */
  yyyy(val) {
    if (!val) return "--";
    return val.substring(0, 4);
  },
  mm(val) {
    if (!val) return "--";
    return val.substring(5, 7);
  },
  ym(val) {
    if (!val) return "--";
    return val.substring(0, 7);
  },
  ymd(val) {
    if (!val) return "--";
    return val.substring(0, 10);
  },
  ymdhm(val) {
    if (!val) return "--";
    if(val.indexOf('T')){
      val=val.replace("T"," ");
    }
    return val.substring(0, 16);
  },
  ymdhms(val) {
    if (!val) return "--";
    if(val.indexOf('T')){
      val=val.replace("T"," ");
    }
    return val.substring(0, 19);
  },
  /**
   * 数字转金额
   * @param value 要格式化的值
   */
  amountFormat(number, decimals = 2, dec_point = ".", thousands_sep = ",") {
    number = (number + "").replace(/[^0-9+-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number,
      pre = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === "undefined") ? "," : thousands_sep,
      dec = (typeof dec_point === "undefined") ? "." : dec_point,
      s,
      toFixedFix = function (n, pre) {
        let k = Math.pow(10, pre);
        let arr = n.toString().split(".");
        let num = arr[0];
        let _decimal =
          arr.length > 1 && arr[1].length > pre
            ? arr[1].substring(0, pre)
            : arr[1];
        return num + (_decimal ? "." + _decimal : "");
      };
    // 为非有限数是小数位位为0
    s = (pre ? toFixedFix(n, pre) : "" + Math.floor(n)).split(".");
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || "").length < pre) {
      s[1] = s[1] || "";
      s[1] += new Array(pre - s[1].length + 1).join("0");
    }
    return s.join(dec);
  },
  //  amountFormat(value) {
  //   let amount = Number(value);
  //   if(value === null || value === undefined){
  //     return "-";
  //   }else if(!isNaN(amount)){
  //     amount = amount.toFixed(3);
  //     amount = amount.toString().match(/^\d+(?:\.\d{0,2})?/)[0];
  //     amount = amount.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  //     return amount;
  //   }
  //   return "";
  // },
  // amountFormat(value) {
  //   if (value == null) return "-"
  //   let amount = (Math.round(Number(value) * 100) / 100)
  //     .toFixed(2)
  //     .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  //   return amount && amount != "NaN" ? amount : "";
  // },
  /**
   * 金额转数字
   * @param value 要格式化的值
   */
  amountNumber(value) {
    let amount = Number(String(value).replace(/,/g, ""));
    return isNaN(amount) || amount == 0 ? "" : amount;
  },
  /**
   * 折扣百分化格式化（需要小数并且不需要四省五入）
   * @param value 要格式化的值
   */
  discountFormat(value) {
    //原算法  会出现(65.35*100) /100 丢精度问题
    // let discount = (parseInt(Number(value) * 100) / 100).toFixed(2);
    let discount=0;
    if(value){
      discount = (math.divide(math.multiply(math.bignumber(value),math.bignumber(100)),math.bignumber(100))).toFixed(2);
    }
    return discount && discount != "NaN" ? discount : "";
  },
  /**
   * 数字千分位（不需要小数）
   * @param value 要格式化的值
   */
  numberFormat(value) {
    let number = parseFloat(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    number.replace(".00", "");
    return number && number != "NaN" ? number.replace(".00", "") : 0;
  },
  /**
   * 折扣百分化格式化
   * @param number 要格式化的值
   * @param decimals 保留几位小数
   * @param isadd 是否要进位100
   * @returns {string}
   */
  numberPercentSign(number, decimals = 0, isadd = 1) {
    if (isadd == 1) {
      return number && (number * 100).toFixed(decimals);
    } else {
      return number && number.toFixed(decimals);
    }
  },
  /**
   * 折扣百分化格式化 1=100%
   * @param value 要格式化的值
   */
  discountFormatHundred(value) {
    let discount = (parseFloat(Number(value) * 100)).toFixed(2);
    return discount && discount != "NaN" ? discount : "";
  },
  formaterDate(value) {
    if (!value) {
      return "";
    }
    return value.substring(0,10);
  },
  /**
   * 数字转金额
   * @param value 要格式化的值
   */
  amountFormatForPrice(value) {
    if (value == null) return "-"
    let valueArr=(value+'').split('.');
    if(valueArr.length>1){
      let secondValue=(valueArr[1].length>1?valueArr[1]:(valueArr[1]+'0'));
      return valueArr[0]+'.'+secondValue.substring(0,2);
    }else{
      return value;
    }
  },
};
