import store from '@/store'

/**
 * 字符权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkPermi(value) {
  const permissions = store.getters && store.getters.permissions;
  if(permissions=='*:*:*'){
    return true;
  }
  if (value && value instanceof Array && value.length > 0) {
    const permissionDatas = value

    const hasPermission = permissions.some(permission => {
      return permissionDatas.includes(permission)
    })

    if (!hasPermission) {
      return false
    }
    return true
  } else {
    console.error(`need roles! Like checkPermi="['system:user:add','system:user:edit']"`)
    return false
  }
}

/**
 * 角色权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkRole(value) {
  if (value && value instanceof Array && value.length > 0) {
    const roles = store.getters && store.getters.roles
    const permissionRoles = value

    const hasRole = roles.some(role => {
      return permissionRoles.includes(role)
    })

    if (!hasRole) {
      return false
    }
    return true
  } else {
    console.error(`need roles! Like checkRole="['admin','editor']"`)
    return false
  }
}

/**
 * 权限数组校验，返回第一个满足条件的权限
 * @param {Array} arr 权限数组
 * @returns {Boolean}
 */
export function checkDefaultSelect(arr) {
  let result = undefined
  arr.forEach((item, index) => {
     if (checkPermi([item])) {
      if (typeof(result) === 'undefined') {
        result = index.toString()
      }  
     }
  })
  return result
}