import request from '@/utils/request'

// 查询学校信息列表
export function listSchool(query) {
  return request({
    url: '/edu/school/list',
    method: 'get',
    params: query
  })
}
// 年级查询学校信息列表
export function gradeSchoolList(query) {
  return request({
    url: '/edu/school/gradeSchoolList',
    method: 'get',
    params: query
  })
}

// 查询有网格员学校信息下拉框
export function staffList(query) {
  return request({
    url: '/edu/school/staffList',
    method: 'get',
    params: query
  })
}
// 查询有网格员学校信息表格
export function schoolStaffList(query) {
  return request({
    url: '/edu/school/schoolStaffList',
    method: 'get',
    params: query
  })
}

// 查询学校信息详细
export function getSchool(schoolId) {
  return request({
    url: '/edu/school/' + schoolId,
    method: 'get'
  })
}

// 新增学校信息
export function addSchool(data) {
  return request({
    url: '/edu/school',
    method: 'post',
    data: data
  })
}

// 修改学校信息
export function updateSchool(data) {
  return request({
    url: '/edu/school',
    method: 'put',
    data: data
  })
}

// 删除学校信息
export function delSchool(schoolId) {
  return request({
    url: '/edu/school/' + schoolId,
    method: 'delete'
  })
}

// 导出学校信息
export function exportSchool(query) {
  return request({
    url: '/edu/school/export',
    method: 'get',
    params: query
  })
}
//批量禁用
export function batchSchoolStatus(schoolId) {
  return request({
    url: '/edu/school/status/' + schoolId,
    method: 'delete'
  })
}
export function checkSchoolStatus(year, schoolId) {
  return request({
    url: '/edu/school/status/' +year +"/" + schoolId,
    method: 'get'
  })
}
//批量启用
export function batchStartSchoolStatus(schoolId) {
  return request({
    url: '/edu/school/startStatus/' + schoolId,
    method: 'delete'
  })
}
// 下载学校导入模板
export function importTemplate() {
  return request({
    url: '/edu/school/importTemplate',
    method: 'get'
  })
}

// 查询学校信息列表
export function listSchools(query) {
  return request({
    url: '/edu/school/schoolList',
    method: 'get',
    params: query
  })
}
//查询所有公司
export function companyList() {
  return request({
    url: '/edu/school/companyList',
    method: 'get',
  })
}

//查询学校数量统计
export function schoolNumberList(query) {
  return request({
      url: '/edu/school/schoolNumberList',
    method: 'get',
    params: query
  })
}
//查询学校数量统计
export function sumSchoolNumberList(query) {
  return request({
    url: '/edu/school/sumSchoolNumberList',
    method: 'get',
    params: query
  })
}
//学校数量统计 根据地区查询公司
export function companyByAreaIdList(areaId) {
  return request({
    url: '/edu/school/companyByAreaIdInfo/'+areaId,
    method: 'get',
  })
}
//查询学校人数统计
export function schoolStudentCount(query) {
  return request({
    url: '/edu/school/schoolStudentCount',
    method: 'get',
    params: query
  })
}
//人数总计
export function studentCount(query) {
  return request({
    url: '/edu/school/studentCount',
    method: 'get',
    params: query
  })
}
//根据公司id集合查询学校数量统计
export function schoolNumberInList(companyIds,status) {
  return request({
    url: '/edu/school/schoolNumberInList/'+companyIds+'/'+status,
    method: 'get',
  })
}


export function schoolOptions(query) {
  return request({
    url: '/edu/school/schoolOptions',
    method: 'get',
    params: query
  })
}

export function setCustomer(data) {
  return request({
    url: '/edu/school/setCustomer',
    method: 'post',
    data: data
  })
}

export function exportSchoolNumberList(query) {
  return request({
    url: '/edu/school/exportSchoolNumberList',
    method: 'get',
    params: query
  })
}

export function exportSchoolStudentCount(query) {
  return request({
    url: '/edu/school/exportSchoolStudentCount',
    method: 'get',
    params: query
  })
}