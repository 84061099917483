import store from './index'
const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  orgName: state => state.user.orgName,
  xm: state => state.user.xm,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  imgsIdArr: state => state.uploadImg.imgsIdArr,
  companyId: state => state.user.companyId,
  companyName: state => state.user.companyName,
  companySimpleName: state => state.user.userInfo.companySimpleName,
  // deptId: state => state.user.userInfo.deptId,
  deptName: state => state.user.userInfo.deptName,
  userInfo: state => state.user.userInfo,
  onlyCompanyProxy: state => state.user.onlyCompanyProxy,
  allDictData: state => state.allDictData.dictData,
  // 总部供应商数据下拉
  headerSupplierList: (state) => {
    if (state.settlement.isGetHeaderSupplierList) {
      return state.settlement.headerSupplierList
    } else {
      store.dispatch("settlement/getTrandeList", 3).then((res) => {
        return state.settlement.headerSupplierList
      })
    }
  },
  subCompanyList: (state) => {
    if (state.settlement.isGetSubCompanyList) {
      return state.settlement.subCompanyList
    } else {
      store.dispatch("settlement/getTrandeList", 2).then((res) => {
        return state.settlement.subCompanyList
      })
    }
  },
  schoolList: (state) => {
    if (state.settlement.isGetSchoolList) {
      return state.settlement.schoolList
    } else {
      store.dispatch("settlement/getTrandeList", 1).then((res) => {
        return state.settlement.schoolList
      })
    }
  },
  unsettleInfoList: (state) => {
    if (state.settlement.isGetUnsettleList) {
      return state.settlement.unsettleInfoList
    } else {
      store.dispatch("settlement/getUnsettleInfoList").then((res) => {
        return state.settlement.unsettleInfoList
      })
    }
  },
};
export default getters;
