// import * as math from 'mathjs';
import $utils from './utils'
const $math = require('mathjs')
export const settleMath = {
  add () {
    return comp('add', arguments)
  },
  subtract () {
    return comp('subtract', arguments)
  },
  multiply () {
    return comp('multiply', arguments)
  },
  divide () {
    return comp('divide', arguments)
  }
}

function comp (_func, args) {
  let t = $math.chain($math.bignumber(args[0]))
  for (let i = 1; i < args.length; i++) {
    // 加法 减法默认截断至2位小数再计算
    let value = ''
    if (_func == 'add' || _func == 'subtract') {
      value = $utils.subPointDecimal(args[i])
    } else {
      value = args[i]
    }
    t = t[_func]($math.bignumber(value))
  }
  // 防止超过6位使用科学计数法
  return parseFloat(t.done())
}

export default settleMath;

/*
  使用文档
  import { settleMath } from '@/utils/settleMath.js'

  math.js使用
  math.add(a+b)//加
  math.subtract(a-b)//减
  math.multiply(a*b)//乘
  math.divide(a/b)//除

**/